import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@/core/store';

type State = {
  returnUrl: string;
};

const initialState: State = {
  returnUrl: '',
};

const slice = createSlice({
  name: 'verification',
  initialState,
  reducers: {
    saveReturnUrl(state, { payload }: PayloadAction<string>) {
      state.returnUrl = payload;
    },
  },
});

export const verificationReducer = slice.reducer;
export const { saveReturnUrl } = slice.actions;

export const selectReturnUrl = (state: RootState) => state.verification.returnUrl;
