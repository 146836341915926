import { Global } from '@emotion/react';
import React from 'react';

export function Fonts() {
  return <Global
    styles={`
     @font-face {
        font-family: 'ProximaNova';
        font-weight: 100;
        font-style: normal;
        src: url('/fonts/ProximaNova-Thin.woff2') format('woff2'),
        url('/fonts/ProximaNova-Thin.woff') format('woff');
    }
    
    @font-face {
        font-family: 'ProximaNova';
        font-weight: 100;
        font-style: italic;
        src: url('/fonts/ProximaNova-ThinItalic.woff2') format('woff2'),
        url('/fonts/ProximaNova-ThinItalic.woff') format('woff');
    }
    
    @font-face {
        font-family: 'ProximaNova';
        font-weight: 300;
        font-style: normal;
        src: url('/fonts/ProximaNova-Light.woff2') format('woff2'),
        url('/fonts/ProximaNova-Light.woff') format('woff');
    }
    
    @font-face {
        font-family: 'ProximaNova';
        font-weight: 300;
        font-style: italic;
        src: url('/fonts/ProximaNova-LightItalic.woff2') format('woff2'),
        url('/fonts/ProximaNova-LightItalic.woff') format('woff');
    }
    
    @font-face {
        font-family: 'ProximaNova';
        font-weight: 400;
        font-style: normal;
        src: url('/fonts/ProximaNova-Regular.woff2') format('woff2'),
        url('/fonts/ProximaNova-Regular.woff') format('woff'),
        url('/fonts/ProximaNova-Regular.ttf') format('ttf');
    }
    
    @font-face {
        font-family: 'ProximaNova';
        font-weight: 500;
        font-style: normal;
        src: url('/fonts/ProximaNova-Medium.woff2') format('woff2'),
        url('/fonts/ProximaNova-Medium.woff') format('woff');
    }
    
    @font-face {
        font-family: 'ProximaNova';
        font-weight: 500;
        font-style: italic;
        src: url('/fonts/ProximaNova-MediumItalic.woff2') format('woff2'),
        url('/fonts/ProximaNova-MediumItalic.woff') format('woff');
    }
    
    @font-face {
        font-family: 'ProximaNova';
        font-weight: 600;
        font-style: normal;
        src: url('/fonts/ProximaNova-Semibold.woff2') format('woff2'),
        url('/fonts/ProximaNova-Semibold.woff') format('woff'),
        url('/fonts/ProximaNova-Semibold.ttf') format('ttf');
    }
    
    @font-face {
        font-family: 'ProximaNova';
        font-weight: 600;
        font-style: italic;
        src: url('/fonts/ProximaNova-SemiboldItalic.woff2') format('woff2'),
        url('/fonts/ProximaNova-SemiboldItalic.woff') format('woff');
    }
    
    @font-face {
        font-family: 'ProximaNova';
        font-weight: 700;
        font-style: normal;
        src: url('/fonts/ProximaNova-Bold.woff2') format('woff2'),
        url('/fonts/ProximaNova-Bold.woff') format('woff'),
        url('/fonts/ProximaNova-Bold.ttf') format('ttf');
    }
    
    @font-face {
        font-family: 'ProximaNova';
        font-weight: 700;
        font-style: italic;
        src: url('/fonts/ProximaNova-BoldItalic.woff2') format('woff2'),
        url('/fonts/ProximaNova-BoldItalic.woff') format('woff');
    }
    
    @font-face {
        font-family: 'ProximaNova';
        font-weight: 800;
        font-style: normal;
        src: url('/fonts/ProximaNova-Extrabold.woff2') format('woff2'),
        url('/fonts/ProximaNova-Extrabold.woff') format('woff');
    }
    
    @font-face {
        font-family: 'ProximaNova';
        font-weight: 800;
        font-style: italic;
        src: url('/fonts/ProximaNova-ExtraboldItalic.woff2') format('woff2'),
        url('/fonts/ProximaNova-ExtraboldItalic.woff') format('woff');
    }
    
    @font-face {
        font-family: 'ProximaNova';
        font-weight: 900;
        font-style: normal;
        src: url('/fonts/ProximaNova-Black.woff2') format('woff2'),
        url('/fonts/ProximaNova-Black.woff') format('woff');
    }
    
    @font-face {
        font-family: 'ProximaNova';
        font-weight: 900;
        font-style: italic;
        src: url('/fonts/ProximaNova-BlackItalic.woff2') format('woff2'),
        url('/fonts/ProximaNova-BlackItalic.woff') format('woff');
    }
    `}
  />
}
