import { createContext, useContext } from 'react';

export const createGenericContext = <T>() => {
  const genericContext = createContext<T | null>(null);
  const useGenericContext = () => {
    const contextData = useContext(genericContext);
    if (!contextData) throw new Error('Context must be used within a Provider');
    return contextData;
  };
  return [useGenericContext, genericContext.Provider] as const;
};
