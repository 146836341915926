import React from 'react';
import { Link } from '@chakra-ui/react';

import type { ContentfulNodeProps } from './types';

export function ContentfulLink({ node, children }: ContentfulNodeProps) {
  return (
    <Link textDecoration="underline" href={node.data.uri} isExternal rel="noopener noreferrer">
      {children}
    </Link>
  );
}
