import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const ButtonStyles: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 'semibold',
    borderRadius: 'full',
    verticalAlign: 'super',
    color: {
      default: 'black',
    },
  },
  variants: {
    solid: {
      backgroundColor: 'primary.blue1',
      color: 'white',
      _hover: {
        backgroundColor: 'primary.blue2',
      },
      _active: {
        backgroundColor: 'primary.blue3',
      },
      _disabled: {
        backgroundColor: 'text.link.disabled !important',
      },
    },
    secondary: {
      backgroundColor: 'secondary.orange',
      color: 'white',
      _hover: {
        backgroundColor: 'orange.400',
      },
      _active: {
        backgroundColor: 'orange.500',
      },
      _disabled: {
        backgroundColor: 'orange.100',
      },
    },
    white: {
      bgColor: 'white',
      color: 'primary.blue1',
      _hover: {
        bgColor: 'background.blue',
      },
      _active: {
        bgColor: 'background.lightblue',
      },
      _disabled: {
        bgColor: 'text.link.disabled !important',
        color: 'text.base.dark !important',
      },
    },
    orange: {
      bgColor: 'orange.default',
      color: 'white',

      _hover: {
        bgColor: 'orange.hover',
      },
      _active: {
        bgColor: 'orange.active',
      },
      _disabled: {
        backgroundColor: 'text.link.disabled !important',
      },
    },
    danger: {
      bgColor: 'background.stateful.error',
      color: 'white',

      _hover: {
        bgColor: '#FF6687',
      },
      _active: {
        bgColor: '#C7002B',
      },
      _disabled: {
        backgroundColor: 'text.link.disabled !important',
      },
    },
    outline: {
      color: 'primary.blue1',
      backgroundColor: 'transparent',
      borderColor: 'primary.blue1',
      _hover: {
        color: 'primary.blue1',
        backgroundColor: 'background.blue',
        borderColor: 'primary.blue1',
      },
      _active: {
        color: 'primary.blue3',
        backgroundColor: 'background.blue',
        borderColor: 'primary.blue3',
      },
      _disabled: {
        color: 'text.link.disabled !important',
        borderColor: 'text.link.disabled !important',
      },
    },
    ghost: {
      color: 'primary.blue1',
      _hover: {
        color: 'primary.blue1',
        backgroundColor: 'background.blue',
      },
      _active: {
        color: 'primary.blue3',
        backgroundColor: 'background.blue',
      },
      _disabled: {
        color: 'text.link.disabled !important',
        backgroundColor: 'transparent !important',
      },
    },
    'ghost-outline': {
      color: 'primary.blue1',
      backgroundColor: 'white',
      boxShadow: '0px 1px 3px rgb(0, 0, 0, 0.3)',
    },
    link: {
      color: 'primary.blue1',
      fontSize: 'inherit',
      fontWeight: 'inherit',
      minW: 'auto',
      _hover: {
        color: 'primary.blue1',
      },
      _active: {
        color: 'primary.blue3',
      },
      _disabled: {
        color: 'text.link.disabled !important',
      },
    },
    iconDefault: {
      color: 'border.formControl',
      backgroundColor: 'white',
      _hover: {
        backgroundColor: 'blackAlpha.100',
      },
      _active: {
        backgroundColor: 'blackAlpha.200',
      },
    },
    unstyled: {
      display: 'flex',
      fontSize: 'inherit',
    },
    unstyledWithHover: {
      _hover: {
        backgroundColor: 'blackAlpha.200',
      },
    },
  },
  sizes: {
    sm: {
      py: 2,
      px: 3,
      h: 8,
    },
    md: {
      py: 3,
      px: 4,
      h: 12,
      fontSize: 'md',
    },
    lg: {
      height: 14,
      py: 0,
      px: 8,
    },
    xsIcon: {
      boxSize: 6,
      borderRadius: 'md',
      fontSize: 'sm',
    },
    smIcon: {
      boxSize: 8,
      borderRadius: 'md',
      fontSize: 'md',
    },
    mdIcon: {
      boxSize: 12,
      borderRadius: 'md',
      fontSize: 'md',
    },
    lgIcon: {
      boxSize: 14,
      borderRadius: 'md',
      fontSize: 'lg',
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'solid',
  },
};
