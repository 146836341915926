import type { OrNull } from '@/types/common';
import type { Slice } from '@reduxjs/toolkit';

type GetHeadersArgs = {
  headers?: Headers;
  accessToken?: OrNull<string>;
};

export function getHeaders({ headers: initHeaders, accessToken }: GetHeadersArgs): Headers {
  const headers = initHeaders || new Headers();
  if (accessToken) {
    headers.set('Authorization', `Bearer ${accessToken}`);
  }

  return headers;
}

export function getSliceActionsList<S extends Slice>(slice: S, actions: (keyof S['actions'])[]) {
  return actions.map((action) => `${slice.name}/${String(action)}`);
}
