import { toUpper } from 'lodash-es';
import { useMemo } from 'react';

import { useProfileContext } from '@/app/providers/profile/ProfileContext';
import { Products } from '@/utils/constants/common';

import type { TReleasedProduct } from '@/types/bookings';
import type { TOption } from '@/types/common';

export const useAcceptedVendorOptions = (product: TReleasedProduct) => {
  const { hyattSettings, internalConstants } = useProfileContext();

  return useMemo(() => {
    const vendorOptionsByProduct = {
      [Products.Air]: internalConstants.tspmAirVendors,
      [Products.Hotel]: internalConstants.tspmHotelVendors,
      [Products.Car]:
        internalConstants.asbCarVendors?.[hyattSettings.common.primaryGds] ??
        internalConstants.tspmCarVendors,
    };
    return Object.entries(vendorOptionsByProduct[product])
      .map(([value, label]) => ({ value: toUpper(value), label }))
      .sort(sortArrayOfObjectsAlphabetically);
  }, [
    hyattSettings.common.primaryGds,
    internalConstants.asbCarVendors,
    internalConstants.tspmAirVendors,
    internalConstants.tspmCarVendors,
    internalConstants.tspmHotelVendors,
    product,
  ]);
};

function sortArrayOfObjectsAlphabetically(a: TOption, b: TOption) {
  const optionA = a.label.toLowerCase();
  const optionB = b.label.toLowerCase();
  if (optionA < optionB) {
    return -1;
  }
  if (optionA > optionB) {
    return 1;
  }
  return 0;
}
