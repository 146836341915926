import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const FormLabel: ComponentStyleConfig = {
  baseStyle: {
    fontSize: 'sm',
    fontWeight: 'semibold',
    color: 'text.base.dark',
    mb: 1,
    mr: 0,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',

    _disabled: {
      opacity: 1,
    },
  },
};

export const FormError: ComponentStyleConfig = {
  baseStyle: {
    text: {
      color: 'text.stateful.error',
      fontSize: 'xs',
      mt: 1,
    },
  },
};

export const Form = {
  baseStyle: {
    requiredIndicator: {
      color: 'text.stateful.error',
    },
    _focusVisible: {
      fill: 'primary.blue1',
    },
  },
};
