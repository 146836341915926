import 'react-datepicker/dist/react-datepicker.css';

import cn from 'classnames';
import { Box } from '@chakra-ui/react';
import React, { forwardRef, useRef } from 'react';
import ReactDatepicker from 'react-datepicker';

import { useDatepickerPosition } from './hooks/useDatepickerPosition';
import './style.scss';
import { useLanguage } from '@/app/language/useLanguage';
import { Portal } from '@/components/Portal';

import type { RefObject } from 'react';
import type { ReactDatePickerProps } from 'react-datepicker';

export type DatepickerProps = ReactDatePickerProps<never, false> & { isOpen: boolean };

export const Datepicker = forwardRef<HTMLDivElement, DatepickerProps>(
  ({ children, wrapperClassName, isOpen, ...restProps }, ref) => {
    const { locale } = useLanguage();
    const calendarRef = useRef<ReactDatepicker<never>>(null);
    const refContainer = useRef(null);

    const [positionX, , current, topPosition] = useDatepickerPosition({
      ref: refContainer as RefObject<HTMLDivElement>,
      dimensions: [684, 393],
    });

    return (
      <Box ref={refContainer} className={cn('react-datepicker__wrapper', wrapperClassName)}>
        {children}

        {isOpen && (
          <Portal>
            <Box
              ref={ref}
              position="absolute"
              left={current?.left}
              top={topPosition}
              width={current?.width}
              height={0}
            >
              <ReactDatepicker
                {...restProps}
                locale={locale}
                calendarClassName={cn('react-datepicker--inline', positionX, 'bottom')}
                inline
                disabledKeyboardNavigation
                ref={calendarRef}
              />
            </Box>
          </Portal>
        )}
      </Box>
    );
  },
);
