import { Helmet } from 'react-helmet';
import React, { useEffect, useState } from 'react';

import { useAppSelector } from '@/core/hooks';
import { gigyaApiKeyByRegion } from '@/features/SSO/constants';
import { selectSSORegion } from '@/features/SSO/slice';
import { ExternalScripts } from './ExternalScripts';
import { APP_TITLE } from '@/core/constants';

const getFaviconsPath = (isDarkMode: boolean) =>
  isDarkMode ? '/favicons/dark' : '/favicons/light';

type MetaInitialLayoutProps = {
  children: JSX.Element | JSX.Element[];
};

export function MetaInitialLayout({ children }: MetaInitialLayoutProps) {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const ssoRegion = useAppSelector(selectSSORegion);

  useEffect(() => {
    const matcher = window.matchMedia('(prefers-color-scheme: dark)');
    // Set color mode initially.
    setIsDarkMode(matcher.matches);
    // Change color mode if mode did change
    matcher.onchange = () => setIsDarkMode(matcher.matches);
  }, []);

  return (
    <>
      <Helmet>
        <title key="app-title">{APP_TITLE}</title>
        <link rel="icon" href={isDarkMode ? '/favicon-dark.ico' : '/favicon.ico'} />
        <link
          rel="icon"
          href={`${getFaviconsPath(isDarkMode)}/icon-vector.svg`}
          type="image/svg+xml"
        />
        <link
          rel="apple-touch-icon"
          id="apple-touch-favicon"
          href={`${getFaviconsPath(isDarkMode)}/apple-touch-icon-180x180.png`}
        />
        <link
          rel="manifest"
          id="manifest"
          href={`${getFaviconsPath(isDarkMode)}/site.webmanifest`}
        />
        {ssoRegion && (
          <script
            key={ssoRegion}
            src={`https://cdns.gigya.com/js/gigya.js?apikey=${gigyaApiKeyByRegion[ssoRegion]}`}
          />
        )}
      </Helmet>
      <ExternalScripts />
      {children}
    </>
  );
}
