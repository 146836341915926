import React from 'react';

import type { SVGProps } from 'react';

export function Warning(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.99967 0.337891C3.31967 0.337891 0.333008 3.32456 0.333008 7.00456C0.333008 10.6846 3.31967 13.6712 6.99967 13.6712C10.6797 13.6712 13.6663 10.6846 13.6663 7.00456C13.6663 3.32456 10.6797 0.337891 6.99967 0.337891ZM7.66634 10.3379H6.33301V9.00456H7.66634V10.3379ZM7.66634 7.67122H6.33301V3.67122H7.66634V7.67122Z"
        fill="currentColor"
      />
    </svg>
  );
}
