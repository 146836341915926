import React from 'react';
import { components } from 'react-select';

import type { MenuListProps, GroupBase } from 'react-select';

export function MenuList<Option, isMulti extends boolean, Group extends GroupBase<Option>>({
  children,
  ...otherProps
}: MenuListProps<Option, isMulti, Group>) {
  const testId = otherProps.selectProps.dataTestId
    ? `${otherProps.selectProps.dataTestId}-menu`
    : '';

  return (
    <components.MenuList {...otherProps}>
      <div data-testid={testId}>{children}</div>
    </components.MenuList>
  );
}
