import React from 'react';

import { RouteType } from '@/types/router';
import { Paths } from '@/app/routes/paths';

import type { AppRoute } from '@/types/router';

const AirResultsPage = React.lazy(() =>
  import('@/pages/air/AirResultsPage').then((module) => ({ default: module.AirResultsPage })),
);
const AirCheckoutPage = React.lazy(() =>
  import('@/pages/air/AirCheckoutPage').then((module) => ({ default: module.AirCheckoutPage })),
);
const Verification = React.lazy(() =>
  import('@/features/air').then((module) => ({ default: module.Verification })),
);
const AirReservationPage = React.lazy(() =>
  import('@/pages/air/AirReservationPage').then((module) => ({
    default: module.AirReservationPage,
  })),
);

export const airRoutes: AppRoute[] = [
  { type: RouteType.DEFAULT, pathname: Paths.AIR_RESULTS, component: AirResultsPage },
  { type: RouteType.DEFAULT, pathname: Paths.AIR_CHECKOUT, component: AirCheckoutPage },
  {
    type: RouteType.DEFAULT,
    pathname: Paths.AIR_VERIFICATION,
    component: Verification,
  },
  {
    type: RouteType.DEFAULT,
    pathname: Paths.AIR_RESERVATION,
    component: AirReservationPage,
  },
];
