import React from 'react';
import { Portal as ChakraPortal, useModalContext } from '@chakra-ui/react';

type PortalProps = {
  children: React.ReactNode;
};

/**
 * The `Portal` component is designed to address a common issue where a popover, when triggered within a modal,
 * opens behind the modal, rendering it unseen. By utilizing this `Portal` component, the popover will be displayed
 * above the current modal, thus ensuring it remains visible to users.
 *
 * It achieves this by utilizing the `ChakraPortal` with a reference to the modal's dialog context, allowing the popover
 * to properly render above the modal rather than behind it.
 */
export function Portal({ children }: PortalProps) {
  try {
    const modalContext = useModalContext();
    return <ChakraPortal containerRef={modalContext?.dialogRef}>{children}</ChakraPortal>;
  } catch {
    return <ChakraPortal>{children}</ChakraPortal>;
  }
}
