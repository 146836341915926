import * as Yup from 'yup';
import { intersection } from 'lodash-es';

import { createValidations } from './validations';

import type { TFunction } from 'i18next';
import type { Definitions } from '@/types/generated';
import type { Option } from '@/components/Form/Select/types';
import type {
  CRDFieldConfig,
  CRDFieldRaw,
  CRDFormValues,
  TCrdPaidPolicy,
  TPaidPolicy,
  ValidationsType,
} from '@/components/Bookings/Checkout/CRD/types';

export const isCommonCRD = (crd: Definitions.ReportableData) => crd.code.split('.')[1] === 'COMMON';

const crdPaidPolicy: TCrdPaidPolicy = {
  D: 'deposit',
  G: 'guarantee',
};

export const getPaidPolicyConditions = (paidPolicy: TPaidPolicy): string[] => {
  if (Array.isArray(paidPolicy) && paidPolicy.length) {
    return paidPolicy.map((policy) => crdPaidPolicy[policy]);
  }

  return [crdPaidPolicy[paidPolicy as string]];
};

/**
 * Function to parse raw crd response
 * @param field CRD field config from the API
 * @param t TFunction from i18next
 * @returns Convenient field config for the form, including validation rules
 */
export function prepareCRDFieldConfig(field: CRDFieldRaw, t: TFunction): CRDFieldConfig {
  const validations: ValidationsType[] = createValidations(field, t);

  const options: Option[] = field.choices.map((choice) => ({
    value: choice.code,
    name: choice.description,
  }));

  return {
    code: field.code,
    label: field.name,
    type: field.type,
    defaultValue: field.default ?? '',
    helpText: field.helpText ?? '',
    options,
    required: field.required,
    validations,
  };
}

export function createCRDValidationSchema(
  crdFieldsConfig: CRDFieldConfig[],
): Record<string, Yup.AnySchema> {
  const schemas: Record<string, Yup.AnySchema> = {};

  crdFieldsConfig.forEach((field) => {
    let schema = Yup.string();

    field.validations.forEach((validation) => {
      if (schema[validation.type as keyof typeof schema]) {
        // @ts-ignore
        schema = schema[validation.type](...validation.params);
      }
    });

    schemas[field.code] = schema;
  });

  return schemas;
}

export function isEnabledCRDField(
  paymentMethod?: string,
  isOutOfPolicy?: boolean,
  paidPolicy?: TPaidPolicy,
) {
  return <T extends CRDFieldRaw>(crdField: T) =>
    crdField.conditions?.length
      ? intersection(crdField.conditions, [
          paymentMethod === 'saved_card' ? 'card' : paymentMethod,
          isOutOfPolicy ? 'out_of_policy' : 'in_policy',
          ...(paidPolicy ? getPaidPolicyConditions(paidPolicy) : []),
        ]).length === crdField.conditions.length
      : true;
}

export const prepareCRD = (
  formValues: CRDFormValues,
  actualCrdFields?: CRDFieldConfig[],
): Definitions.ReportableData[] =>
  actualCrdFields
    ?.map((config) => config.code)
    .map((fieldName) => ({ code: fieldName, answer: formValues[fieldName] }))
    .filter(({ answer }) => !!answer) ?? [];
