import React from 'react';
import { Text } from '@chakra-ui/react';

type CRDFieldHelpTextProps = { text: string };

export function CRDFieldHelpText({ text }: CRDFieldHelpTextProps) {
  return text ? (
    <Text fontSize="xs" color="text.base.placeholder" mt={1} wordBreak="break-word">
      {text}
    </Text>
  ) : null;
}
