import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { colors } from '@/theme/colors';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  accordionAnatomy.keys,
);

const baseStyle = definePartsStyle({
  root: {
    mb: 3,
    borderColor: 'transparent',

    '.chakra-collapse': {
      px: 0,
      overflow: 'visible !important',
    },
  },

  button: {
    fontSize: 'sm',
    fontWeight: 'bold',
    py: 2,
    px: 0,

    _hover: {
      backgroundColor: 'transparent',
    },
  },

  panel: {
    p: 0,
  },

  icon: {
    color: 'text.base.weak',
    height: 6,
    width: 6,
    ml: '-5px',
    mr: '5px',
  },
});

const variants = {
  border: definePartsStyle({
    root: {
      w: 'full',
      borderTop: '1px solid',
      borderColor: 'gray.100 !important',
    },
    container: {
      borderTop: 0,
      borderBottom: '1px solid',
      borderColor: 'gray.100 !important',
    },
    button: {
      fontWeight: 'regular',
      py: 4,
      justifyContent: 'space-between',
    },
    panel: {
      pt: '4 !important',
      paddingBottom: '8 !important',
    },
  }),

  primary: definePartsStyle({
    container: {
      borderTop: `1px solid ${colors.border.line}`,
    },
    button: {
      fontSize: 'md',
      color: 'primary.blue1',
    },
    icon: {
      color: 'primary.blue1',
    },
    panel: {
      py: '2 !important',
    },
  }),
};

export const AccordionStyles = defineMultiStyleConfig({ baseStyle, variants });
