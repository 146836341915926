import * as React from 'react';
import { useListBoxSection, useListBox, useOption } from '@react-aria/listbox';
import { MdCheck } from 'react-icons/md';
import { Box, Heading, Icon, List, ListItem, Spinner } from '@chakra-ui/react';

import type { ListItemProps} from '@chakra-ui/react';
import type { AriaListBoxOptions} from '@react-aria/listbox';
import type { Node, LoadingState } from '@react-types/shared';
import type { ListState } from '@react-stately/list';

interface AutocompleteListBoxProps extends AriaListBoxOptions<unknown> {
  listBoxRef?: React.RefObject<HTMLUListElement>;
  state: ListState<unknown>;
  loadingState?: LoadingState;
  onLoadMore?: () => void;
  optionProps?: ListItemProps;
}

interface OptionProps {
  item: Node<unknown>;
  state: ListState<unknown>;
  listItemProps?: ListItemProps;
}

export function AutocompleteListBox(props: AutocompleteListBoxProps) {
  const ref = React.useRef<HTMLUListElement>(null);
  const { listBoxRef = ref, state, optionProps } = props;
  const { listBoxProps } = useListBox(props, state, listBoxRef);

  const onScroll = (e: React.UIEvent) => {
    const scrollOffset = e.currentTarget.scrollHeight - e.currentTarget.clientHeight * 2;
    if (e.currentTarget.scrollTop > scrollOffset && props.onLoadMore) {
      props.onLoadMore();
    }
  };

  return (
    <List
      {...listBoxProps}
      ref={listBoxRef}
      overflow="auto"
      width="100%"
      maxHeight="300"
      my="1"
      display="flex"
      flexDirection="column"
      onScroll={onScroll}
    >
      {[...state.collection].map((item) =>
        item.type === 'section' ? (
          <Section key={item.key} section={item} state={state} />
        ) : (
          <Option key={item.key} item={item} state={state} listItemProps={optionProps} />
        ),
      )}
      {props.loadingState === 'loadingMore' && (
        // Display a spinner at the bottom of the list if we're loading more.
        // role="option" is required for valid ARIA semantics since
        // we're inside a role="listbox".
        <Box role="option" pt="4" pb="2" display="flex" justifyContent="center">
          <Spinner color="blue.400" size="sm" />
        </Box>
      )}
    </List>
  );
}

function Section({
  section,
  state,
  optionProps,
}: {
  section: Node<unknown>;
  state: ListState<unknown>;
  optionProps?: ListItemProps;
}) {
  const { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: section.rendered,
    'aria-label': section['aria-label'],
  });

  return (
    <ListItem
      {...itemProps}
      borderBottom="1px solid"
      borderColor="border.line"
      _last={{ border: '0' }}
    >
      {section.rendered ? (
        <Heading as="p" fontSize="xs" px={4} py={2} pt={4} bg="white" {...headingProps}>
          {section.rendered}
        </Heading>
      ) : null}
      <List {...groupProps} as="ul" p="0" m="0" listStyleType="none">
        {[...section.childNodes].map((node) => (
          <Option key={node.key} item={node} state={state} listItemProps={optionProps} />
        ))}
      </List>
    </ListItem>
  );
}

function Option({ item, state, listItemProps }: OptionProps) {
  const ref = React.useRef<HTMLLIElement>(null);
  const { optionProps, isSelected, isFocused, isDisabled } = useOption(
    {
      key: item.key,
    },
    state,
    ref,
  );

  return (
    <ListItem
      {...optionProps}
      as="li"
      ref={ref}
      px={8}
      py={2}
      fontSize="xs"
      background={isFocused ? 'blue.50' : 'white'}
      color={isFocused ? 'blue.700' : 'gray.700'}
      cursor="pointer"
      opacity={isDisabled ? '0.5' : 1}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      {...listItemProps}
    >
      {item.rendered}
      {isSelected && <Icon as={MdCheck} />}
    </ListItem>
  );
}
