import { createSlice } from '@reduxjs/toolkit';

import { Products } from '@/utils/constants/common';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@/core/store';
import type { TProduct } from '@/types/bookings';


type State = {
  pnrId: string;
  products: TProduct[];
  bannerIndex: number;
};

const initialState: State = {
  pnrId: '',
  products: [],
  bannerIndex: 0,
};

type SaveTripProductsPayload = {
  pnrId: string;
  products: TProduct[];
};

const slice = createSlice({
  name: 'trips',
  initialState,
  reducers: {
    saveTripProducts(state, { payload }: PayloadAction<SaveTripProductsPayload>) {
      if (state.pnrId === payload.pnrId) {
        state.products.push(...payload.products);
      } else {
        state.products = payload.products;
      }

      state.pnrId = payload.pnrId;
    },

    setTripBannerIndex(state, { payload }: PayloadAction<number>) {
      state.bannerIndex = payload;
    },
  },
});

export const tripsReducer = slice.reducer;
export const { saveTripProducts, setTripBannerIndex } = slice.actions;

export const selectIsAddFlightAllowed = (state: RootState) =>
  !state.trips.products.includes(Products.Air);

export const selectTripsBannerIndex = (state: RootState) => state.trips.bannerIndex;
