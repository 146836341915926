import { latinNumberSpaceRegex, numberOptionalRegex } from '@/utils/regex';

import type { TFunction } from 'i18next';
import type { CRDFieldRaw, ValidationsType } from '@/components/Bookings/Checkout/CRD/types';

function addLengthValidation(field: CRDFieldRaw, validations: ValidationsType[], t: TFunction) {
  if (field.minLength) {
    validations.push(
      {
        type: 'min',
        params: [
          field.minLength,
          t('Field must be at least {{count}} characters', { count: field.minLength }),
        ],
      },
      {
        type: 'trim',
        params: [],
      },
    );
  }

  if (field.maxLength) {
    validations.push({
      type: 'max',
      params: [
        field.maxLength,
        t('Field cannot exceed {{count}} characters', { count: field.maxLength }),
      ],
    });
  }
}

function createTextValidation(field: CRDFieldRaw, t: TFunction): ValidationsType[] {
  const validations: ValidationsType[] = [];

  validations.push({
    type: 'matches',
    params: [latinNumberSpaceRegex, t('Only letters and numbers allowed')],
  });

  addLengthValidation(field, validations, t);

  return validations;
}

function createNumberValidation(field: CRDFieldRaw, t: TFunction): ValidationsType[] {
  const validations: ValidationsType[] = [];

  validations.push({
    type: 'matches',
    params: [numberOptionalRegex, t('Enter a number')],
  });

  addLengthValidation(field, validations, t);

  return validations;
}

function createEmailValidation(t: TFunction): ValidationsType[] {
  return [
    {
      type: 'email',
      params: [t('Enter valid email address')],
    },
  ];
}

function createPartialEmailValidation(field: CRDFieldRaw, t: TFunction): ValidationsType[] {
  const validations: ValidationsType[] = [];

  validations.push({
    type: 'test',
    params: [
      'partial email',
      t('Enter the beginning of your email address, stopping at the @ symbol'),
      (value: string) => !/[@\s]/.test(value || ''),
    ],
  });

  addLengthValidation(field, validations, t);

  return validations;
}

function createNonasciiValidation(field: CRDFieldRaw, t: TFunction): ValidationsType[] {
  const validations: ValidationsType[] = [];

  addLengthValidation(field, validations, t);

  return validations;
}

export function createValidations(field: CRDFieldRaw, t: TFunction): ValidationsType[] {
  const validations: ValidationsType[] = [];

  if (field.required) {
    validations.push({ type: 'required', params: [t('This field is required')] });
  }

  switch (field.validationType) {
    case 'number':
      validations.push(...createNumberValidation(field, t));
      break;
    case 'email':
      validations.push(...createEmailValidation(t));
      break;
    case 'partial_email':
      validations.push(...createPartialEmailValidation(field, t));
      break;
    case 'nonascii':
      validations.push(...createNonasciiValidation(field, t));
      break;
    case 'text':
      validations.push(...createTextValidation(field, t));
      break;
    default:
  }

  return validations;
}
