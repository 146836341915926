import React from 'react';
import { Button, HStack, Heading, Icon, Link, Text, VStack } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import { FallingIceCream } from '@/icons';
import { getSupportLink } from '@/utils/constants/links';

export function Fallback() {
  const { t, i18n } = useTranslation();

  return (
    <VStack
      paddingX={8}
      paddingY={6}
      gap={6}
      alignItems="flex-start"
      maxWidth="747px"
      borderRadius={16}
      shadow="lg"
      backgroundColor="white"
      marginX="auto"
      marginTop="200px"
    >
      <HStack gap={7} alignItems="center">
        <Icon as={FallingIceCream} />
        <Heading as="h1" fontSize="h1">
          {t('There’s been a glitch...')}
        </Heading>
      </HStack>
      <VStack gap={8} alignItems="flex-start" fontSize="h3">
        <Text>
          {t('Sorry, this not working properly. Now we know about this and working on a fix.')}
        </Text>
        <Text>
          <Trans t={t}>
            You can{' '}
            <Text as="span" fontWeight="semibold">
              Go back, Refresh the page
            </Text>{' '}
            (sometimes that helps), or{' '}
            <Link href={getSupportLink(i18n.language)} isExternal>
              <Button variant="link" fontWeight="semibold">
                Contact support
              </Button>
            </Link>{' '}
            if you need a hand.
          </Trans>
        </Text>
      </VStack>
    </VStack>
  );
}
