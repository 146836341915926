export const fonts = {
  heading: `'ProximaNova', "Helvetica Neue", Arial, system-ui, sans-serif`,
  body: `'ProximaNova', "Helvetica Neue", Arial, system-ui, sans-serif`,
};

export const fontSizes = {
  ph1: '54px',
  ph2: '42px',
  h1: '2rem', // 32px
  h2: '1.5rem', // 24px
  h3: '1.25rem', // 20px
  h4: '1rem', // 16px
  h5: '0.875rem', // 14px
  h6: '0.75rem', // 12px
  xxs: '0.625rem', // 10px
  xs: '0.75rem', // 12px
  sm: '0.875rem', // 14px
  md: '1rem', // 16px
  lg: '1.125rem', // 18px
};
