import React from 'react';
import { Image } from '@chakra-ui/react';
import { keyBy, mapValues } from 'lodash-es';

import { iconsApiPath } from '@/features/air/constants';
import { useAcceptedVendorOptions } from '@/hooks/useAcceptedVendorOptions';
import fallbackIcon from '@/assets/images/plane-departure-solid.svg';

type Props = {
  iconCode: string;
  testId?: string;
};

export function AirlineIconImg({ iconCode, testId }: Props) {
  const airlines = useAcceptedVendorOptions('air');
  const airlineLabel = mapValues(keyBy(airlines, 'value'), 'label')[iconCode];

  return (
    <Image
      src={`${iconsApiPath}${iconCode}.png`}
      fallbackSrc={fallbackIcon}
      data-testid={testId}
      alt={airlineLabel}
    />
  );
}
