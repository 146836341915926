import React, { memo } from 'react';
import { Box, Card, CardBody, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { CRDField } from './CRDField';
import { testIds } from './testIds';

import type { CRDFieldConfig } from './types';

type CarCRDProps = {
  crdFormKey?: string;
  fields: CRDFieldConfig[];
};

const DEFAULT_CRD_FORM_KEY = 'crd';

function CRDSectionC({ fields, crdFormKey = DEFAULT_CRD_FORM_KEY }: CarCRDProps) {
  const { t } = useTranslation();

  return (
    <Card size="lg" data-testid={testIds.crd}>
      <CardBody>
        <Heading as="h2" fontSize="h3" mb={2}>
          {t('Specify trip details')}
        </Heading>
        <Text mb={4} fontSize="sm" color="text.base.weak">
          {t('Your company has requested additional information about your trip.')}
        </Text>

        <SimpleGrid spacing={3} columns={2} data-testid={testIds.crdFieldsList}>
          {fields.map((field) => (
            <Box key={field.code}>
              <CRDField crdFieldConfig={field} crdFormKey={crdFormKey} />
            </Box>
          ))}
        </SimpleGrid>
      </CardBody>
    </Card>
  );
}

export const CRDSection = memo(CRDSectionC);
