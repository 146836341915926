import React from 'react';

import { RouteType } from '@/types/router';
import { Paths } from '@/app/routes/paths';

import type { AppRoute } from '@/types/router';

const RailOutwardResultsPage = React.lazy(() =>
  import('@/pages/rail/RailOutwardResultsPage').then((module) => ({
    default: module.RailOutwardResultsPage,
  })),
);
const RailInwardResultsPage = React.lazy(() =>
  import('@/pages/rail/RailInwardResultsPage').then((module) => ({
    default: module.RailInwardResultsPage,
  })),
);
const RailCheckoutPage = React.lazy(() =>
  import('@/pages/rail/RailCheckoutPage').then((module) => ({
    default: module.RailCheckoutPage,
  })),
);
const RailReservationPage = React.lazy(() =>
  import('@/pages/rail/RailReservationPage').then((module) => ({
    default: module.RailReservationPage,
  })),
);

export const railRoutes: AppRoute[] = [
  { type: RouteType.DEFAULT, pathname: Paths.RAIL_RESULTS, component: RailOutwardResultsPage },
  {
    type: RouteType.DEFAULT,
    pathname: Paths.RAIL_RETURN_RESULTS,
    component: RailInwardResultsPage,
  },
  { type: RouteType.DEFAULT, pathname: Paths.RAIL_CHECKOUT, component: RailCheckoutPage },
  { type: RouteType.DEFAULT, pathname: Paths.RAIL_RESERVATION, component: RailReservationPage },
];
