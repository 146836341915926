import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { persistor, store } from '@/core/store';
import '@/core/i18n';

// TODO: wrap with <React.StrictMode> once we figure out an alternative for <InputMask />
export function AppProviders({ children }: { children: React.ReactNode }) {
  return (
    <Suspense>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {children}
        </PersistGate>
      </Provider>
    </Suspense>
  );
}
