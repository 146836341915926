import React, { useEffect, useRef } from 'react';
import { Button, HStack, Icon, Link, Text, VStack } from '@chakra-ui/react';
import { MdHelpOutline, MdOutlineContentCopy } from 'react-icons/md';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Alert } from '@/components/Alert';
import { ClipboardText } from '@/components/ClipboardText/ClipboardText';
import { getSupportLink } from '@/utils/constants/links';

import type { AlertProps } from '@/components/Alert/Alert';

export const defaultGlobalErrorAlertTestId = 'global-error-alert';

type TGlobalErrorAlertProps = {
  message: string;
  errorCode?: string;
  onClose?: () => void;
  alertProps?: Partial<AlertProps>;
  closeOnRouteChange?: boolean;
};

export function GlobalErrorAlert({
  message,
  errorCode,
  onClose,
  alertProps,
  closeOnRouteChange = true,
}: TGlobalErrorAlertProps) {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const initialPathname = useRef(pathname);

  useEffect(() => {
    if (initialPathname.current !== pathname && closeOnRouteChange) onClose?.();
  }, [closeOnRouteChange, initialPathname, onClose, pathname]);

  return (
    <Alert
      variant="embedded"
      status="error"
      title={t('Error')}
      dismissible
      onClose={onClose}
      data-testid={defaultGlobalErrorAlertTestId}
      {...alertProps}
    >
      <VStack alignItems="stretch">
        <Text>
          {message}{' '}
          {errorCode && (
            <Trans
              t={t}
              i18nKey="Error code: <Text>{{errorCode}}</Text>"
              components={{
                Text: <Text as="span" fontWeight="semibold" />,
              }}
              values={{
                errorCode,
              }}
            />
          )}
        </Text>
        <HStack spacing={4}>
          {errorCode && (
            <ClipboardText
              text={errorCode}
              rightIcon={undefined}
              leftIcon={<Icon as={MdOutlineContentCopy} color="text.link.default" />}
            >
              {t('Copy error code')}
            </ClipboardText>
          )}
          <Link href={getSupportLink(i18n.language)} isExternal display="flex">
            <Button
              variant="link"
              fontWeight="semibold"
              leftIcon={<Icon as={MdHelpOutline} color="text.link.default" />}
            >
              {t('Contact support')}
            </Button>
          </Link>
        </HStack>
      </VStack>
    </Alert>
  );
}
