import { airHelpers } from '@getgoing/hurricane';
import uniq from 'lodash-es/uniq';
import uniqBy from 'lodash-es/uniqBy';
import { isNil, sum } from 'lodash-es';

import { datetimeUtil, DEFAULT_DATE_FORMAT } from '@/utils/time';
import { getCabinLabels } from '@/features/air/constants';
import { snakeToCapitalized } from '@/utils/text';
import { getFlightSegmentEmissions } from './flightSegment';
import { isDefined } from '@/utils/typeGuards';

import type { BaggageAllowance } from '@getgoing/hurricane/dist/air/types';
import type { OrNull } from '@/types/common';
import type { Dayjs} from '@/utils/time';
import type { Definitions } from '@/types/generated';
import type {
  Cabin,
  ExtendedFlightOptionOrOriginDestination,
  FlightOptionOrSegments,
  FlightOptionSegment,
  ParsedFlightOriginDestination,
  PartialParsedFlightOption,
} from './parsers';
import type { TFunction } from 'i18next';
import type { ParsedPricingFareGroup } from '@/features/air/utils/parsePricingResponse';

function getFirstAndLastSegment(
  flightOption: PartialParsedFlightOption,
): [FlightOptionSegment, FlightOptionSegment] {
  const { segments } = flightOption;
  const { 0: firstSegment, length, [length - 1]: lastSegment } = segments!;

  return [firstSegment, lastSegment];
}

export function getDepartureAndArrivalDate(
  flightOption: PartialParsedFlightOption,
): [Dayjs, Dayjs] {
  const [firstSegment, lastSegment] = getFirstAndLastSegment(flightOption);
  const departureTime = datetimeUtil(firstSegment.departure.datetime);
  const arrivalTime = datetimeUtil(lastSegment.arrival.datetime);

  return [departureTime, arrivalTime];
}

export function getDepartArrivalDifference(departureDate: Dayjs, arrivalDate: Dayjs) {
  return arrivalDate.date() - departureDate.date();
}

export function getDepartureAndArrivalAirport(
  flightOption: PartialParsedFlightOption,
): [Definitions.Airport, Definitions.Airport] {
  const [firstSegment, lastSegment] = getFirstAndLastSegment(flightOption);

  return [firstSegment.departure.airport, lastSegment.arrival.airport];
}

export function getUniqAirlineNames(flightOption: PartialParsedFlightOption): string[] {
  const { segments } = flightOption;
  const airlineNames = segments?.map((segment) => segment.carrier.name);

  return uniq(airlineNames);
}

export function formatDay(date: Dayjs) {
  return date.format('D MMM');
}

export function getDestinationsDatetimeDiffInMinutes(current: string, next: string) {
  return datetimeUtil(next).diff(datetimeUtil(current), 'minute');
}

export function minutesToDuration(mins: number) {
  const hours = Math.floor(mins / 60);
  return { hours, minutes: mins - hours * 60 };
}

export function showDuration(duration: Duration) {
  const { hours, minutes } = duration;

  return `${hours ? `${hours} hr ` : ''}${minutes ? `${minutes} min` : ''}`;
}

export function getSegmentConnectionDuration(current: string, next: string) {
  return showDuration(minutesToDuration(getDestinationsDatetimeDiffInMinutes(current, next)));
}

function flightOptionOrSegmentsGuard(
  arg: FlightOptionOrSegments,
): arg is PartialParsedFlightOption {
  return 'segments' in arg;
}

function getSegments(flightOptionOrSegments: FlightOptionOrSegments): FlightOptionSegment[] {
  if (flightOptionOrSegmentsGuard(flightOptionOrSegments)) {
    return flightOptionOrSegments.segments ?? [];
  }

  return flightOptionOrSegments;
}

export function getCarriersAndOperatingCarriers(flightOptionOrSegments: FlightOptionOrSegments) {
  const segments = getSegments(flightOptionOrSegments);
  const carriers = segments.map((segment) => segment.carrier);
  const uniqCarriers = uniqBy(carriers, 'code');
  const operatingCarriers = uniqBy(
    segments
      .filter(
        (segment) =>
          segment.operatingCarrier && segment.carrier.code !== segment.operatingCarrier.code,
      )
      .map((segment) => segment.operatingCarrier),
    'code',
  );

  return { carriers, uniqCarriers, operatingCarriers };
}

export function getCarriersAndOperatingCarriersCodes(
  flightOptionOrSegments: FlightOptionOrSegments,
) {
  const { uniqCarriers: carriers, operatingCarriers } =
    getCarriersAndOperatingCarriers(flightOptionOrSegments);

  return {
    carriers: carriers.map((carrier) => carrier.code),
    operatingCarriers: operatingCarriers.map((carrier) => carrier.code),
  };
}

export const getFlightEmissions = (flight: ParsedFlightOriginDestination): number | null => {
  if (!flight.segments) {
    return null;
  }

  const emissions = flight.segments.map(getFlightSegmentEmissions);

  if (emissions.length === 0 || emissions.some(isNil)) {
    return null;
  }

  return sum(emissions);
};

export function getCarriersAndOperatingCarriersNames(
  flightOptionOrSegments: FlightOptionOrSegments,
) {
  const { carriers, uniqCarriers, operatingCarriers } =
    getCarriersAndOperatingCarriers(flightOptionOrSegments);

  return {
    carriers: uniqCarriers.map((carrier) => carrier.name),
    carriersCodes: carriers.map((carrier) => `${carrier.code}${carrier.flightNumber}`),
    operatingCarriers: operatingCarriers.map((carrier) => carrier.name),
    carriersWithFlightNumber: carriers.map(
      (carrier) => `${carrier.name} ${carrier.code}${carrier.flightNumber}`,
    ),
  };
}

export function getAmenities(flightOptionOrSegments: FlightOptionOrSegments) {
  const segments = getSegments(flightOptionOrSegments);
  const allAmenities = segments.flatMap((segment) => segment.amenities).filter(Boolean);
  const uniqAmenities = uniqBy(allAmenities, (amenity) => amenity.amenity);

  const meal = uniqAmenities?.find(({ amenity }) => amenity === 'fresh_food')?.displayText;
  const extras = uniqAmenities?.filter(({ amenity }) => amenity !== 'fresh_food');

  return { meal, extras };
}

export function getSegmentByIdRef(
  fareGroup: ParsedPricingFareGroup,
  segmentIdRef: string,
): FlightOptionSegment | undefined {
  const allSegments = fareGroup.originDestinations.flatMap<FlightOptionSegment>(
    (originDestination) => originDestination.segments || [],
  );

  return allSegments.find((segment) => segment.segmentIdRef === segmentIdRef);
}

export const getFlightsCarriers = (flights: ParsedFlightOriginDestination[]) =>
  uniqBy(
    flights.flatMap((flight) => (flight.segments || []).map((segment) => segment.carrier)),
    'code',
  );

export const getFlightsCabins = (flights: ParsedFlightOriginDestination[]) =>
  uniq(flights.flatMap((flight) => flight.cabins));

export function getCabinName(cabin: Cabin, t: TFunction) {
  const labels = getCabinLabels(t);
  const label = labels[cabin];

  return label || snakeToCapitalized(cabin);
}

export const getAirlineCarrierName = (segment: FlightOptionSegment, t: TFunction) => {
  const { carrier, operatingCarrier } = segment;

  if (operatingCarrier?.code === carrier.code) {
    return carrier.name;
  }

  return `${carrier.name} ${t('(Operated by {{name}})', { name: operatingCarrier.name })}`;
};

export function getDateDeltaInDays(flightOption: PartialParsedFlightOption): number {
  const [firstSegment, lastSegment] = getFirstAndLastSegment(flightOption);
  const departure = datetimeUtil(firstSegment.departure.datetime, DEFAULT_DATE_FORMAT);
  const arrival = datetimeUtil(lastSegment.arrival.datetime, DEFAULT_DATE_FORMAT);
  return datetimeUtil(arrival).diff(departure, 'd');
}

export function getAirportChangeBetweenOriginDestinations(
  flightOption: ParsedFlightOriginDestination,
  previousFlightOption?: OrNull<ParsedFlightOriginDestination>,
) {
  const [departureAirport, arrivalAirport] = getDepartureAndArrivalAirport(flightOption);

  if (!previousFlightOption) {
    return {
      departure: {
        airport: departureAirport,
      },
      arrival: {
        airport: arrivalAirport,
      },
    };
  }

  const [selectedDepartureAirport, selectedArrivalAirport] =
    getDepartureAndArrivalAirport(previousFlightOption);

  return {
    departure: {
      airport: departureAirport,
      selectedAirport: selectedDepartureAirport,
      isChanged: departureAirport.code !== selectedArrivalAirport.code,
    },
    arrival: {
      airport: arrivalAirport,
      selectedAirport: selectedArrivalAirport,
      isChanged: arrivalAirport.code !== selectedDepartureAirport.code,
    },
  };
}

export const getFlightPriceDifference = (
  first: ParsedFlightOriginDestination,
  second: ParsedFlightOriginDestination,
) => second.total - first.total;

export const isFlightBookable = (flight: ParsedFlightOriginDestination): boolean =>
  flight.unbookableRules.length === 0;

export const isFlightInPolicy = (flight: ExtendedFlightOptionOrOriginDestination): boolean =>
  flight.outOfPolicyRules.length === 0;

export const isBaggageIncluded = (
  flightOriginDestination: ParsedFlightOriginDestination,
): boolean | null => {
  const baggageAllowances = flightOriginDestination.segments?.map<BaggageAllowance | undefined>(
    (segment) => airHelpers.extractBaggageAllowance(segment),
  );

  return (
    baggageAllowances?.every((baggage) => baggage && airHelpers.isBaggageIncluded(baggage)) ||
    airHelpers.isBaggageIncluded(flightOriginDestination.ticketAttributes.baggage)
  );
};

/**
 * The departure Segment (second one) will be marked with "airportChange" in case of an airport change.
 * For example, in case of Segment 1: DFW - LGA, Segment 2: JFK - LHR
 * Segment 2 will have airportChange: true.
 */
export const isAirportChangeInSegment = (secondSegment: FlightOptionSegment): boolean =>
  secondSegment.airportChange;

export const isAirportChangeInFlightOption = (
  flightOption: ParsedFlightOriginDestination,
): boolean => Boolean(flightOption.segments?.some(isAirportChangeInSegment));

export const getTechStopAirportCodes = (
  flightOption: ParsedFlightOriginDestination | Definitions.PricingOriginDestination,
) => {
  const { segments } = flightOption;

  const techStops = segments?.flatMap((segment) => segment.stops).filter(isDefined);

  if (!techStops?.length) {
    return null;
  }

  return techStops.map((stop) => stop.airport.code);
};
