import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { datetimeLocales, datetimeUtil } from '@/utils/time';
import { SUPPORTED_LOCALES } from '@/core/constants';
import { languageStorageKey, localeToLanguageNameMapping } from '@/app/language/constants';
import { getLocale, isSupportedLocale } from '@/app/language/utils';

import type { TAppLocales, TLanguageOption } from '@/app/language/types';

type TUseLanguageReturn = {
  locale: TAppLocales;
  languageOptions: TLanguageOption[];
  changeLanguage: (language: TAppLocales) => void;
};

export function useLanguage(): TUseLanguageReturn {
  const { t, i18n } = useTranslation();
  const currentLocale = getLocale(i18n.language);
  const languageOptions: TLanguageOption[] = SUPPORTED_LOCALES.map((locale) => ({
    value: locale,
    label: localeToLanguageNameMapping[locale](t),
  }));

  const changeLanguage = useCallback(
    (locale: TAppLocales) => {
      datetimeUtil.locale(locale);
      return i18n.changeLanguage(locale);
    },
    [i18n],
  );

  // Listen to storage event to change language
  useEffect(() => {
    const onStorageLanguageChange = ({ newValue, key }: StorageEvent) => {
      if (key === languageStorageKey && isSupportedLocale(newValue) && newValue !== currentLocale) {
        return changeLanguage(newValue);
      }
    };
    window.addEventListener('storage', onStorageLanguageChange);
    return () => {
      window.removeEventListener('storage', onStorageLanguageChange);
    };
  }, [changeLanguage, currentLocale]);

  // Set dayjs locale when language changes
  useEffect(() => {
    if (datetimeLocales[datetimeUtil.locale()] !== currentLocale) {
      datetimeUtil.locale(currentLocale);
    }
  }, [currentLocale]);

  return {
    locale: currentLocale,
    languageOptions,
    changeLanguage,
  };
}
