import jwtDecode from 'jwt-decode';

import { useAppSelector } from '@/core/hooks';
import { getAccessToken } from '@/core/selectors';

type ParsedTokenType = {
  su_email?: string;
  email: string;
};

export const useParsedAccessToken = () => {
  const accessToken = useAppSelector(getAccessToken);

  return accessToken ? jwtDecode<ParsedTokenType>(accessToken) : null;
};
