import React, { Suspense } from 'react';

import { Spinner } from '@/components/Spinner';

type WithSuspenseProps = {
  children: React.ReactNode;
};

export function WithSuspense({ children }: WithSuspenseProps) {
  return <Suspense fallback={<Spinner isFullHeight />}>{children}</Suspense>;
}
