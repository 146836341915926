import datetime, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import timezonePlugin from 'dayjs/plugin/timezone';
import isToday from 'dayjs/plugin/isToday';
import utc from 'dayjs/plugin/utc';
import durationPlugin from 'dayjs/plugin/duration';

// Supported locales imports ('en' locale is supported by default)
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/pt';
import 'dayjs/locale/es';
import 'dayjs/locale/nn';
import 'dayjs/locale/sv';
import 'dayjs/locale/fr-ca';
import 'dayjs/locale/ja';
import { getLocale } from '@/app/language/utils';
import { languageStorageKey } from '@/app/language/constants';

import type { PluginFunc } from 'dayjs';
import type { TAppLocales } from '@/app/language/types';
import type { Duration as DayjsDuration } from 'dayjs/plugin/duration';

declare module 'dayjs' {
  interface Dayjs {
    ceil(unit: UnitTypeShort, amount: number): Dayjs;
  }
}
const ceilPlugin: PluginFunc = (option, dayjsClass) => {
  dayjsClass.prototype.ceil = function (unit, amount) {
    return this.add(amount - (this.get(unit) % amount), unit).startOf(unit);
  };
};

datetime.extend(ceilPlugin);
datetime.extend(customParseFormat);
datetime.extend(timezonePlugin);
datetime.extend(durationPlugin);
datetime.extend(isSameOrBefore);
datetime.extend(isSameOrAfter);
datetime.extend(utc);
datetime.extend(isToday);
// Set initial dayjs locale based on the current language from i18next
datetime.locale(getLocale(localStorage.getItem(languageStorageKey)));

export const datetimeLocales: Record<string, TAppLocales> = {
  en: 'en-US',
  fr: 'fr-FR',
  de: 'de-DE',
  pt: 'pt-BR',
  es: 'es-ES',
  nn: 'nn-NO',
  sv: 'sv-SE',
  ja: 'ja-JP',
  'fr-ca': 'fr-CA',
};

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_TIME_FORMAT = 'HH:mm';

export const FULL_DATE_FORMAT = 'ddd, D MMM YYYY';
export const EXTENDED_FULL_DATE_FORMAT = 'dddd, D MMM YYYY';

export const FULL_DAY_FORMAT = 'dddd';
export const HOTEL_RECORD_FORMAT = 'MMM DD, YYYY';

export const EUROPEAN_DATE_FORMAT = 'DD/MM/YYYY'; 
export const DOCUMENTS_DATE_FORMAT = 'DD MMM YYYY';

export const ASB_DATETIME_FORMAT = 'YYYY-MM-DD[T]HH:mm';
export const DATETIME_FORMAT_WITH_TIMEZONE = 'YYYY-MM-DD[T]HH:mm:ssZ';

export const DATEPICKER_DATE_FORMAT = 'ddd DD, MMM';
export const DAY_MONTH_FORMAT = 'ddd, MMM DD';

export const TIME_PREFERENCE_FORMATS: Record<string, string> = {
  12: 'h:mm A',
  24: 'HH:mm',
};

export const getTimeFormat = (timeFormat?: string) => {
  if (timeFormat) {
    return TIME_PREFERENCE_FORMATS[timeFormat];
  }
  return TIME_PREFERENCE_FORMATS[12];
};

/**
 * @returns Inputted date in the future or not. Note: current date is not a future.
 */
export const isFutureDate = (date: string | Date, format?: string): boolean => {
  const today = datetime().startOf('day');
  return datetime(date, format).diff(today) > 0;
};

export const formatDate = (date: string, format: string, initFormat?: string) =>
  datetime(date, initFormat).format(format);

const TIME_INTERVAL = 30;
export const getTimeRange = (offsetMins = TIME_INTERVAL): Dayjs[] => {
  const timeRange = [];

  const rangeLength = 24 * (60 / offsetMins);
  const startTime = datetime().startOf('day');

  for (let i = 0; i < rangeLength; i += 1) {
    timeRange.push(startTime.add(i * offsetMins, 'minutes'));
  }

  return timeRange;
};
export const getNearestDateFromRange = (date: Dayjs) => date.ceil('m', TIME_INTERVAL);

export function getDuration(
  startDatetime: string,
  startFormat: string,
  endDatetime: string,
  endFormat: string,
): DayjsDuration {
  return datetime.duration(
    Math.abs(datetime(endDatetime, endFormat).diff(datetime(startDatetime, startFormat))),
  );
}

function formatDuration(duration: DayjsDuration): string {
  const formatPart = (value: number, unit: string): string => (value > 0 ? `${value}${unit} ` : '');

  const days = formatPart(duration.days(), 'd');
  const hours = formatPart(duration.hours(), 'h');
  const minutes = formatPart(duration.minutes(), 'm');

  return `${days}${hours}${minutes}`.trim();
}

export function getFormattedDuration(
  startDatetime: string,
  startFormat: string,
  endDatetime: string,
  endFormat: string,
): string {
  return formatDuration(getDuration(startDatetime, startFormat, endDatetime, endFormat));
}

export const getDaysDiff = (start: string, end: string) =>
  datetime(start, DEFAULT_DATE_FORMAT).diff(datetime(end, DEFAULT_DATE_FORMAT), 'd');

export const compareAsc = (leftDate: string, rightDate: string) => {
  const diff = datetime(leftDate).diff(datetime(rightDate));

  if (diff < 0) {
    return -1;
  }

  if (diff > 0) {
    return 1;
  }
  // Return 0 if diff is 0; return NaN if diff is NaN
  return diff;
};

export const compareDesc = (leftDate: string, rightDate: string) => {
  const diff = datetime(leftDate).diff(datetime(rightDate));

  if (diff > 0) {
    return -1;
  }

  if (diff < 0) {
    return 1;
  }
  // Return 0 if diff is 0; return NaN if diff is NaN
  return diff;
};

export function getStartAtNearestUnit(startDatetime: string): [number, TUnit] {
  const today = datetime().startOf('day');
  const start = datetime(startDatetime).startOf('day');

  if (start.isToday()) {
    return [0, 'today'];
  }

  const dayDiff = start.diff(today, 'day');

  if (dayDiff > 30) {
    const monthDiff = start.diff(today, 'month');
    return [monthDiff, 'month'];
  }

  if (dayDiff > 7) {
    const weekDiff = start.diff(today, 'week');
    return [weekDiff, 'week'];
  }

  return [dayDiff, 'day'];
}

export { datetime as datetimeUtil, Dayjs };
export type { DayjsDuration };
export type TUnit = 'month' | 'week' | 'day' | 'today';
