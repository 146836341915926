import { HOTEL_RECORD_FORMAT, datetimeUtil } from '@/utils/time';
import { isDefined } from '@/utils/typeGuards';

import type { Definitions } from '@/types/generated';

export const getValidatingCarrierCode = (ticket: Definitions.UnusedTicket) =>
  ticket.validatingCarrier.code;

export const getTicketAmount = (ticket: Definitions.UnusedTicket) =>
  Math.floor(ticket.creditAmount.amount);

export const getTicketCurrency = (ticket: Definitions.UnusedTicket) => ticket.creditAmount.currency;

export const getTicketTravelerFullName = (ticket: Definitions.UnusedTicket) =>
  [ticket.traveler?.firstName, ticket.traveler?.lastName].filter(isDefined).join(' ');

export const formatTicketDate = (value: string) => datetimeUtil(value).format(HOTEL_RECORD_FORMAT);
