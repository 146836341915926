import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';

import cn from 'classnames';
import { Box } from '@chakra-ui/react';
import ReactDatepicker from 'react-datepicker';
import React, { forwardRef, useCallback, useRef } from 'react';

import { datetimeUtil } from '@/utils/time';
import { useDatepickerPosition } from './hooks/useDatepickerPosition';
import { useLanguage } from '@/app/language/useLanguage';
import { Portal } from '@/components/Portal';

import type { RefObject } from 'react';
import type { ReactDatePickerProps } from 'react-datepicker';

export type DatepickerRangeProps = ReactDatePickerProps<never, true> & { isOpen: boolean };

export const DatepickerRange = forwardRef<HTMLDivElement, DatepickerRangeProps>(
  ({ children, isOpen, wrapperClassName, ...restProps }, ref) => {
    const calendarRef = useRef<ReactDatepicker<never>>(null);
    const refContainer = useRef(null);
    const { locale } = useLanguage();

    const [positionX, , current, topPosition] = useDatepickerPosition({
      ref: refContainer as RefObject<HTMLDivElement>,
      dimensions: [684, 393],
    });

    const getDayClassName = useCallback((date: Date) => {
      const daysInMonth = datetimeUtil(date).daysInMonth();
      const day = datetimeUtil(date).date();

      if (day === 1) return 'react-datepicker__day--first';
      if (day === daysInMonth) return 'react-datepicker__day--last';

      return null;
    }, []);

    return (
      <Box position="relative" ref={refContainer}>
        {children}
        {isOpen && (
          <Portal>
            <Box
              ref={ref}
              position="absolute"
              left={current?.left}
              top={topPosition}
              width={current?.width}
              height={0}
            >
              <ReactDatepicker
                {...restProps}
                locale={locale}
                calendarClassName={cn('react-datepicker--inline', positionX, 'bottom')}
                dayClassName={getDayClassName}
                selectsRange
                inline
                disabledKeyboardNavigation
                ref={calendarRef}
              />
            </Box>
          </Portal>
        )}
      </Box>
    );
  },
);
