import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { colors } from '@/theme/colors';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tagAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    mb: '1 !important',
    mr: '1 !important',
    ml: '1 !important',
    verticalAlign: 'initial',
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: 'border.formControl',
    borderRadius: 'full',
  },

  label: {
    color: 'text.base.weak',
    fontWeight: 'bold',
  },
});

const variants = {
  solid: definePartsStyle({
    container: {
      backgroundColor: `${colors.background.grayDark} !important`,
      borderColor: colors.background.grayDark,
    },

    label: {
      color: 'white',
      fontWeight: 'bold',
    },
  }),
};

export const TagStyles = defineMultiStyleConfig({ baseStyle, variants });
