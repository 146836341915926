import type { TFunction } from 'i18next';

export const iconsApiPath = 'https://airline-logos.bcdtriptech.com/icons/';
export const columnWidth = ['40px', '86px', '80px', '65px', '105px', 'auto', '72px'];

export const getCabinLabels = (t: TFunction): Record<string, string> => ({
  economy: t('Economy'),
  premium_economy: t('Premium Economy'),
  business: t('Business'),
  premium_business: t('Premium Business'),
  first: t('First'),
  premium_first: t('Premium First'),
});
