import { createSlice } from '@reduxjs/toolkit';
import isEqual from 'lodash-es/isEqual';
import uniqBy from 'lodash-es/uniqBy';

import { getSliceActionsList } from '@/core/utils';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@/core/store';
import type { OrNull } from '@/types/common';
import type {
  TRecentDestinationsInitialState,
  TRecentDestinationsMode,
  TRecentDestinationsWithArrangerInitialState,
  TSetAirRecentDestinationsPayload,
  TSetCarRecentDestinationsPayload,
  TSetHotelRecentDestinationsPayload,
  TSetRailRecentDestinationsPayload,
} from '@/features/recentDestinations/types';

const RECENT_DESTINATIONS_MAX_LENGTH = 10;

export const initialRecentDestinationState: TRecentDestinationsInitialState = {
  air: {
    one: {
      origin: [],
      destination: [],
    },
    round: {
      origin: [],
      destination: [],
    },
    multi: {
      origin: [],
      destination: [],
    },
  },
  hotel: [],
  car: {
    pickUp: [],
    dropOff: [],
  },
  rail: {
    single: {
      origin: [],
      destination: [],
      travelVia: [],
    },
    return: {
      origin: [],
      destination: [],
      travelVia: [],
    },
    open_return: {
      origin: [],
      destination: [],
      travelVia: [],
    },
  },
};

const initialState: TRecentDestinationsWithArrangerInitialState = {
  arranger: initialRecentDestinationState,
  myself: initialRecentDestinationState,
};

const combineRecentDestination = <T>(stateDestinations: T[], newDestination: OrNull<T>) => {
  if (!newDestination) return stateDestinations;
  return [
    newDestination,
    ...stateDestinations.filter((destination) => !isEqual(destination, newDestination)),
  ].slice(0, RECENT_DESTINATIONS_MAX_LENGTH);
};
const combineAirRecentDestinations = <T>(stateDestinations: T[], newDestinations: T[]) =>
  uniqBy([...newDestinations.reverse(), ...stateDestinations], 'id').slice(
    0,
    RECENT_DESTINATIONS_MAX_LENGTH,
  );
const combineRailRecentDestinations = <T>(stateDestinations: T[], newDestinations: T[]) =>
  uniqBy([...newDestinations.reverse(), ...stateDestinations], 'id').slice(
    0,
    RECENT_DESTINATIONS_MAX_LENGTH,
  );
export const getRecentDestinationsStoreKey = (isArrangerMode: boolean): TRecentDestinationsMode =>
  isArrangerMode ? 'arranger' : 'myself';

const slice = createSlice({
  name: 'recentDestinations',
  initialState,
  reducers: {
    setAirRecentDestinations: (
      state,
      {
        payload: { type, key, destinations, isArrangerMode },
      }: PayloadAction<TSetAirRecentDestinationsPayload>,
    ) => {
      const storeKey = getRecentDestinationsStoreKey(isArrangerMode);
      state[storeKey].air[type][key] = combineAirRecentDestinations(
        state[storeKey].air[type][key],
        destinations,
      );
    },
    setHotelRecentDestinations: (
      state,
      {
        payload: { destination, isArrangerMode },
      }: PayloadAction<TSetHotelRecentDestinationsPayload>,
    ) => {
      const storeKey = getRecentDestinationsStoreKey(isArrangerMode);
      state[storeKey].hotel = combineRecentDestination(state[storeKey].hotel, destination);
    },
    setCarRecentDestinations: (
      state,
      {
        payload: { key, destination, isArrangerMode },
      }: PayloadAction<TSetCarRecentDestinationsPayload>,
    ) => {
      const storeKey = getRecentDestinationsStoreKey(isArrangerMode);
      state[storeKey].car[key] = combineRecentDestination(state[storeKey].car[key], destination);
    },
    setRailRecentDestinations: (
      state,
      {
        payload: { key, type, destination, isArrangerMode },
      }: PayloadAction<TSetRailRecentDestinationsPayload>,
    ) => {
      const storeKey = getRecentDestinationsStoreKey(isArrangerMode);
      state[storeKey].rail[type][key] = combineRailRecentDestinations(
        state[storeKey].rail[type][key],
        destination ? [destination] : [],
      );
    },
  },
});

export const recentDestinationsReducer = slice.reducer;

export const {
  setAirRecentDestinations,
  setHotelRecentDestinations,
  setCarRecentDestinations,
  setRailRecentDestinations,
} = slice.actions;
export const selectRecentDestinationsSlice = (state: RootState) => state.recentDestinations;

export const recentDestinationsActionsSyncList = getSliceActionsList(slice, [
  'setAirRecentDestinations',
  'setHotelRecentDestinations',
  'setCarRecentDestinations',
  'setRailRecentDestinations',
]);
