import { GIGYA_API_KEY_GLOBAL, GIGYA_API_KEY_US } from '@/core/constants';

import type { Definitions } from '@/types/generated';

export const GIGYA_WAIT_CHECK_TIME_MS = 100;
export const GIGYA_WAIT_TIMEOUT_MS = 60000;

export const gigyaApiKeyByRegion: Record<Definitions.CheckSSOResponse['region'], string> = {
  us: GIGYA_API_KEY_US,
  global: GIGYA_API_KEY_GLOBAL,
};
