import { breadcrumbAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  container: {
    position: 'relative',
  },
  list: {
    position: 'absolute',
    top: '-35px',
    left: 0,
    fontSize: 'xs',
  },
  item: {
    color: 'text.link.gray',

    ':last-child': {
      span: {
        color: 'text.link.disabled',
        textDecoration: 'none',
        cursor: 'default',
      },
    },
  },
});

const variants = {
  light: {
    item: {
      color: 'white',
      opacity: '0.85',
    },
  },
};

export const BreadcrumbStyles = defineMultiStyleConfig({
  baseStyle,
  variants,
});
