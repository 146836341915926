import type { TypedLocation } from '@/features/air';

type TNullableOriginAndDestination = {
  origin?: TypedLocation | null;
  destination?: TypedLocation | null;
};
type TValidOriginAndDestination = {
  origin: TypedLocation;
  destination: TypedLocation;
};

export const everyItemHasOriginAndDestination = <T extends TNullableOriginAndDestination>(
  data: T[],
): data is (T & TValidOriginAndDestination)[] =>
  data.every((item) => !!(item.origin && item.destination));
