import { Box, TagLabel } from '@chakra-ui/react';
import omit from 'lodash-es/omit';
import React from 'react';

import type { GroupBase, MultiValueGenericProps } from 'react-select';

export function MultiValueLabel<Option, isMulti extends boolean, Group extends GroupBase<Option>>({
  children,
  innerProps,
}: MultiValueGenericProps<Option, isMulti, Group>) {
  return (
    <Box {...omit(innerProps, 'css')}>
      <TagLabel>{children}</TagLabel>
    </Box>
  );
}
