import React, { useCallback, useRef } from 'react';
import {
  Box,
  CloseButton,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  chakra
} from '@chakra-ui/react';
import { useComboBox } from '@react-aria/combobox';
import { useButton } from '@react-aria/button';
import { useFilter } from '@react-aria/i18n';
import { useComboBoxState } from '@react-stately/combobox';

import { AutocompletePopover } from './AutocompletePopover';
import { AutocompleteListBox } from './AutocompleteListBox';

import type {
  InputProps,
  ListItemProps} from '@chakra-ui/react';
import type { ComboBoxProps } from '@react-types/combobox';
import type { LoadingState } from '@react-types/shared';

export { Item, Section } from '@react-stately/collections';

export interface AutocompleteProps<T> extends ComboBoxProps<T> {
  isLoading?: boolean;
  loadingState?: LoadingState;
  isClearable?: boolean;
  onLoadMore?: () => void;
  onClear?: () => void;
  className?: string;
  size?: InputProps['size'];
  inputLeftElement?: JSX.Element;
  inputRightElement?: JSX.Element;
  triggerButton?: JSX.Element;
  inputProps?: InputProps;
  optionProps?: ListItemProps;
  isRequired?: boolean;
  isInvalid?: boolean;
  errorMessage?: React.ReactNode;
}

function Autocomplete<T extends Record<string, any>>({
  isLoading,
  inputLeftElement,
  inputRightElement,
  triggerButton,
  inputProps,
  optionProps,
  className,
  isRequired,
  isInvalid,
  ...props
}: AutocompleteProps<T>) {
  const { contains } = useFilter({ sensitivity: 'base' });
  const state = useComboBoxState<T>({
    defaultFilter: contains,
    allowsEmptyCollection: true,
    shouldCloseOnBlur: true,
    ...props,
  });

  const inputRef = useRef(null);
  const listBoxRef = useRef(null);
  const popoverRef = useRef(null);
  const buttonRef = useRef(null);

  const {
    buttonProps: triggerProps,
    inputProps: comboboxInputProps,
    listBoxProps,
    labelProps,
  } = useComboBox(
    {
      ...props,
      inputRef,
      listBoxRef,
      popoverRef,
    },
    state,
  );

  const { buttonProps } = useButton(triggerProps, buttonRef);

  const handleClear = useCallback(() => {
    state.close();
    state.setSelectedKey('');
    state.setInputValue('');
    state.revert();
    props.onClear?.();
  }, [state, props.onClear]);

  return (
    <Box position="relative" className={className}>
      <FormControl
        isInvalid={!!(isInvalid || props.errorMessage)}
        isRequired={isRequired}
        ref={inputRef}
      >
        <FormLabel {...labelProps}>{props.label}</FormLabel>
        <InputGroup size={props.size} {...inputProps}>
          {inputLeftElement && <InputLeftElement>{inputLeftElement}</InputLeftElement>}
          <Input
            {...comboboxInputProps}
            size={props.size}
            textOverflow="ellipsis"
            {...inputProps}
          />
          <InputRightElement>
            {triggerButton
              ? React.cloneElement(triggerButton, {
                  ...buttonProps,
                  isOpen: state.isOpen,
                  ref: buttonRef,
                })
              : inputRightElement || (
                  <AutocompleteRightElement
                    loadingState={props.loadingState}
                    isClearable={props.isClearable && state.inputValue !== ''}
                    onClear={handleClear}
                  />
                )}
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage>{props.errorMessage}</FormErrorMessage>
      </FormControl>
      {state.isOpen && (
        <AutocompletePopover
          popoverRef={popoverRef}
          triggerRef={inputRef}
          state={state}
          placement="bottom start"
        >
          {state.collection.size > 0 && (
            <AutocompleteListBox
              {...listBoxProps}
              listBoxRef={listBoxRef}
              state={state}
              loadingState={props.loadingState}
              onLoadMore={props.onLoadMore}
            />
          )}
        </AutocompletePopover>
      )}
    </Box>
  );
}

interface AutocompleteRightElementProps {
  loadingState?: LoadingState;
  isClearable?: boolean;
  onClear?: () => void;
}

function AutocompleteRightElement({
  loadingState,
  isClearable,
  onClear,
}: AutocompleteRightElementProps) {
  if (loadingState === 'loading' || loadingState === 'filtering') {
    return <Spinner color="blue.400" size="sm" />;
  }

  if (isClearable) {
    return <CloseButton onClick={onClear} size="sm" color="border.formControl" />;
  }

  return null;
}

/** @deprecated - use Select/AsyncSelect instead */
// eslint-disable-next-line import/no-default-export
export default chakra(Autocomplete);
