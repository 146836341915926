import { MultiValueContainer } from '@/components/Select/components/MultiValueContainer';
import { MultiValueLabel } from '@/components/Select/components/MultiValueLabel';
import { MultiValueRemove } from '@/components/Select/components/MultiValueRemove';
import { ClearIndicator } from '@/components/Select/components/ClearIndicator';
import { DropdownIndicator } from '@/components/Select/components/DropdownIndicator';
import { Option } from '@/components/Select/components/Option';
import { Control } from '@/components/Select/components/Control';
import { LoadingIndicator } from '@/components/Select/components/LoadingIndicator';
import { IndicatorsContainer } from '@/components/Select/components/IndicatorsContainer';
import { GroupHeading } from '@/components/Select/components/GroupHeading';
import { ValueContainer } from '@/components/Select/components/ValueContainer';
import { Input } from '@/components/Select/components/Input';
import { MenuList } from '@/components/Select/components/MenuList';
import { SingleValue } from '@/components/Select/components/SingleValue';

export const selectComponents = {
  IndicatorSeparator: null,
  MultiValueContainer,
  MultiValueLabel,
  MultiValueRemove,
  ClearIndicator,
  DropdownIndicator,
  Option,
  LoadingIndicator,
  Control,
  IndicatorsContainer,
  GroupHeading,
  ValueContainer,
  Input,
  MenuList,
  SingleValue,
};
