import React from 'react';
import { RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { buildRoutes } from '@/utils/router';
import { routes } from '@/app/routes';
import { AppWithRouterProviders } from '@/app/AppWithRouterProviders';
import { Fallback } from '@/app/AppErrorBoundary/Fallback';

const router = createBrowserRouter([
  {
    id: 'root',
    path: '/',
    element: <AppWithRouterProviders />,
    errorElement: <Fallback />,
    children: createRoutesFromElements(buildRoutes(routes)),
  },
]);

export function App() {
  return <RouterProvider router={router} />;
}
