import React from 'react';
import { Table, TableContainer, Tbody } from '@chakra-ui/react';

import type { ContentfulNodeProps } from './types';

export function ContentfulTable({ children }: ContentfulNodeProps) {
  return (
    <TableContainer>
      <Table variant="simple" size="sm" mb={2}>
        <Tbody>{children}</Tbody>
      </Table>
    </TableContainer>
  );
}
