import type { TFunction } from 'i18next';
import type { TAppLocales } from '@/app/language/types';

export const languageFallback: TAppLocales = 'en-US';
export const languageQueryParamKey = 'lang';
export const languageStorageKey = 'i18nextLng';

type TGetLanguageName = (t: TFunction) => string;
export const localeToLanguageNameMapping: Record<TAppLocales, TGetLanguageName> = {
  'en-US': (t) => t('English'),
  'fr-FR': (t) => t('French'),
  'de-DE': (t) => t('German'),
  'pt-BR': (t) => t('Portuguese'),
  'es-ES': (t) => t('Spanish'),
  'nn-NO': (t) => t('Norwegian'),
  'sv-SE': (t) => t('Swedish'),
  'ja-JP': (t) => t('Japanese'),
  'fr-CA': (t) => t('French Canadian'),
};
