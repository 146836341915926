import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@/core/store';

export enum CommandExecutorCommand {
  ChangeCompany,
}

export enum CommandExecutorStatus {
  Idle,
  Blocked,
  Unblocked,
  Completed,
  Canceled,
}

type UnifiedBookingBlocker = {
  command: CommandExecutorCommand | null;
  status: CommandExecutorStatus;
};

const initialState: UnifiedBookingBlocker = {
  command: null,
  status: CommandExecutorStatus.Idle,
};

const slice = createSlice({
  name: 'commandExecutor',
  initialState,
  reducers: {
    beforeCommand(state, { payload }: PayloadAction<CommandExecutorCommand>) {
      state.command = payload;
      state.status = CommandExecutorStatus.Blocked;
    },
    proceedCommand(state) {
      state.status = CommandExecutorStatus.Unblocked;
    },
    afterCommand(state) {
      state.status = CommandExecutorStatus.Completed;
    },
    cancelCommand(state) {
      state.status = CommandExecutorStatus.Canceled;
    },
  },
});

export const commandExecutorReducer = slice.reducer;
export const { beforeCommand, cancelCommand, proceedCommand, afterCommand } = slice.actions;

export const selectCommandExecutorStatus = (state: RootState) => state.commandExecutor.status;
export const selectCommandExecutorCommand = (state: RootState) => state.commandExecutor.command;
