import * as Yup from 'yup';


import { datetimeUtil } from '@/utils/time';
import { latinSpaceRegex } from './regex';

import type { TFunction } from 'i18next';

export const REQUIRED_FIELD_MESSAGE = (t: TFunction) => t('This field is required');

export const ISODateSchema = Yup.string().test({
  name: 'is-iso-date-format',
  test: (date) => datetimeUtil(date).isValid(),
});

export const createNameValidationSchema = (t: TFunction) =>
  Yup.string()
    .trim()
    .required(REQUIRED_FIELD_MESSAGE(t))
    .matches(latinSpaceRegex, t('Only letters allowed'));
