import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { ChakraProvider, useMediaQuery } from '@chakra-ui/react';
import { OverlayProvider } from '@react-aria/overlays';

import { breakpoints } from '@/theme/breakpoints';
import { Fonts } from '@/theme/fonts/Fonts';
import { Spinner } from '@/components/Spinner';
import { theme } from '@/theme';
import { useNavigationScrollTop } from '@/hooks/useNavigationScrollTop';
import { AppErrorBoundary } from '@/app/AppErrorBoundary';
import { MetaInitialLayout } from '@/app/MetaInitialLayout';
import { LoginAsIndicator } from '@/app/LoginAsIndicator';

import type { ToastProviderProps } from '@chakra-ui/react';

export function AppWithRouterProviders() {
  useNavigationScrollTop();
  const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.md})`, {
    ssr: false,
  });

  const toastOptions: ToastProviderProps = {
    defaultOptions: {
      containerStyle: {
        marginLeft: isDesktop ? theme.space[16] : 0,
      },
    },
  };

  return (
    <ChakraProvider theme={theme} toastOptions={toastOptions}>
      <Fonts />
      <Suspense fallback={<Spinner isFullHeight />}>
        <OverlayProvider>
          <AppErrorBoundary>
            <MetaInitialLayout>
              <LoginAsIndicator>
                <Outlet />
              </LoginAsIndicator>
            </MetaInitialLayout>
          </AppErrorBoundary>
        </OverlayProvider>
      </Suspense>
    </ChakraProvider>
  );
}
