import React from 'react';

import type { SVGProps } from 'react';

export function Error(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        d="M0.666992 13.0065H15.3337L8.00033 0.339844L0.666992 13.0065ZM8.66699 11.0065H7.33366V9.67318H8.66699V11.0065ZM8.66699 8.33984H7.33366V5.67318H8.66699V8.33984Z"
      />
    </svg>
  );
}
