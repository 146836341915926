import { seatMapHelpers } from '@getgoing/hurricane';

import type { Definitions } from '@/types/generated';

export interface ParsedSeatMapResponse {
  currency: string;
  seatMaps: seatMapHelpers.ParsedSeatMap[];
}

export function parseSeatMapResponse(
  response: Definitions.AirSeatMapResponse,
): ParsedSeatMapResponse {
  const { seatMaps, currency } = response;

  return {
    currency,
    seatMaps: seatMaps?.map((seatMap) => seatMapHelpers.parseSeatMap(seatMap)),
  };
}
