/* eslint-disable import/no-default-export */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { IS_DEV, SUPPORTED_LOCALES, TEST_LOCALE } from './constants';
import { languageFallback, languageQueryParamKey } from '@/app/language/constants';

import type { TAppLocales } from '@/app/language/types';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnNull: false,
    debug: IS_DEV,
    fallbackLng: languageFallback,
    returnEmptyString: false,
    supportedLngs: [...SUPPORTED_LOCALES, TEST_LOCALE],
    detection: {
      lookupQuerystring: languageQueryParamKey,
      convertDetectedLanguage: (lng) => {
        const norwegianFallback: TAppLocales = 'nn-NO';
        if (['nn', 'no', 'nb'].includes(lng)) return norwegianFallback;
        return lng;
      },
    },
    load: 'currentOnly',
    interpolation: { escapeValue: false },
    backend: {
      loadPath: '/locales/{{lng}}.json',
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['strong', 'i', 'p'],
    },
  });

export default i18n;
