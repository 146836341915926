import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const CheckboxStyles: ComponentStyleConfig = {
  baseStyle: () => ({
    container: {
      alignItems: 'flex-start',
    },
    label: {
      lineHeight: 1.4,
      _disabled: {
        color: 'text.link.disabled',
        opacity: '1',
      },
    },

    control: {
      background: 'white',
      borderColor: 'primary.blue1',
      border: '1px solid',
      mt: '.25rem',

      _hover: {
        background: 'background.blue',
        borderColor: 'primary.blue2',

        _checked: {
          background: 'primary.blue2',
          borderColor: 'primary.blue2',
        },

        _indeterminate: {
          background: 'primary.blue2',
          borderColor: 'primary.blue2',
        },
      },

      _checked: {
        background: 'primary.blue1',
        borderColor: 'primary.blue1',
      },

      _indeterminate: {
        background: 'primary.blue1',
        borderColor: 'primary.blue1',
      },

      _focusVisible: {
        borderColor: 'primary.blue3',

        _checked: {
          background: 'primary.blue3',
        },
      },

      _disabled: {
        background: 'background.disabled !important',
        borderColor: 'text.link.disabled !important',

        _checked: {
          color: 'white',
          background: 'text.link.disabled !important',
          borderColor: 'text.link.disabled !important',
        },
      },

      _invalid: {
        background: 'white !important',
        borderColor: 'text.stateful.error !important',

        _checked: {
          color: 'white',
          background: 'text.stateful.error !important',
          borderColor: 'text.stateful.error !important',
        },
      },
    },
  }),
  variants: {
    currentColor: {
      control: {
        borderColor: 'currentColor',
        color: 'currentColor',
      },
    },
  },
};
