import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { RouteType } from '@/types/router';
import { Paths } from '@/app/routes/paths';
import { hotelsRoutes } from '@/app/routes/hotelsRoutes';
import { airRoutes } from '@/app/routes/airRoutes';
import { carsRoutes } from '@/app/routes/carsRoutes';
import { railRoutes } from '@/app/routes/railRoutes';
import { authRoutes } from '@/app/routes/authRoutes';
import { Products } from '@/utils/constants/common';

import type { AppRoute } from '@/types/router';

const AuthLayout = React.lazy(() =>
  import('@/features/auth/components/authLayout/AuthLayout').then((module) => ({
    default: module.AuthLayout,
  })),
);
const AppGenericLayout = React.lazy(() =>
  import('@/app/AppGenericLayout').then((module) => ({
    default: module.AppGenericLayout,
  })),
);
const FeatureToggleGuard = React.lazy(() =>
  import('@/app/FeatureToggle').then((module) => ({
    default: module.FeatureToggleGuard,
  })),
);
const AppLayout = React.lazy(() =>
  import('@/app/AppLayout').then((module) => ({
    default: module.AppLayout,
  })),
);
const ArrangerSearchParamsTracker = React.lazy(() =>
  import(
    '@/features/arranger/components/ArrangerSearchParamsTracker/ArrangerSearchParamsTracker'
  ).then((module) => ({
    default: module.ArrangerSearchParamsTracker,
  })),
);
const AuthWithCookie = React.lazy(() =>
  import('@/features/auth/components/authWithCookie/AuthWithCookie').then((module) => ({
    default: module.AuthWithCookie,
  })),
);
const TermsAndConditionsLayout = React.lazy(() =>
  import('@/features/auth/components/authLayout/TermsAndConditionsLayout').then((module) => ({
    default: module.TermsAndConditionsLayout,
  })),
);
const SSOPage = React.lazy(() =>
  import('@/features/SSO/SSOPage').then((module) => ({
    default: module.SSOPage,
  })),
);
const TripPage = React.lazy(() =>
  import('@/pages/TripPage').then((module) => ({
    default: module.TripPage,
  })),
);
const DashboardPage = React.lazy(() =>
  import('@/pages/DashboardPage').then((module) => ({
    default: module.DashboardPage,
  })),
);
const FAQPage = React.lazy(() =>
  import('@/pages/FAQPage').then((module) => ({
    default: module.FAQPage,
  })),
);
const PrivacyPolicyPage = React.lazy(() =>
  import('@/pages/PrivacyPolicyPage').then((module) => ({
    default: module.PrivacyPolicyPage,
  })),
);
const ProfilePage = React.lazy(() =>
  import('@/pages/ProfilePage').then((module) => ({
    default: module.ProfilePage,
  })),
);
const MessagesPage = React.lazy(() =>
  import('@/pages/MessagesPage').then((module) => ({
    default: module.MessagesPage,
  })),
);
const TravelProgramPage = React.lazy(() =>
  import('@/pages/TravelProgramPage').then((module) => ({
    default: module.TravelProgramPage,
  })),
);
const TermsConditionsPage = React.lazy(() =>
  import('@/pages/TermsConditionsPage').then((module) => ({
    default: module.TermsConditionsPage,
  })),
);
const BookTripPage = React.lazy(() =>
  import('@/pages/BookTripPage').then((module) => ({
    default: module.BookTripPage,
  })),
);
const CreditsPage = React.lazy(() =>
  import('@/pages/CreditsPage').then((module) => ({
    default: module.CreditsPage,
  })),
);
const UnifiedBooking = React.lazy(() =>
  import('@/features/unifiedBooking').then((module) => ({
    default: module.UnifiedBookingRouter,
  })),
);
const SignUpSuccessPage = React.lazy(() =>
  import('@/pages/auth/SignUpSuccessPage').then((module) => ({
    default: module.SignUpSuccessPage,
  })),
);

const AgentChatInit = React.lazy(() =>
  import('@/features/agentChat/AgentChatCloudInitPage').then((module) => ({
    default: module.AgentChatCloudInitPage,
  })),
);

const ContentfulPreview = React.lazy(() =>
  import('@/features/contentPreview').then((module) => ({
    default: module.ContentfulPreview,
  })),
);

const LoginAsUser = React.lazy(() =>
  import('@/features/auth').then((module) => ({ default: module.LoginAsUser })),
);

function DefaultRedirect() {
  return <Navigate to={Paths.DASHBOARD} />;
}

export const routes: AppRoute[] = [
  {
    // Temporary workaround for logging in OneTrust bot to check Thunderbolt's cookies.
    type: RouteType.CONTAINER,
    component: AuthWithCookie,
    children: [
      {
        type: RouteType.DEFAULT,
        pathname: Paths.LOGIN,
        component: AuthLayout,
        children: authRoutes,
      },
    ],
  },
  { type: RouteType.DEFAULT, pathname: Paths.LOGIN_AS_USER, component: LoginAsUser },
  {
    type: RouteType.DEFAULT,
    pathname: Paths.SIGN_UP_SUCCESS,
    component: SignUpSuccessPage,
  },
  {
    type: RouteType.DEFAULT,
    pathname: Paths.COMPANY_SIGN_UP_SUCCESS,
    component: SignUpSuccessPage,
  },
  {
    type: RouteType.CONTAINER,
    component: AppGenericLayout,
    children: [
      {
        type: RouteType.CONTAINER,
        component: AppLayout,
        children: [
          { type: RouteType.INDEX, component: DefaultRedirect },
          {
            type: RouteType.DEFAULT,
            pathname: Paths.BOOK_TRIP,
            component: BookTripPage,
          },
          {
            type: RouteType.DEFAULT,
            pathname: Paths.CREDITS,
            component: CreditsPage,
          },
          {
            type: RouteType.DEFAULT,
            pathname: Paths.UNIFIED_BOOKING,
            component: UnifiedBooking,
          },
          {
            type: RouteType.DEFAULT,
            pathname: Paths.CARS,
            component: () => (
              <FeatureToggleGuard product={Products.Car} name="bookEnabled">
                <ArrangerSearchParamsTracker>
                  <Outlet />
                </ArrangerSearchParamsTracker>
              </FeatureToggleGuard>
            ),
            children: carsRoutes,
          },
          {
            type: RouteType.DEFAULT,
            pathname: Paths.AIR,
            component: () => (
              <FeatureToggleGuard product={Products.Air} name="bookEnabled">
                <ArrangerSearchParamsTracker>
                  <Outlet />
                </ArrangerSearchParamsTracker>
              </FeatureToggleGuard>
            ),
            children: airRoutes,
          },
          {
            type: RouteType.DEFAULT,
            pathname: Paths.HOTELS,
            component: () => (
              <FeatureToggleGuard product={Products.Hotel} name="bookEnabled">
                <ArrangerSearchParamsTracker>
                  <Outlet />
                </ArrangerSearchParamsTracker>
              </FeatureToggleGuard>
            ),
            children: hotelsRoutes,
          },
          {
            type: RouteType.DEFAULT,
            pathname: Paths.RAIL,
            component: () => (
              <FeatureToggleGuard product={Products.Rail} name="bookEnabled">
                <ArrangerSearchParamsTracker>
                  <Outlet />
                </ArrangerSearchParamsTracker>
              </FeatureToggleGuard>
            ),
            children: railRoutes,
          },
          {
            type: RouteType.DEFAULT,
            pathname: Paths.DASHBOARD,
            component: DashboardPage,
          },
          {
            type: RouteType.DEFAULT,
            pathname: Paths.DASHBOARD_FILTER,
            component: DashboardPage,
          },
          {
            type: RouteType.DEFAULT,
            pathname: Paths.TRAVEL_PROGRAM,
            component: () => (
              <FeatureToggleGuard name="allowToViewTravelProgram">
                <TravelProgramPage />
              </FeatureToggleGuard>
            ),
          },
          { type: RouteType.DEFAULT, pathname: Paths.MESSAGES, component: MessagesPage },
          {
            type: RouteType.DEFAULT,
            pathname: Paths.MY_PROFILE,
            component: ProfilePage,
          },
          {
            type: RouteType.DEFAULT,
            pathname: Paths.PRIVACY_POLICY,
            component: PrivacyPolicyPage,
          },
          {
            type: RouteType.DEFAULT,
            pathname: Paths.TERMS_CONDITIONS,
            component: TermsConditionsPage,
          },
          { type: RouteType.DEFAULT, pathname: Paths.FAQ, component: FAQPage },
        ],
      },
      { type: RouteType.DEFAULT, pathname: Paths.TRIPS, component: TripPage },
    ],
  },
  {
    type: RouteType.DEFAULT,
    pathname: Paths.TERMS_AND_CONDITIONS,
    component: TermsAndConditionsLayout,
  },
  {
    type: RouteType.DEFAULT,
    pathname: Paths.COMPANY_TERMS_AND_CONDITIONS,
    component: TermsAndConditionsLayout,
  },
  { type: RouteType.DEFAULT, pathname: Paths.PAGE_NOT_FOUND, component: DefaultRedirect },
  { type: RouteType.DEFAULT, pathname: Paths.AGENT_CHAT_INIT, component: AgentChatInit },
  { type: RouteType.DEFAULT, pathname: Paths.SSO, component: SSOPage },
  { type: RouteType.DEFAULT, pathname: Paths.PREVIEW, component: ContentfulPreview },
];
