import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  switchAnatomy.keys,
);

const baseStyle = definePartsStyle({
  track: {
    bg: 'border.decor',
    _checked: {
      bg: 'primary.blue1',
    },
  },
});

export const SwitchStyles = defineMultiStyleConfig({ baseStyle });
