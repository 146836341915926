import { alertAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import type { AlertStatuses } from './Alert';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  alertAnatomy.keys,
);

type TAlertColorMap = Record<
  AlertStatuses,
  { borderColor: string; backgroundColor: string; iconColor: string }
>;

const embeddedAlertColorsMap: TAlertColorMap = {
  error: { borderColor: '#C8102E', backgroundColor: '#FCD9DF', iconColor: '#C8102E' },
  warning: { borderColor: '#D54414', backgroundColor: '#FFF3E7', iconColor: '#FF9E1B' },
  success: { borderColor: '#8DC73F', backgroundColor: '#EFFAE1', iconColor: '#8DC73F' },
  info: { borderColor: '#00629B', backgroundColor: '#E5F0FF', iconColor: '#00629B' },
};

const primaryAlertColorsMap: TAlertColorMap = {
  error: { borderColor: '#C8102E', backgroundColor: '#ED1D3F', iconColor: 'white' },
  warning: { borderColor: '#FF9E1B', backgroundColor: '#DD730C', iconColor: 'white' },
  success: { borderColor: '#8DC73F', backgroundColor: '#6A982C', iconColor: 'white' },
  info: { borderColor: '#3763A0', backgroundColor: '#00629B', iconColor: 'white' },
};

const baseStyle = definePartsStyle({
  container: {
    border: '1px solid',
    borderRadius: 'md',
    alignItems: 'flex-start',
    py: 2,
    px: 4,
  },
  title: {
    color: 'text.base.default',
    fontSize: 'lg',
    fontWeight: 'normal',
    position: 'relative',
    m: 0,
  },
  description: {
    color: 'text.base.default',
    fontSize: 'sm',
  },
  icon: {
    boxSize: 4,
    mt: '0.25em',
  },
});

const embedded = definePartsStyle(({ status }) => ({
  container: {
    borderColor: embeddedAlertColorsMap[status as AlertStatuses].borderColor,
    backgroundColor: embeddedAlertColorsMap[status as AlertStatuses].backgroundColor,
  },
  icon: {
    color: embeddedAlertColorsMap[status as AlertStatuses].iconColor,
  },
}));

const primary = definePartsStyle(({ status }) => ({
  container: {
    borderColor: primaryAlertColorsMap[status as AlertStatuses].borderColor,
    backgroundColor: primaryAlertColorsMap[status as AlertStatuses].backgroundColor,
  },
  title: {
    color: 'white',
  },
  description: {
    color: 'white',
  },
  icon: {
    color: primaryAlertColorsMap[status as AlertStatuses].iconColor,
  },
}));

const variants = {
  embedded,
  primary,
};

export type TAlertVariant = keyof typeof variants;

export const AlertStyles = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: 'primary',
  },
});
