import React from 'react';

import { RouteType } from '@/types/router';
import { Paths } from '@/app/routes/paths';

import type { AppRoute } from '@/types/router';

const CarsResultsPage = React.lazy(() =>
  import('@/pages/cars/CarsResultsPage').then((module) => ({
    default: module.CarsResultsPage,
  })),
);
const CarCheckoutPage = React.lazy(() =>
  import('@/pages/cars/CarCheckoutPage').then((module) => ({
    default: module.CarCheckoutPage,
  })),
);
const CarsReservationPage = React.lazy(() =>
  import('@/pages/cars/CarsReservationPage').then((module) => ({
    default: module.CarsReservationPage,
  })),
);

export const carsRoutes: AppRoute[] = [
  { type: RouteType.DEFAULT, pathname: Paths.CARS_RESULTS, component: CarsResultsPage },
  { type: RouteType.DEFAULT, pathname: Paths.CARS_CHECKOUT, component: CarCheckoutPage },
  {
    type: RouteType.DEFAULT,
    pathname: Paths.CARS_RESERVATION,
    component: CarsReservationPage,
  },
];
