import React from 'react';
import { components } from 'react-select';

import { testIds } from '../testIds';

import type { GroupBase, SingleValueProps } from 'react-select';

export function SingleValue<Option, isMulti extends boolean, Group extends GroupBase<Option>>({
  children,
  ...data
}: SingleValueProps<Option, isMulti, Group>) {
  return (
    <components.SingleValue
      {...data}
      // @ts-ignore
      innerProps={{ ...data.innerProps, 'data-testid': testIds.value }}
    >
      {children}
    </components.SingleValue>
  );
}
