import { extendWithArrangerHeaders } from '@/features/arranger';
import { spitfire } from '@/core/spitfire';
import { FETCH_ERROR_CODE } from '@/core/errors/constants';

import type { TArrangerQueryRequest} from '@/features/arranger';
import type { Definitions } from '@/types/generated';
import type { TDestinationItem } from '@/features/destinationLookup';


const service = spitfire.injectEndpoints({
  endpoints: (builder) => ({
    getAvailability: builder.query<
      GetAvailabilityResponse,
      TArrangerQueryRequest<GetAvailabilityRequest>
    >({
      query: ({ currentTraveler, ...body }) =>
        extendWithArrangerHeaders({
          args: {
            url: 'bookings/cars/get_availability',
            method: 'POST',
            body,
          },
          currentTraveler,
        }),
      extraOptions: {
        errorHandlerSkipOptions: {
          skipByStatusCodes: [404],
        },
      },
    }),
    getRateDetails: builder.query<
      GetRateDetailsResponse,
      TArrangerQueryRequest<GetRateDetailsRequest>
    >({
      query: ({ currentTraveler, ...body }) =>
        extendWithArrangerHeaders({
          args: {
            url: 'bookings/cars/get_rate_details',
            method: 'POST',
            body,
          },
          currentTraveler,
        }),
    }),
    getCheckoutData: builder.query<
      Definitions.CarsGetCheckoutDataResponse,
      TArrangerQueryRequest<GetCheckoutDataRequest>
    >({
      query: ({ currentTraveler, ...body }) =>
        extendWithArrangerHeaders({
          args: {
            url: 'bookings/cars/get_checkout_data',
            method: 'POST',
            body,
          },
          currentTraveler,
        }),
    }),
    getReservationDetails: builder.query<
      GetReservationDetailsResponse,
      TArrangerQueryRequest<GetReservationDetailsRequest>
    >({
      query: ({ currentTraveler, ...body }) =>
        extendWithArrangerHeaders({
          args: {
            url: 'bookings/cars/get_reservation_details',
            method: 'POST',
            body,
          },
          currentTraveler,
        }),
      providesTags: ['CarReservation'],
      extraOptions: {
        errorHandlerSkipOptions: {
          skipByStatusCodes: [403, 404],
        },
      },
    }),
    cancelCarReservation: builder.mutation<
      Definitions.CarsCancelReservationResponse,
      TArrangerQueryRequest<Definitions.CarsCancelReservationRequest>
    >({
      query: ({ currentTraveler, ...body }) =>
        extendWithArrangerHeaders({
          args: {
            url: 'bookings/cars/cancel_reservation',
            method: 'POST',
            body,
          },
          currentTraveler,
        }),
    }),
    carsDestinationLookup: builder.query<
      TCarsDestinationLookupResponse,
      TArrangerQueryRequest<TCarsDestinationLookupRequest>
    >({
      query: ({ currentTraveler, ...body }) =>
        extendWithArrangerHeaders({
          args: {
            url: 'bookings/destination_lookup',
            method: 'POST',
            body: {
              product: 'cars',
              ...body,
            },
          },
          currentTraveler,
        }),
    }),
    bookCar: builder.mutation<TCarBookResponse, TArrangerQueryRequest<Definitions.CarsBookRequest>>(
      {
        query: ({ currentTraveler, ...body }) =>
          extendWithArrangerHeaders({
            args: {
              url: 'bookings/cars/book',
              method: 'POST',
              body,
            },
            currentTraveler,
          }),
        extraOptions: {
          errorHandlerSkipOptions: {
            skipByStatusCodes: [FETCH_ERROR_CODE],
          },
        },
      },
    ),
  }),
});

export const {
  useGetAvailabilityQuery,
  useGetRateDetailsQuery,
  useLazyCarsDestinationLookupQuery,
  useGetCheckoutDataQuery,
  useGetReservationDetailsQuery,
  useLazyGetReservationDetailsQuery,
  useCancelCarReservationMutation,
  useBookCarMutation,
} = service;

export { service as carsService };

export type CarTypeDetails = {
  category: string;
  type: string;
  transmission: string;
  fuel: { code: string; description: string };
  air: boolean;
  passengers: number;
  checkedBaggage: number;
  cabinBaggage: number;
};

export type GetAvailabilityRequest = Definitions.CarsGetAvailabilityRequest;
export type GetAvailabilityResponse = Definitions.CarsGetAvailabilityResponse;

export type Rate = GetAvailabilityResponse['rates'][number] & {
  carTypeDetails: CarTypeDetails;
  preference: string[];
  mapAddress: string;
};

export type GetRateDetailsRequest = Definitions.CarsGetRateDetailsRequest;
export type GetRateDetailsResponse = Definitions.CarsGetRateDetailsResponse;

export type RateDetails = GetRateDetailsResponse['rate'];

export type TCarsDestinationLookupRequest = Definitions.DestinationLookupRequest;

export type TCarsDestinationLookupResponse = Definitions.DestinationLookupResponse;

export type CarsMatchDataEntry = TDestinationItem;

/**
 List of possible categories. Currently: `landmarks`, `cities`, `airports_and_stations`, `hotels`, `company_locations`. Always present. Not all categories may be present at all times. Each category will contain a limited list of matching items.
 */

export type CarPaymentOptions =
  | 'no_payment'
  | 'voucher'
  | 'evoucher_voucher'
  | 'vpa_payment'
  | 'card'
  | 'evoucher'
  | 'evoucher_card';

type GetCheckoutDataRequest = Definitions.CarsGetCheckoutDataRequest;

type GetReservationDetailsRequest = Definitions.CarsGetReservationDetailsRequest;

export type GetReservationDetailsResponse = Definitions.CarsGetReservationDetailsResponse;

type TCarBookResponse = Definitions.CarsBookResponse;
