import { extendWithArrangerHeaders } from '@/features/arranger';
import { spitfire } from '@/core/spitfire';

import type { TArrangerQueryRequest} from '@/features/arranger';
import type { WithId } from '@/types/common';
import type { Definitions } from '@/types/generated';
import type { TReleasedProduct } from '@/types/bookings';

const service = spitfire.injectEndpoints({
  endpoints: (builder) => ({
    updateLoyaltyPrograms: builder.mutation<
      undefined,
      TArrangerQueryRequest<TUpdateLoyaltyProgramsRequest>
    >({
      query: ({ currentTraveler, product, loyaltyPrograms }) => {
        const productField: keyof Definitions.UpdateMembershipsRequest = `${product}Memberships`;
        const body: Definitions.UpdateMembershipsRequest = {
          [productField]: loyaltyPrograms.map(({ id, ...rest }) => ({ ...rest })),
        };

        return extendWithArrangerHeaders({
          args: {
            url: 'memberships/update',
            method: 'POST',
            body,
          },
          currentTraveler,
        });
      },
      invalidatesTags: ['TravelerInfo'],
      extraOptions: {
        errorHandlerSkipOptions: {
          skipAll: true,
        },
      },
    }),
  }),
});

export const { useUpdateLoyaltyProgramsMutation } = service;

export type TLoyaltyProgram =
  | Definitions.AirMembership
  | Definitions.HotelMembership
  | Definitions.CarMembership;

export type TUpdatedLoyaltyProgram =
  | Definitions.BulkUpdateCarMembership
  | Definitions.BulkUpdateHotelMembership
  | Definitions.BulkUpdateAirMembership;

type TUpdateLoyaltyProgramsRequest = {
  product: TReleasedProduct;
  loyaltyPrograms: WithId<TUpdatedLoyaltyProgram>[];
};
