import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const RadioStyles: ComponentStyleConfig = {
  baseStyle: () => ({
    label: {
      _disabled: {
        color: 'text.link.disabled',
        opacity: '1',
      },
    },

    control: {
      boxSizing: 'content-box',
      background: 'white',
      borderColor: 'primary.blue1',
      border: '1px solid',

      _hover: {
        background: 'background.blue',
      },

      _checked: {
        background: 'white',
        borderColor: 'primary.blue1',

        _hover: {
          background: 'background.blue',
        },

        _before: {
          background: 'primary.blue1',
        },
      },

      _focusVisible: {
        borderColor: 'primary.blue3',

        _checked: {
          _before: {
            background: 'primary.blue3',
          },
        },
      },

      _disabled: {
        background: 'background.disabled !important',
        borderColor: 'text.link.disabled !important',

        _checked: {
          color: 'white',
          borderColor: 'text.link.disabled !important',

          _before: {
            backgroundColor: 'text.link.disabled',
          },
        },
      },

      _invalid: {
        background: 'white !important',
        borderColor: 'text.stateful.error !important',

        _before: {
          backgroundColor: 'text.stateful.error',
        },
      },
    },
  }),
};
