import { menuAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const buttonSelectStyle = {
  fontSize: 'sm',
  px: '3',
  h: '8',
};

export const MenuStyles = defineMultiStyleConfig({
  baseStyle: {
    button: {
      position: 'relative',
      textAlign: 'left',
      borderRadius: 'md',
      _focusVisible: {
        boxShadow: 'outline',
      },
    },
    list: {
      py: 0,
      overflow: 'hidden',
      minW: '140px',
      maxH: '350px',
      w: 'max-content',
      overflowY: 'auto',
      borderRadius: '4px',
      borderWidth: 0,
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.3)',
      zIndex: 'dropdown',
      transform: 'translateY(-5px) !important',
    },
    item: {
      fontSize: 'sm',
      fontWeight: 'semibold',
      borderRadius: 0,
      justifyContent: 'space-between',
      py: 2,

      '.chakra-menu__icon-wrapper': {
        mr: 0,
        ml: 2,
        w: 4,
        h: '23px',
      },

      _hover: {
        bgColor: 'background.lightGray',
      },
    },
  },
  variants: {
    dropdown: {
      button: {
        ...buttonSelectStyle,
        backgroundColor: 'white',
        outlineColor: 'border.formControl',
        outlineWidth: '1px',
        outlineOffset: '0',
        zIndex: 'dropdown',

        _focusVisible: {
          outlineColor: 'primary.blue1',
          outlineWidth: '2px',
        },

        _active: {
          outlineColor: 'primary.blue1',
          outlineWidth: '2px',
        },
      },
    },
    filled: {
      button: {
        ...buttonSelectStyle,
        backgroundColor: 'tertiary.paleGrey',
      },
    },
    tripActionMenu: {
      item: {
        pr: '44px',
        '.chakra-menu__icon-wrapper': {
          p: 0,
          mr: 2,
          boxSize: 6,
          fontSize: 'xl',
        },
      },
    },
  },
});
