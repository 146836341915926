import { createGenericContext } from '@/utils/createGenericContext';

import type { Definitions } from '@/types/generated';
import type { TravelerInformationResponse } from '@/features/travelerProfile/service';

export type TProfileContext = {
  generalTravelerInformation: TravelerInformationResponse;
  travelerInformation: TravelerInformationResponse;
  generalHyattSettings: Definitions.HyattSettingsResponse;
  hyattSettings: Definitions.HyattSettingsResponse;
  internalConstants: Required<Definitions.GetInternalConstantsResponse>;
  arrangerTravelers?: Definitions.GetTravelersResponse[];
  isFetching: boolean;
};

export const [useProfileContext, ProfileContextProvider] = createGenericContext<TProfileContext>();
