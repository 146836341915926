import React from 'react';
import { components } from 'react-select';

import type { GroupBase, ValueContainerProps} from 'react-select';

export function ValueContainer<Option, isMulti extends boolean, Group extends GroupBase<Option>>({
  children,
  ...props
}: ValueContainerProps<Option, isMulti, Group>) {
  return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
}
