import { createSlice } from '@reduxjs/toolkit';
import { isEqual } from 'lodash-es';

import { getSliceActionsList } from '@/core/utils';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@/core/store';
import type { UserCompany } from './service';
import type { OrNullRecord } from '@/types/common';
import type { Definitions } from '@/types/generated';

export enum LogoutReason {
  TokenExpired,
}

export type UserAuthState = OrNullRecord<{
  email?: string;
  companyId?: string;
}>;

type LogoutDetails = {
  reason: LogoutReason;
  authState: UserAuthState;
  traveler: Definitions.TravelerExtended | null;
};

type AuthState = OrNullRecord<{
  accessToken: string;
  loginToken: string;
  meta: {
    email: string;
  };
  companies: UserCompany[];
  selectedCompany: UserCompany;
  logoutDetails: LogoutDetails;
}>;

const initialState: AuthState = {
  loginToken: null,
  accessToken: null,
  meta: null,
  companies: null,
  selectedCompany: null,
  logoutDetails: null,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoginData(state, { payload }: PayloadAction<Definitions.LoginResponse>) {
      const { loginToken, companies, ...newMeta } = payload;

      state.loginToken = loginToken;
      state.meta = newMeta;
      state.companies = companies || null;
    },
    setAccessToken(state, { payload }: PayloadAction<{ accessToken: string }>) {
      state.accessToken = payload.accessToken;
    },
    setSelectedCompany(state, { payload }: PayloadAction<{ selectedCompany: UserCompany }>) {
      state.selectedCompany = payload.selectedCompany;
    },
    resetAuthState(state) {
      return { ...initialState, logoutDetails: state.logoutDetails };
    },
    setLogoutDetails(state, { payload }: PayloadAction<AuthState['logoutDetails']>) {
      state.logoutDetails = payload;
    },
    logoutThirdPartyServices() {
      window?.Genesys?.('command', 'Auth.logout');
      window?.gigya?.accounts.logout();
    },
  },
});

export const {
  setLoginData,
  setAccessToken,
  resetAuthState,
  setSelectedCompany,
  setLogoutDetails,
  logoutThirdPartyServices,
} = slice.actions;

export const authReducer = slice.reducer;

export const selectAuthSlice = (state: RootState) => state.auth;

export const selectAuthCompanySlice = (state: RootState) => state.auth.selectedCompany;

const selectCompanyId = (state: RootState) => selectAuthCompanySlice(state)?.companyId;

export const selectLogoutDetails = (state: RootState) => state.auth.logoutDetails;

export const selectEmail = (state: RootState) => state.auth.meta?.email;

export const selectUserAuthState = (state: RootState) => ({
  email: selectEmail(state),
  companyId: selectCompanyId(state),
});

export const selectIsLoginTheSameUser =
  (userAuthState?: UserAuthState | null) => (state: RootState) =>
    isEqual(selectUserAuthState(state), userAuthState);

export const authActionsSyncList = getSliceActionsList(slice, [
  'setLoginData',
  'setAccessToken',
  'setSelectedCompany',
  'resetAuthState',
  'logoutThirdPartyServices',
]);
