import { t } from 'i18next';

import type { UseToastOptions } from '@chakra-ui/react';
import type { TSpitfireErrorStatus } from '@/core/errors/types';
import type { AlertProps } from '@/components/Alert/Alert';

export const spitfireErrorStatuses = [
  'UNKNOWN_ERROR',
  'AIR_MEMBERSHIP_NOT_FOUND',
  'ARRANGERS_NOT_FOUND',
  'BAD_REQUEST',
  'CAR_MEMBERSHIP_NOT_FOUND',
  'COMPANY_NOT_FOUND',
  'CREDIT_CARD_NOT_FOUND',
  'FORBIDDEN',
  'HOTEL_MEMBERSHIP_NOT_FOUND',
  'NATIONAL_ID_CARD_NOT_FOUND',
  'PASSPORT_NOT_FOUND',
  'TRAVELERS_NOT_FOUND',
  'UNAUTHORIZED',
  'VALIDATION_ERROR',
  'VISA_NOT_FOUND',
  'ASB_ALL_HOTELS_EXCLUDED_BY_EXCLUDING_PROVIDERS',
  'ASB_ALL_RATES_EXCLUDED',
  'ASB_ALL_RATES_EXCLUDED_BY_MAX_NIGHTS_COUNT',
  'ASB_ALTERNATIVE_HOTELS_NOT_ALLOWED',
  'ASB_AUTH_ERROR',
  'ASB_BAD_GATEWAY',
  'ASB_BOOKING_ALREADY_CANCELLED',
  'ASB_BOOKING_ALREADY_EXISTS',
  'ASB_BOOKING_ALREADY_IN_PROCESS',
  'ASB_BOOKING_CANCELLING_ALREADY_IN_PROCESS',
  'ASB_BOOKING_NOT_FOUND',
  'ASB_CANCEL_PAST_BOOKING_ERROR',
  'ASB_CARD_TYPE_NOT_SUPPORTED',
  'ASB_CD_NUMBERS_CONFLICT',
  'ASB_CHECKIN_IN_PAST',
  'ASB_CHECKOUT_BEFORE_CHECKIN',
  'ASB_COMPANY_AIR_DISABLED',
  'ASB_COMPANY_CARS_DISABLED',
  'ASB_COMPANY_HOTELS_DISABLED',
  'ASB_COMPANY_NO_CONTENT_ENABLED',
  'ASB_COMPANY_NOT_FOUND',
  'ASB_CREDIT_CARD_WILL_BE_EXPIRED',
  'ASB_DATE_OF_BIRTH_MISSING',
  'ASB_DATE_OF_BIRTH_TOO_BIG',
  'ASB_DATE_OF_BIRTH_TOO_SMALL',
  'ASB_DECLINED_CREDIT_CARD',
  'ASB_DEPARTURE_DATE_TOO_BIG',
  'ASB_DEPARTURE_IN_PAST',
  'ASB_DROPOFF_BEFORE_PICKUP',
  'ASB_DROPOFF_DATETIME_TOO_BIG',
  'ASB_EXPIRED_CREDIT_CARD',
  'ASB_FAILED_BOOKING',
  'ASB_FAKE_BOOKING_NOT_SUPPORTED',
  'ASB_FAKE_BOOKING_CANNOT_BE_CANCELLED',
  'ASB_FARE_NO_LONGER_AVAILABLE',
  'ASB_FARE_REF_KEY_OUTDATED',
  'ASB_FARES_NOT_FOUND',
  'ASB_FF_NUMBER_MISSING',
  'ASB_FLIGHT_OPTIONS_DIFFERENT_FARE_GROUPS',
  'ASB_FLIGHT_OPTIONS_ORIGIN_DESTINATION_MISMATCH',
  'ASB_FORBIDDEN',
  'ASB_HOTEL_EXCLUDED',
  'ASB_HOTEL_ID_DOES_NOT_EXIST',
  'ASB_HOTELS_NOT_FOUND',
  'ASB_HOTEL_RESERVATION_INVALID_LOYALTY_CARD',
  'ASB_HTTP_NOT_FOUND',
  'ASB_ID_NUMBERS_CONFLICT_ERROR',
  'ASB_INCOMPATIBLE_RATE_KEY',
  'ASB_INCORRECT_CONFIGURATION_ID',
  'ASB_INCORRECT_DESTINATION',
  'ASB_INVALID_CARD_NUMBER',
  'ASB_INVALID_CC_STATE_PROVINCE_CODE',
  'ASB_INVALID_CC_VERIFICATION_RETURN_URL',
  'ASB_INVALID_CC_EXPIRATION_DATE_FOR_G_RATE',
  'ASB_INVALID_FF_INFORMATION',
  'ASB_SEATS_AUTOMATICALLY_ALLOCATED',
  'ASB_INVALID_FORM_OF_PAYMENT',
  'ASB_INVALID_CHOICE',
  'ASB_INVALID_FORMAT',
  'ASB_INVALID_CLIENT_REPORTABLE_DATA_FORMAT',
  'ASB_INVALID_COUNTRY_CODE',
  'ASB_INVALID_CURRENCY_CODE',
  'ASB_INVALID_EMAIL_FORMAT',
  'ASB_INVALID_HOTEL_IDS',
  'ASB_INVALID_ID_NUMBERS_FORMAT',
  'ASB_INVALID_PAYMENT_METHOD',
  'ASB_MALFORMED_RATE_KEY',
  'ASB_MIXING_SEARCH_ID_WITH_PARAMETERS',
  'ASB_OPTIONAL_SERVICES_UNAVAILABLE',
  'ASB_PAID_POLICY_CHANGED',
  'ASB_PASSPORT_GENDER_MISSING',
  'ASB_PASSPORT_WILL_BE_EXPIRED',
  'ASB_PATTERN_MISMATCH',
  'ASB_PICKUP_IN_PAST',
  'ASB_PNR_NOT_FOUND',
  'ASB_PNR_RETRIEVAL_ERROR',
  'ASB_PRICE_CHECK_FAILED',
  'ASB_PRICING_OPTIONS_NOT_ALLOWED',
  'ASB_RATE_KEY_EXPIRED',
  'ASB_RATE_KEY_INCOMPATIBLE',
  'ASB_RATE_KEY_INVALID_FORMAT',
  'ASB_RATE_KEY_INVALID_PARAMETERS',
  'ASB_RATE_NO_LONGER_AVAILABLE',
  'ASB_RATE_PRICE_CHANGED',
  'ASB_RATE_SPECIAL_EQUIPMENT_NOT_AVAILABLE',
  'ASB_RATES_NOT_FOUND',
  'ASB_REQUIRED_DATA_MISSING',
  'ASB_REQUIRED_FIELD_MISSING',
  'ASB_RESTRICTED_DESTINATION',
  'ASB_HOTEL_ID_RESTRICTED_DESTINATION',
  'ASB_SABRE_INVALID_VENDOR',
  'ASB_SEARCH_RESULT_EXPIRED',
  'ASB_SEATMAPS_NOT_FOUND',
  'ASB_SERVICE_DIFFERENT_FLIGHT_OPTIONS_GROUP',
  'ASB_SERVICE_DIFFERENT_SEARCH',
  'ASB_SERVICE_UNAVAILABLE',
  'ASB_SPECIAL_REQUEST_INVALID_FORMAT',
  'ASB_TERM_MISSING',
  'ASB_TRAVELER_DOCUMENT_CONFLICT',
  'ASB_TRAVELER_DOCUMENT_GENDER_MISSING',
  'ASB_TRAVELER_EMAIL_NOT_FOUND',
  'ASB_TRAVELFUSION_GENDER_MISSING',
  'ASB_TRIP_FORM_OF_PAYMENT_CONFLICT',
  'ASB_UNABLE_RETRIEVE_COMPANY_PROFILE',
  'ASB_UNABLE_RETRIEVE_TRAVELER_PROFILE',
  'ASB_UNACCEPTED_CREDIT_CARD_TYPE',
  'ASB_UNBOOKABLE_FLIGHT_OPTION',
  'ASB_UNCONFIGURED_LOYALTY_CODES',
  'ASB_UNEXPECTED_EXTRA_KEYS',
  'ASB_UNEXPECTED_PARAMETER',
  'ASB_VALUE_TOO_BIG',
  'ASB_VALUE_TOO_LONG',
  'ASB_VALUE_TOO_SHORT',
  'CONTENTFUL_BAD_GATEWAY',
  'CONTENTFUL_BAD_REQUEST',
  'CONTENTFUL_ENTRY_NOT_FOUND',
  'CONTENTFUL_FORBIDDEN',
  'CONTENTFUL_RESOURCE_NOT_FOUND',
  'CONTENTFUL_SERVICE_UNAVAILABLE',
  'CONTENTFUL_UNAUTHORIZED',
  'MESSAGES_ERROR',
  'MPLT_ERROR',
  'MPLT_UNAUTHORIZED',
  'GIGYA_BAD_REQUEST',
  'GIGYA_FORBIDDEN',
  'GIGYA_RESOURCE_NOT_FOUND',
  'GIGYA_SERVICE_UNAVAILABLE',
  'GIGYA_UNAUTHORIZED',
  'TSPM_DEACTIVATED_PROFILE',
  'TSPM_DISALLOWED_ENDPOINT',
  'TSPM_DISALLOWED_PROFILE',
  'TSPM_INVALID_PROFILE',
  'TSPM_UNEDITABLE_PROFILE',
  'AUTH_SERVICE_UNAVAILABLE',
  'AUTH_UNAUTHORIZED',
  'DATASTORE_SERVICE_UNAVAILABLE',
  'SETTINGS_COMPANY_DISALLOWED',
  'SETTINGS_ENDPOINT_DISALLOWED',
  'SETTINGS_FORBIDDEN',
  'SETTINGS_SERVICE_UNAVAILABLE',
  'SETTINGS_UNAUTHORIZED',
  'TD_API_ERROR',
  'TD_API_RECEIPT_NOT_FOUND',
  'TD_API_TRIP_NOT_FOUND',
  'TD_API_UNAUTHORIZED',
  'ARRANGER_TRAVELER_FORBIDDEN',
  'ASB_UNSUPPORTED_PROVIDER_METHOD',
  'ASB_LOYALTY_CARD_NUMBER_MISPLACED_CREDIT_CARD_NUMBER',
] as const;

export const getSomethingWrong = () =>
  t(
    'Something went wrong with your request. Please try again in a few minutes. If the error continues, contact our support team.',
  );

const noDataError = () => t('Invalid or missing data.');

export const getBookingNetworkError = () =>
  t(
    'Sorry, we could not complete your booking at this time. There is a chance that the transaction went through behind the scene. Please try again in few minutes, but only after making sure that the first reservation is not present in the system. Network error: Can’t connect to servers. Please verify your Internet connection.',
  );

export const errorMessages: Record<
  TSpitfireErrorStatus,
  (_details?: Record<string, any>) => string
> = {
  UNKNOWN_ERROR: getSomethingWrong,
  AIR_MEMBERSHIP_NOT_FOUND: noDataError,
  ARRANGERS_NOT_FOUND: getSomethingWrong,
  BAD_REQUEST: noDataError,
  CAR_MEMBERSHIP_NOT_FOUND: noDataError,
  COMPANY_NOT_FOUND: noDataError,
  CREDIT_CARD_NOT_FOUND: noDataError,
  FORBIDDEN: getSomethingWrong,
  HOTEL_MEMBERSHIP_NOT_FOUND: noDataError,
  NATIONAL_ID_CARD_NOT_FOUND: noDataError,
  PASSPORT_NOT_FOUND: noDataError,
  TRAVELERS_NOT_FOUND: getSomethingWrong,
  UNAUTHORIZED: getSomethingWrong,
  VALIDATION_ERROR: getSomethingWrong,
  VISA_NOT_FOUND: noDataError,
  ASB_ALL_HOTELS_EXCLUDED_BY_EXCLUDING_PROVIDERS: () =>
    t("No results were found based on your company's policy."),
  ASB_ALL_RATES_EXCLUDED: () => t("No results were found based on your company's policy."),
  ASB_ALL_RATES_EXCLUDED_BY_MAX_NIGHTS_COUNT: () => t('No results were found.'),
  ASB_ALTERNATIVE_HOTELS_NOT_ALLOWED: () => t('No results were found.'),
  ASB_AUTH_ERROR: getSomethingWrong,
  ASB_BAD_GATEWAY: getSomethingWrong,
  ASB_BOOKING_ALREADY_CANCELLED: () => t('Your booking has already been canceled.'),
  ASB_BOOKING_ALREADY_EXISTS: () => t('These details match an existing booking.'),
  ASB_BOOKING_ALREADY_IN_PROCESS: () => t('Your booking is already in progress.'),
  ASB_BOOKING_CANCELLING_ALREADY_IN_PROCESS: () => t('Cancellation is already in progress.'),
  ASB_BOOKING_NOT_FOUND: () => t('Booking was not found.'),
  ASB_CANCEL_PAST_BOOKING_ERROR: () => t('This trip has already occurred.'),
  ASB_CARD_TYPE_NOT_SUPPORTED: () => t('Credit card type is not supported.'),
  ASB_CD_NUMBERS_CONFLICT: () => t('Only one number per vendor is allowed.'),
  ASB_CHECKIN_IN_PAST: noDataError,
  ASB_CHECKOUT_BEFORE_CHECKIN: noDataError,
  ASB_COMPANY_AIR_DISABLED: () => t("No results were found based on your company's policy."),
  ASB_COMPANY_CARS_DISABLED: () => t("No results were found based on your company's policy."),
  ASB_COMPANY_HOTELS_DISABLED: () => t("No results were found based on your company's policy."),
  ASB_COMPANY_NO_CONTENT_ENABLED: () => t("No results were found based on your company's policy."),
  ASB_COMPANY_NOT_FOUND: () => t('No results were found.'),
  ASB_CREDIT_CARD_WILL_BE_EXPIRED: () => t('Credit cards cannot expire within one month.'),
  ASB_DATE_OF_BIRTH_MISSING: noDataError,
  ASB_DATE_OF_BIRTH_TOO_BIG: noDataError,
  ASB_DATE_OF_BIRTH_TOO_SMALL: noDataError,
  ASB_DECLINED_CREDIT_CARD: () => t('Your credit card was declined by the supplier.'),
  ASB_DEPARTURE_DATE_TOO_BIG: () => t('The date cannot be more than 330 days in advance.'),
  ASB_DEPARTURE_IN_PAST: noDataError,
  ASB_DROPOFF_BEFORE_PICKUP: noDataError,
  ASB_DROPOFF_DATETIME_TOO_BIG: () => t('The date cannot be more than 330 days in advance.'),
  ASB_EXPIRED_CREDIT_CARD: noDataError,
  ASB_FAILED_BOOKING: getSomethingWrong,
  ASB_FAKE_BOOKING_NOT_SUPPORTED: () => t('Error for testing purposes'),
  ASB_FAKE_BOOKING_CANNOT_BE_CANCELLED: () => t('Error for testing purposes'),
  ASB_FARE_NO_LONGER_AVAILABLE: () =>
    t('The selected fare is no longer available. Please repeat your search.'),
  ASB_FARE_REF_KEY_OUTDATED: () => t('Seat maps were not found.'),
  ASB_FARES_NOT_FOUND: () =>
    t('No results were found. Please repeat your search with different dates or destinations.'),
  ASB_FF_NUMBER_MISSING: noDataError,
  ASB_FLIGHT_OPTIONS_DIFFERENT_FARE_GROUPS: getSomethingWrong,
  ASB_FLIGHT_OPTIONS_ORIGIN_DESTINATION_MISMATCH: getSomethingWrong,
  ASB_FORBIDDEN: () => t("You don't have access to this page."),
  ASB_HOTEL_EXCLUDED: () => t("No results were found based on your company's policy."),
  ASB_HOTEL_ID_DOES_NOT_EXIST: noDataError,
  ASB_HOTELS_NOT_FOUND: () => t('No results were found.'),
  ASB_HOTEL_RESERVATION_INVALID_LOYALTY_CARD: () =>
    t('The membership number entered cannot be verified. Please enter a valid number.'),
  ASB_HTTP_NOT_FOUND: getSomethingWrong,
  ASB_ID_NUMBERS_CONFLICT_ERROR: () => t('Only one number per vendor is allowed.'),
  ASB_INCORRECT_CONFIGURATION_ID: noDataError,
  ASB_INCOMPATIBLE_RATE_KEY: () => t('The request is invalid.'),
  ASB_INCORRECT_DESTINATION: noDataError,
  ASB_INVALID_CARD_NUMBER: noDataError,
  ASB_INVALID_CC_STATE_PROVINCE_CODE: noDataError,
  ASB_INVALID_CC_VERIFICATION_RETURN_URL: getSomethingWrong,
  ASB_INVALID_CC_EXPIRATION_DATE_FOR_G_RATE: () =>
    t('Credit cards cannot expire within one month.'),
  ASB_INVALID_FF_INFORMATION: () => t('Your frequent flyer number was declined by the supplier.'),
  ASB_SEATS_AUTOMATICALLY_ALLOCATED: () =>
    t(
      'Seats are allocated automatically by this airline. Make a booking again without seat assignment.',
    ),
  ASB_INVALID_FORM_OF_PAYMENT: () => t('Your credit card was declined by the supplier.'),
  ASB_INVALID_CHOICE: () => t('The request is invalid.'),
  ASB_INVALID_FORMAT: getSomethingWrong,
  ASB_INVALID_CLIENT_REPORTABLE_DATA_FORMAT: noDataError,
  ASB_INVALID_COUNTRY_CODE: (details) =>
    details?.field === 'phone_number'
      ? t('Invalid phone number. Please update your information in the Billing Section.')
      : noDataError(),
  ASB_INVALID_CURRENCY_CODE: noDataError,
  ASB_INVALID_EMAIL_FORMAT: noDataError,
  ASB_INVALID_HOTEL_IDS: noDataError,
  ASB_INVALID_ID_NUMBERS_FORMAT: noDataError,
  ASB_INVALID_PAYMENT_METHOD: noDataError,
  ASB_MALFORMED_RATE_KEY: noDataError,
  ASB_MIXING_SEARCH_ID_WITH_PARAMETERS: () => t('The request is invalid.'),
  ASB_OPTIONAL_SERVICES_UNAVAILABLE: () => t('The request is invalid.'),
  ASB_PAID_POLICY_CHANGED: getSomethingWrong,
  ASB_PASSPORT_GENDER_MISSING: noDataError,
  ASB_PASSPORT_WILL_BE_EXPIRED: () => t('Passport will expire before the flight ends.'),
  ASB_PATTERN_MISMATCH: noDataError,
  ASB_PICKUP_IN_PAST: noDataError,
  ASB_PNR_NOT_FOUND: getSomethingWrong,
  ASB_PNR_RETRIEVAL_ERROR: getSomethingWrong,
  ASB_PRICE_CHECK_FAILED: () => t('custom handling'),
  ASB_PRICING_OPTIONS_NOT_ALLOWED: () => t('The request is invalid.'),
  ASB_RATE_KEY_EXPIRED: getSomethingWrong,
  ASB_RATE_KEY_INCOMPATIBLE: noDataError,
  ASB_RATE_KEY_INVALID_FORMAT: noDataError,
  ASB_RATE_KEY_INVALID_PARAMETERS: noDataError,
  ASB_RATE_NO_LONGER_AVAILABLE: () =>
    t('No results were found. Please repeat your search with different dates or destinations.'),
  ASB_RATE_PRICE_CHANGED: () => t('There has been a price change.'),
  ASB_RATE_SPECIAL_EQUIPMENT_NOT_AVAILABLE: () =>
    t('The selected special equipment is not included in this rate.'),
  ASB_RATES_NOT_FOUND: () => t('No results were found.'),
  ASB_REQUIRED_DATA_MISSING: (details) =>
    details?.field === 'loyalty_card_number'
      ? t(
          'To access this rate, you must be a member of the hotel loyalty program. Please provide your membership account number to proceed.',
        )
      : noDataError(),
  ASB_REQUIRED_FIELD_MISSING: noDataError,
  ASB_RESTRICTED_DESTINATION: () => t('custom handling'),
  ASB_HOTEL_ID_RESTRICTED_DESTINATION: () => t('custom handling'),
  ASB_SABRE_INVALID_VENDOR: noDataError,
  ASB_SEARCH_RESULT_EXPIRED: () => t('The search results have expired. Please repeat your search.'),
  ASB_SEATMAPS_NOT_FOUND: () => t('Seat maps were not found.'),
  ASB_SERVICE_DIFFERENT_FLIGHT_OPTIONS_GROUP: getSomethingWrong,
  ASB_SERVICE_DIFFERENT_SEARCH: getSomethingWrong,
  ASB_SERVICE_UNAVAILABLE: getSomethingWrong,
  ASB_SPECIAL_REQUEST_INVALID_FORMAT: getSomethingWrong,
  ASB_TERM_MISSING: getSomethingWrong,
  ASB_TRAVELER_DOCUMENT_CONFLICT: () =>
    t('Only one document is allowed. Please provide either an identity card or passport data.'),
  ASB_TRAVELER_DOCUMENT_GENDER_MISSING: noDataError,
  ASB_TRAVELER_EMAIL_NOT_FOUND: () => t('There is no profile associated with your email address.'),
  ASB_TRAVELFUSION_GENDER_MISSING: noDataError,
  ASB_TRIP_FORM_OF_PAYMENT_CONFLICT: noDataError,
  ASB_UNABLE_RETRIEVE_COMPANY_PROFILE: getSomethingWrong,
  ASB_UNABLE_RETRIEVE_TRAVELER_PROFILE: getSomethingWrong,
  ASB_UNACCEPTED_CREDIT_CARD_TYPE: noDataError,
  ASB_UNBOOKABLE_FLIGHT_OPTION: () => t("No results were found based on your company's policy."),
  ASB_UNCONFIGURED_LOYALTY_CODES: noDataError,
  ASB_UNEXPECTED_EXTRA_KEYS: noDataError,
  ASB_UNEXPECTED_PARAMETER: noDataError,
  ASB_VALUE_TOO_BIG: noDataError,
  ASB_VALUE_TOO_LONG: noDataError,
  ASB_VALUE_TOO_SHORT: noDataError,
  CONTENTFUL_BAD_GATEWAY: getSomethingWrong,
  CONTENTFUL_BAD_REQUEST: getSomethingWrong,
  CONTENTFUL_ENTRY_NOT_FOUND: noDataError,
  CONTENTFUL_FORBIDDEN: getSomethingWrong,
  CONTENTFUL_RESOURCE_NOT_FOUND: noDataError,
  CONTENTFUL_SERVICE_UNAVAILABLE: getSomethingWrong,
  CONTENTFUL_UNAUTHORIZED: getSomethingWrong,
  MESSAGES_ERROR: getSomethingWrong,
  MPLT_ERROR: getSomethingWrong,
  MPLT_UNAUTHORIZED: getSomethingWrong,
  GIGYA_BAD_REQUEST: getSomethingWrong,
  GIGYA_FORBIDDEN: () => t('The username and/or password entered is incorrect.'),
  GIGYA_RESOURCE_NOT_FOUND: getSomethingWrong,
  GIGYA_SERVICE_UNAVAILABLE: getSomethingWrong,
  GIGYA_UNAUTHORIZED: () => t("You don't have access to this page."),
  TSPM_DEACTIVATED_PROFILE: () =>
    t('Your TripSource profile is not active. Please contact our support team.'),
  TSPM_DISALLOWED_ENDPOINT: getSomethingWrong,
  TSPM_DISALLOWED_PROFILE: getSomethingWrong,
  TSPM_INVALID_PROFILE: () => t('The request is invalid.'),
  TSPM_UNEDITABLE_PROFILE: getSomethingWrong,
  AUTH_SERVICE_UNAVAILABLE: getSomethingWrong,
  AUTH_UNAUTHORIZED: getSomethingWrong,
  DATASTORE_SERVICE_UNAVAILABLE: getSomethingWrong,
  SETTINGS_COMPANY_DISALLOWED: () => t("You don't have access to this page."),
  SETTINGS_ENDPOINT_DISALLOWED: () => t("You don't have access to this page."),
  SETTINGS_FORBIDDEN: () => t("You don't have access to this page."),
  SETTINGS_SERVICE_UNAVAILABLE: getSomethingWrong,
  SETTINGS_UNAUTHORIZED: getSomethingWrong,
  TD_API_ERROR: getSomethingWrong,
  TD_API_RECEIPT_NOT_FOUND: getSomethingWrong,
  TD_API_TRIP_NOT_FOUND: getSomethingWrong,
  TD_API_UNAUTHORIZED: getSomethingWrong,
  ARRANGER_TRAVELER_FORBIDDEN: getSomethingWrong,
  ASB_UNSUPPORTED_PROVIDER_METHOD: getSomethingWrong,
  ASB_LOYALTY_CARD_NUMBER_MISPLACED_CREDIT_CARD_NUMBER: getSomethingWrong,
};

export const customErrorToastOptions: Partial<Record<TSpitfireErrorStatus, UseToastOptions>> = {};
export const customErrorAlertProps: Partial<Record<TSpitfireErrorStatus, AlertProps>> = {};

export const ERROR_TOAST_COMMON_OPTIONS = {
  position: 'top',
  isClosable: true,
  duration: null,
} as const;

export const FETCH_ERROR_CODE = 'FETCH_ERROR';
