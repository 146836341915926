import { initialRecentDestinationState } from '@/features/recentDestinations/slice';

export const PERSIST_VERSION = 2;

export const persistMigrations = {
  0: (state: any) =>
    // migration recentDestinations from persistConfig version 0
    ({
      ...state,
      recentDestinations: {
        myself: state?.recentDestinations || initialRecentDestinationState,
        arranger: initialRecentDestinationState,
      },
    }),
  // add rail initial values to recentDestinations
  2: (state: any) => migrateRecentSearchesWithRail(state),
};

function migrateRecentSearchesWithRail(state: any) {
  return {
    ...state,
    recentDestinations: {
      myself: {
        ...state.recentDestinations.myself,
        rail: state.recentDestinations.myself?.rail || initialRecentDestinationState.rail,
      },
      arranger: {
        ...state.recentDestinations.arranger,
        rail: state.recentDestinations.arranger?.rail || initialRecentDestinationState.rail,
      },
    },
  };
}
