import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash-es/debounce';

import type React from 'react';

type UseDatepickerPositionOptions = {
  ref: React.RefObject<HTMLDivElement>;
  dimensions: [width: number, height: number];
};

type PositionX = 'left' | 'right';
type PositionY = 'top' | 'bottom';

export function useDatepickerPosition({
  ref: dpRef,
  dimensions: [width, height],
}: UseDatepickerPositionOptions): [PositionX, PositionY, DOMRect | undefined, number] {
  const [positionX, setPositionX] = useState<PositionX>('right');
  const [positionY, setPositionY] = useState<PositionY>('bottom');

  const getPositionY = useCallback(() => {
    if (dpRef && dpRef.current) {
      const offsetBottom = window.innerHeight - dpRef.current.getBoundingClientRect().bottom;
      const y = offsetBottom < height ? 'top' : 'bottom';
      setPositionY(y);
    }
  }, [dpRef, height]);

  const getPositionX = useCallback(() => {
    if (dpRef && dpRef.current) {
      const { left } = dpRef.current.getBoundingClientRect();
      const offsetRight = window.innerWidth - left;

      // If the datepicker fits on the right side of the input field, it should be positioned to the
      // right. If it doesn't fit, then position it to the left.
      const x = offsetRight < width ? 'right' : 'left';
      setPositionX(x);
    }
  }, [dpRef, width]);

  useEffect(() => {
    getPositionY();
    getPositionX();

    const getPositionXDebounced = debounce(getPositionX, 100);
    const getPositionYDebounced = debounce(getPositionY, 100);

    window.addEventListener('scroll', getPositionYDebounced, true);
    window.addEventListener('resize', getPositionXDebounced, true);

    return () => {
      window.removeEventListener('scroll', getPositionYDebounced, true);
      window.removeEventListener('resize', getPositionXDebounced, true);
    };
  }, [getPositionX, getPositionY]);

  const current = dpRef.current?.getBoundingClientRect();
  const topPosition = (current?.top || 0) + window.scrollY + (current?.height || 0);

  return [positionX, positionY, current, topPosition];
}
