export const ReleasedProducts = {
  Air: 'air',
  Hotel: 'hotels',
  Car: 'cars',
} as const;

export const Products = {
  ...ReleasedProducts,
  Rail: 'rail',
} as const;

export const GOOGLE_MAP_SCRIPT_ID = 'google-map-script';
