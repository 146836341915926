import React, { memo } from 'react';
import { Card, CardBody } from '@chakra-ui/react';

import { UnusedTicketsHeader } from './components/UnusedTicketsHeader';
import { UnusedTicketsContent } from './components/UnusedTicketsContent';
import { airTestIds } from '../testids';

import type { OrNull } from '@/types/common';
import type { Definitions } from '@/types/generated';

type Props = {
  unusedTickets: OrNull<Definitions.UnusedTicket[][]>;
};

export const UnusedTickets = memo(({ unusedTickets }: Props) => {
  if (!unusedTickets?.length) {
    return null;
  }

  return (
    <Card mt={4} mb={8} data-testid={airTestIds.result.unusedTickets}>
      <CardBody>
        <UnusedTicketsHeader />

        <UnusedTicketsContent unusedTickets={unusedTickets} />
      </CardBody>
    </Card>
  );
});
