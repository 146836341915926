import { colors } from '@/theme/colors';
import { fontSizes } from '@/theme/fonts';

import type { StylesConfig } from 'react-select';
import type { Theme } from 'react-select/dist/declarations/src/types';

export const selectStyles: StylesConfig<any, any, any> = {
  control: (baseStyles, props) => ({
    ...baseStyles,
    minHeight: 33,
    borderColor: props.selectProps.isInvalid ? colors.border.error : baseStyles.borderColor,
  }),
  input: (baseStyles) => ({
    ...baseStyles,
    margin: 0,
    cursor: 'text',
    fontSize: 14,
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    fontSize: 14,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    fontSize: 14,
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.3)',
    transform: 'translateY(-5px) !important',
    width: 'max-content',
    minWidth: '160px',
    maxHeight: '350px',
  }),
};

export const simpleSelectStyles: StylesConfig<any, any, any> = {
  control: (baseStyles, controlProps) => ({
    ...(selectStyles.control?.(baseStyles, controlProps) ?? {}),
    cursor: 'pointer',
    border: 0,
    background: 'transparent',
    ':focus-within': {
      outline: '2px solid transparent',
      outlineOffset: '2px',
      boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.6)',
    },
  }),
  singleValue: () => ({
    fontSize: fontSizes.sm,
    textTransform: 'uppercase',
    fontWeight: '600',
  }),
  valueContainer: () => ({
    display: 'flex',
    alignItems: 'center',
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
  menu: (baseStyles, menuProps) => ({
    ...(selectStyles.menu?.(baseStyles, menuProps) ?? {}),
    fontSize: fontSizes.sm,
    fontWeight: '600',
    right: '0',
  }),
};

export const autoHeightSimpleSelectStyles: StylesConfig<any, any, any> = {
  ...simpleSelectStyles,
  menuList: (baseStyles) => ({
    ...baseStyles,
    maxHeight: 'auto',
  }),
};

export const ghostButtonSelectStyles: StylesConfig<any, any, any> = {
  control: (baseStyles, controlProps) => ({
    ...(selectStyles.control?.(baseStyles, controlProps) ?? {}),
    flexWrap: 'nowrap',
    cursor: 'pointer',
    height: '32px',
    padding: '8px 0 8px 12px',
    border: 0,
    background: 'transparent',
    borderRadius: '16px',
    ':hover': {
      color: colors.primary.blue1,
      backgroundColor: colors.background.blue,
    },
    ':focus-within': {
      outline: '2px solid transparent',
      outlineOffset: '2px',
      boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.6)',
    },
  }),
  singleValue: () => ({
    fontSize: fontSizes.sm,
    fontWeight: '600',
    color: colors.primary.blue1,
    whiteSpace: 'nowrap',
  }),
  indicatorsContainer: () => ({
    svg: {
      color: colors.primary.blue1,
      height: '0.5em',
      width: '0.5em',
    },
  }),
  valueContainer: () => ({
    display: 'flex',
    alignItems: 'center',
    color: colors.primary.blue1,
  }),
  menu: (baseStyles, menuProps) => ({
    ...(selectStyles.menu?.(baseStyles, menuProps) ?? {}),
    fontSize: fontSizes.sm,
    fontWeight: '600',
  }),
};

export const unstyledButtonSelectStyles: StylesConfig<any, any, any> = {
  control: (baseStyles, controlProps) => ({
    ...(selectStyles.control?.(baseStyles, controlProps) ?? {}),
    cursor: 'pointer',
    border: 0,
    background: 'transparent',
    ':focus-within': {
      outline: '2px solid transparent',
      outlineOffset: '2px',
      boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.6)',
    },
  }),
  singleValue: () => ({
    fontSize: fontSizes.sm,
    fontWeight: '600',
  }),
  indicatorsContainer: () => ({
    svg: {
      color: colors.text.base.default,
      height: '0.5em',
      width: '0.5em',
    },
  }),
  valueContainer: () => ({
    display: 'flex',
    alignItems: 'center',
  }),
  menu: (baseStyles, menuProps) => ({
    ...(selectStyles.menu?.(baseStyles, menuProps) ?? {}),
    fontSize: fontSizes.sm,
    fontWeight: '600',
  }),
};

export const selectTheme = (theme: Theme) => ({
  ...theme,
  borderRadius: 6,
  colors: {
    ...theme.colors,
    primary: colors.primary.blue1,
    neutral20: colors.border.formControl,
    neutral30: colors.border.formControl,
    neutral50: colors.text.base.placeholder,
  },
});

export const selectOptionIconSize = {
  xs: 4,
  sm: 6,
  md: 8,
  lg: 10,
};
