import { datetimeUtil } from '@/utils/time';

export const UTC_DIFFERENCE = 12;

const utcDate = datetimeUtil().utc();

export const MAX_DATE = new Date(
  utcDate.year(),
  utcDate.month(),
  utcDate.date() + 330,
  utcDate.hour(),
  utcDate.minute(),
);

export const MAX_FLIGHTS_DATE =
  new Date().getUTCHours() >= UTC_DIFFERENCE
    ? MAX_DATE
    : datetimeUtil().utc().add(329, 'd').toDate();
