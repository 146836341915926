import React from 'react';
import { Center, CircularProgress } from '@chakra-ui/react';

export const defaultSpinnerTestId = 'default-spinner';

type SpinnerProps = {
  height?: number | string;
  isFullHeight?: boolean;
  dataTestId?: string;
};

export function Spinner({
  height = 300,
  isFullHeight,
  dataTestId = defaultSpinnerTestId,
}: SpinnerProps) {
  const fullHeightStyles = {
    position: 'fixed',
    zIndex: 'modal',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  return (
    <Center
      height={height}
      data-testid={dataTestId}
      sx={isFullHeight ? fullHeightStyles : undefined}
    >
      <CircularProgress isIndeterminate size={20} thickness={1} color="primary.blue1" />
    </Center>
  );
}
