import type { AirSearchFormValues } from '@/features/air';
import type { Products } from '@/utils/constants/common';
import type { UnifiedFormCars, UnifiedFormHotels } from './UnifiedSearch/types';

export type UnifiedBooking = {
  products: Product[];
  pnrId?: string;
};
export type AirProduct = {
  id: string;
  index: number;
  bookingUid: string | null;
  product: typeof Products.Air;
  form: AirSearchFormValues;
};
export type CarProduct = {
  id: string;
  index: number;
  bookingUid: string | null;
  product: typeof Products.Car;
  form: UnifiedFormCars;
};
export type HotelProduct = {
  id: string;
  index: number;
  bookingUid: string | null;
  product: typeof Products.Hotel;
  form: UnifiedFormHotels;
};
export type Product = AirProduct | CarProduct | HotelProduct;
export type ProductForm = Product['form'];

export enum GetCurrentProductError {
  NotFound,
  AllCompleted,
}

export type GetCurrentProductResult =
  | {
      data: null;
      error: GetCurrentProductError;
    }
  | {
      error: null;
      data: Product;
    };
