import { theme } from '@chakra-ui/react';

export const sizes = {
  ...theme.sizes,
  container: {
    sm: '728px',
    md: '916px',
    lg: '964px',
    xl: '1104px',
  },
};
