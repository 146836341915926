import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { spitfire } from './spitfire';
import { appReducer } from '@/app/slice';
import { apiHeadersReducer } from '@/core/slices/apiHeaders/slice';
import { authReducer, authActionsSyncList } from '@/features/auth/slice';
import { userSettingsReducer, userSettingsActionsSyncList } from '@/features/userSettings/slice';
import { carsReducer } from '@/features/cars/slice';
import { railReducer } from '@/features/rail/slice';
import { unifiedBookingReducer } from '@/features/unifiedBooking/slice';
import { commandExecutorReducer } from '@/features/unifiedBooking/CommandExecutor/slice';
import { arrangerReducer, arrangerActionsSyncList } from '@/features/arranger/slice';
import { SSOReducer, SSOActionsSyncList } from '@/features/SSO/slice';
import { agentChatReducer, agentChatActionsSyncList } from '@/features/agentChat/slice';
import {
  recentDestinationsReducer,
  recentDestinationsActionsSyncList,
} from '@/features/recentDestinations/slice';
import { verificationReducer } from '@/features/air';
import { tripsReducer } from '@/features/trips';
import { PERSIST_VERSION, persistMigrations } from '@/core/persistMigrations';
import { IS_DEV } from '@/core/constants';
import { crdReducer } from '@/components/Bookings/Checkout/CRD';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: [
    'app',
    'auth',
    'arranger',
    'userSettings',
    'cars',
    'unifiedBooking',
    'verification',
    'trips',
    'crd',
    'recentDestinations',
    'SSO',
    'agentChat',
    'rail',
  ],
  version: PERSIST_VERSION,
  migrate: createMigrate(persistMigrations, { debug: IS_DEV }),
};

const arrangerPersistConfig = { key: 'arranger', storage: sessionStorage };

export const rootReducer = combineReducers({
  app: appReducer,
  apiHeaders: apiHeadersReducer,
  arranger: persistReducer(arrangerPersistConfig, arrangerReducer),
  auth: authReducer,
  userSettings: userSettingsReducer,
  cars: carsReducer,
  rail: railReducer,
  unifiedBooking: unifiedBookingReducer,
  commandExecutor: commandExecutorReducer,
  verification: verificationReducer,
  trips: tripsReducer,
  crd: crdReducer,
  recentDestinations: recentDestinationsReducer,
  SSO: SSOReducer,
  agentChat: agentChatReducer,
  [spitfire.reducerPath]: spitfire.reducer,
});

export const store = configureStore({
  reducer: persistReducer<ReturnType<typeof rootReducer>>(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      spitfire.middleware,
      createStateSyncMiddleware({
        whitelist: [
          ...userSettingsActionsSyncList,
          ...authActionsSyncList,
          ...arrangerActionsSyncList,
          ...recentDestinationsActionsSyncList,
          ...SSOActionsSyncList,
          ...agentChatActionsSyncList,
          'spitfire/resetApiState',
        ],
      }),
    ]),
});

export const persistor = persistStore(store);

initMessageListener(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
