import { Box } from '@chakra-ui/react';
import * as React from 'react';
import { usePopover, DismissButton, Overlay } from '@react-aria/overlays';

import type { OverlayTriggerState } from '@react-stately/overlays';
import type { AriaPopoverProps } from '@react-aria/overlays';

interface AutocompletePopoverProps extends Omit<AriaPopoverProps, 'popoverRef'> {
  children: React.ReactNode;
  state: OverlayTriggerState;
  popoverRef?: React.RefObject<HTMLDivElement>;
}

export function AutocompletePopover(props: AutocompletePopoverProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  const { popoverRef = ref, state, children, isNonModal } = props;

  const { popoverProps, underlayProps } = usePopover(
    {
      ...props,
      popoverRef,
    },
    state,
  );

  return (
    <Overlay>
      {!isNonModal && <Box {...underlayProps} position="fixed" />}
      <Box
        {...popoverProps}
        ref={popoverRef}
        background="white"
        border="1px solid lightgray"
        borderRadius="md"
        zIndex="dropdown"
        width={props.triggerRef.current?.clientWidth ?? '100%'}
        left={0}
        right={0}
        boxShadow="lg"
        mt="2px"
      >
        {!isNonModal && <DismissButton onDismiss={state.close} />}
        {children}
        <DismissButton onDismiss={state.close} />
      </Box>
    </Overlay>
  );
}
