import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Divider,
  VStack,
} from '@chakra-ui/react';

import { Ticket } from './Ticket';
import { airTestIds } from '@/features/air/testids';

import type { Definitions } from '@/types/generated';

type Props = {
  unusedTickets: Definitions.UnusedTicket[][];
};

export function UnusedTicketsContent({ unusedTickets }: Props) {
  return (
    <VStack
      spacing={4}
      divider={<Divider />}
      as={Accordion}
      align="stretch"
      mb={0}
      fontSize="sm"
      allowMultiple
    >
      {unusedTickets.map((group) => {
        const firstTicket = group[0];

        if (group.length === 1) {
          return <Ticket key={firstTicket.ticketNumber} ticket={firstTicket} groupLength={1} />;
        }

        return (
          <AccordionItem key={firstTicket.ticketNumber}>
            <AccordionButton py={0}>
              <Ticket ticket={firstTicket} groupLength={group.length} />
            </AccordionButton>

            <AccordionPanel pb={4} data-testid={airTestIds.result.unusedTicketsGroup}>
              {group.slice(1).map((ticket) => (
                <Ticket
                  key={ticket.ticketNumber + ticket.expiryDatetime}
                  ticket={ticket}
                  groupLength={group.length}
                  isSubTicket
                />
              ))}
            </AccordionPanel>
          </AccordionItem>
        );
      })}
    </VStack>
  );
}
