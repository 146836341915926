// Temp fix, should be obtained from env var
export const APP_TITLE = import.meta.env.THUNDERBOLT_APP_TITLE as string;
export const API_URL = import.meta.env.THUNDERBOLT_API_URL as string;
export const DEFAULT_USER_EMAIL = 'yourbcdtraveler@gmail.com';
export const GOOGLE_MAPS_API_KEY = import.meta.env.THUNDERBOLT_GOOGLE_MAPS_API_KEY as string;
export const TSPM_SSO_NAME = import.meta.env.THUNDERBOLT_TSPM_SSO_NAME as string;
export const GIGYA_API_KEY_GLOBAL = import.meta.env.THUNDERBOLT_GIGYA_API_KEY_GLOBAL as string;
export const GIGYA_API_KEY_US = import.meta.env.THUNDERBOLT_GIGYA_API_KEY_US as string;
export const APP_STORE_URL = 'https://apps.apple.com/us/app/tripsource/id710472405';
export const GOOGLE_PLAY_URL =
  'https://play.google.com/store/apps/details?id=com.mttnow.android.bcd.production';
export const TRAIN_LOGO_URL = import.meta.env.THUNDERBOLT_TRAIN_LOGO_URL as string;
export const AGENT_CHAT_CLIENT_ID = import.meta.env.THUNDERBOLT_AGENT_CHAT_CLIENT_ID as string;
export const AGENT_CHAT_REDIRECT_URI = import.meta.env
  .THUNDERBOLT_AGENT_CHAT_REDIRECT_URI as string;
export const NOTIFY_LOGIN_COOKIE_DOMAIN = import.meta.env
  .THUNDERBOLT_NOTIFY_LOGIN_COOKIE_DOMAIN as string;
export const AGENT_CHAT_AUTH_URL = import.meta.env.THUNDERBOLT_AGENT_CHAT_AUTH_URL as string;

export const ENVS = {
  mocked: 'mocked',
  development: 'development',
  staging: 'staging',
  uat: 'uat',
  pre_prod: 'pre-prod',
  production: 'production',
} as const;

export const IS_DEV =
  import.meta.env.DEV ||
  import.meta.env.MODE === ENVS.mocked ||
  import.meta.env.MODE === ENVS.development ||
  import.meta.env.MODE === ENVS.staging;
export const IS_MOCKED = import.meta.env.MODE === ENVS.mocked;
export const IS_UAT = import.meta.env.MODE === ENVS.uat;
export const IS_PROD = import.meta.env.MODE === ENVS.production;

export const TEST_LOCALE = 'en-TT';
export const SUPPORTED_LOCALES = [
  'en-US',
  'fr-FR',
  'de-DE',
  'pt-BR',
  'es-ES',
  'nn-NO',
  'sv-SE',
  'ja-JP',
  'fr-CA',
] as const;
