import { Products } from '@/utils/constants/common';
import { FORM_FIELDS as SINGLE_FORM_HOTELS_FORM_FIELDS } from '@/features/hotels/search/constants';
import { FORM_FIELDS as SINGLE_FORM_CARS_FORM_FIELDS } from '@/features/cars/components/search/constants';

export const UNIFIED_BOOKING_PRODUCTS = [Products.Air, Products.Hotel, Products.Car] as const;

export const CARS_FORM_FIELDS = {
  ...SINGLE_FORM_CARS_FORM_FIELDS,
  MATCH_DATES: 'matchDates',
} as const;

export const HOTELS_FORM_FIELDS = {
  ...SINGLE_FORM_HOTELS_FORM_FIELDS,
  MATCH_DATES: 'matchDates',
} as const;
