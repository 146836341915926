import { Box, TagCloseButton } from '@chakra-ui/react';
import omit from 'lodash-es/omit';
import React from 'react';

import type { GroupBase, MultiValueRemoveProps } from 'react-select';

export function MultiValueRemove<Option, isMulti extends boolean, Group extends GroupBase<Option>>({
  innerProps,
}: MultiValueRemoveProps<Option, isMulti, Group>) {
  return (
    <Box {...omit(innerProps, 'css')}>
      <TagCloseButton />
    </Box>
  );
}
