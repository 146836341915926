import { createSlice } from '@reduxjs/toolkit';
import { customAlphabet } from 'nanoid';
import { alphanumeric } from 'nanoid-dictionary';

import type { RootState } from '@/core/store';

type TApiHeadersState = {
  version: string;
  context: {
    sessionId: string;
    interface: string;
  };
};

const createSessionId = customAlphabet(alphanumeric);

const initialState: TApiHeadersState = {
  version: THUNDERBOLT_VERSION,
  context: {
    interface: 'thunderbolt',
    sessionId: createSessionId(),
  },
};

const slice = createSlice({
  name: 'apiHeaders',
  initialState,
  reducers: {
    refreshSessionId(state) {
      state.context.sessionId = createSessionId();
    },
  },
});

export const apiHeadersReducer = slice.reducer;

export const { refreshSessionId } = slice.actions;

export const selectApiHeaders = (state: RootState) => state.apiHeaders;
