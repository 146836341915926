import startCase from 'lodash-es/startCase';

export function snakeToCapitalized(snakeString: string): string {
  return snakeString.split('_').map(startCase).join(' ');
}

// Function replaces spaces in the string with non-breaking spaces, so we can avoid using <pre>
export function replaceStartingSpacesWithNbsp(str: string) {
  const spaces = str.match(/^(\s+)/)?.[1] || '';

  return str.replace(spaces, spaces.replace(/ /g, '\u00a0'));
}

export function splitByEscapeSequence(content: string) {
  return content.split('\n').map((chunk) => replaceStartingSpacesWithNbsp(chunk));
}
