import flatMap from 'lodash-es/flatMap';
import flow from 'lodash-es/flow';

import type { Definitions } from '@/types/generated';

type City = Definitions.City;
interface Airport extends Definitions.BaseAirport {
  score?: number;
}
export interface TypedCity extends City {
  type: 'c';
  key: string;
}
export interface TypedAirport extends Airport {
  type: 'a';
  nested: boolean;
  key: string;
}

type Location = City | Airport;

export type TypedLocation = TypedCity | TypedAirport;

export function parseAirLookup(
  response: Definitions.AirDestinationLookupResponse,
): TypedLocation[] {
  const { cities, airports } = response;
  function parseCity(city: City): TypedCity {
    return {
      ...city,
      type: 'c',
      key: city.id,
    };
  }

  function parseAirport(airport: Airport, nested: boolean): TypedAirport {
    return {
      ...airport,
      type: 'a',
      nested,
      key: airport.id,
    };
  }

  function sortByScore(locations: Location[]): Location[] {
    return [...locations].sort((a, b) => b.score! - a.score!);
  }
  function unfoldCity(locations: City[]): Location[] {
    const unfolded = locations.map((city) => {
      if (city.airports) {
        return [city, ...city.airports.map((airport) => parseAirport(airport, true))];
      }

      return city;
    });

    return flatMap(unfolded);
  }

  return flow(
    sortByScore,
    unfoldCity,
  )([...cities.map(parseCity), ...airports.map((airport) => parseAirport(airport, false))]);
}
