import { Center, Grid, Icon } from '@chakra-ui/react';
import { MdCheck } from 'react-icons/md';
import React from 'react';

import { selectOptionIconSize } from '@/components/Select/constants';
import { testIds } from '../testIds';

import type { GroupBase, OptionProps } from 'react-select';

export function Option<Option, isMulti extends boolean, Group extends GroupBase<Option>>({
  children,
  innerProps,
  isSelected,
  isFocused,
  innerRef,
  selectProps,
  isDisabled,
}: OptionProps<Option, isMulti, Group>) {
  const { optionIconSize = 'sm' } = selectProps;

  const isDisabledOption = isDisabled && !isSelected;

  return (
    <Grid
      {...innerProps}
      templateColumns="auto 24px"
      px={4}
      py={2}
      bgColor={isFocused ? 'background.lightest' : 'white'}
      _hover={{
        bgColor: 'background.lightest',
      }}
      cursor="pointer"
      ref={innerRef}
      gap={1}
      fontSize="sm"
      data-testid={testIds.option}
      aria-selected={isSelected}
      opacity={isDisabledOption ? 0.5 : 1}
    >
      {children}
      <Center>
        {isSelected && (
          <Icon as={MdCheck} color="primary.blue1" boxSize={selectOptionIconSize[optionIconSize]} />
        )}
      </Center>
    </Grid>
  );
}
