import React from 'react';
import { AspectRatio, Image } from '@chakra-ui/react';

import type { ContentfulNodeProps } from './types';

export function ContentfulImage({ node }: ContentfulNodeProps) {
  return (
    <AspectRatio
      ratio={
        node.data.target.fields.file.details.image.width /
        node.data.target.fields.file.details.image.height
      }
      my={3}
    >
      <Image
        src={`https:${node.data.target.fields.file.url}`}
        width={node.data.target.fields.file.details.image.width}
      />
    </AspectRatio>
  );
}
