import { avatarAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(avatarAnatomy.keys)

const baseStyle = definePartsStyle({
  container: {
    bg: 'primary.blue4',
    textColor: 'white',
    border: '2px solid',
  },
})

export const AvatarStyles = defineMultiStyleConfig({ baseStyle })
