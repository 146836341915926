import { createSlice } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash-es';

import { getSliceActionsList } from '@/core/utils';
import { setCurrency } from '@/features/userSettings/slice';

import type { AppDispatch, RootState } from '@/core/store';
import type { Definitions } from '@/types/generated';
import type { PayloadAction } from '@reduxjs/toolkit';

const MAX_RECENT_TRAVELERS = 5;

export enum AllowedTravelerActions {
  allowBook = 'allowBook',
  allowEdit = 'allowEdit',
}

type ArrangerState = {
  isArrangerMode: boolean;
  traveler: Definitions.TravelerExtended | null;
  recentTravelers: Definitions.TravelerExtended[];
};

const initialState: ArrangerState = {
  isArrangerMode: true,
  traveler: null,
  recentTravelers: [],
};

const slice = createSlice({
  name: 'arranger',
  initialState,
  reducers: {
    setIsArrangerMode(state, { payload }: PayloadAction<boolean>) {
      state.isArrangerMode = payload;
    },
    selectTraveler(
      state,
      {
        payload,
      }: PayloadAction<{
        traveler: Definitions.GetTravelersTripsResponse['travelers'][number];
      }>,
    ) {
      state.traveler = payload.traveler;
    },
    resetTraveler: (state) => {
      state.traveler = null;
    },
    setRecentTraveler(state, { payload }: PayloadAction<Definitions.TravelerExtended>) {
      const allRecentTravelers = state.recentTravelers || [];
      // set uniq traver and last should be first in array
      state.recentTravelers = uniqBy([payload, ...allRecentTravelers], 'travelerId');
    },
    resetArrangerState: () => initialState,
  },
});

export const {
  setIsArrangerMode,
  resetTraveler,
  selectTraveler,
  setRecentTraveler,
  resetArrangerState,
} = slice.actions;

export const arrangerReducer = slice.reducer;

export const selectTravelerAction =
  (traveler: Definitions.TravelerExtended) => (dispatch: AppDispatch) => {
    dispatch(setCurrency(null));
    dispatch(setIsArrangerMode(true));
    dispatch(selectTraveler({ traveler }));
    dispatch(setRecentTraveler(traveler));
  };

export const resetTravelerAction = () => (dispatch: AppDispatch) => {
  dispatch(setCurrency(null));
  dispatch(resetTraveler());
};

export const selectIsArrangerMode = (state: RootState) => state.arranger.isArrangerMode;
export const selectCurrentTraveler = (state: RootState) => state.arranger.traveler;
export const selectRecentArrangerTravelers = (state: RootState) =>
  getRecentTravelers(state.arranger.recentTravelers);

export const selectCommonRecentTravelers = (state: RootState) =>
  getRecentTravelers(state.arranger.recentTravelers).slice(0, MAX_RECENT_TRAVELERS);

export const selectRecentTravelersAllowBook = (state: RootState) =>
  getRecentTravelers(state.arranger.recentTravelers)
    .filter((traveler) => traveler[AllowedTravelerActions.allowBook])
    .slice(0, MAX_RECENT_TRAVELERS);
export const selectRecentTravelersAllowEdit = (state: RootState) =>
  getRecentTravelers(state.arranger.recentTravelers)
    .filter((traveler) => traveler[AllowedTravelerActions.allowEdit])
    .slice(0, MAX_RECENT_TRAVELERS);

function getRecentTravelers(recentTravelers: any): Definitions.TravelerExtended[] {
  return Array.isArray(recentTravelers) ? recentTravelers : [];
}

export const arrangerActionsSyncList = getSliceActionsList(slice, ['resetArrangerState']);
