// https://github.com/contentful/contentful-resolve-response
// eslint-disable-next-line import/no-extraneous-dependencies
import resolveResponse from 'contentful-resolve-response';

import { spitfire } from '@/core/spitfire';

import type {
  TGetFaqResponseResolved,
  TGetFAQRequest,
  DocumentResponse,
  GetBannersAssetsRequest,
  GetBannersAssetsResponse,
  GetStagingBannersAssetsRequest,
  GetCommonBannersAssetsRequest,
} from './types';
import type { Definitions } from '@/types/generated';

const service = spitfire.injectEndpoints({
  endpoints: (builder) => ({
    getFaq: builder.query<TGetFaqResponseResolved | undefined, TGetFAQRequest>({
      query: ({ locale }) => ({
        url: '/get_faq',
        method: 'POST',
        body: {
          locale,
        },
      }),
      transformResponse: (res) => resolveResponse(res)?.[0],
    }),
    getDocument: builder.query<DocumentResponse, Definitions.GetDocumentRequest>({
      query: (body) => ({
        url: '/get_document',
        method: 'POST',
        body,
      }),
      transformResponse: (res) => resolveResponse(res, { removeUnresolved: true })?.[0],
    }),
    getBannersAssets: builder.query<GetBannersAssetsResponse, GetBannersAssetsRequest>({
      query: (body) => ({
        url: '/get_banners_assets',
        method: 'POST',
        body,
      }),
      transformResponse: (res) => resolveResponse(res),
      extraOptions: {
        errorHandlerSkipOptions: {
          skipByStatusCodes: [404, 503],
        },
      },
    }),
    getStagingBannersAssets: builder.query<
      GetBannersAssetsResponse,
      GetStagingBannersAssetsRequest
    >({
      query: (body) => ({
        url: '/get_staging_banners_assets',
        method: 'POST',
        body,
      }),
      transformResponse: (res) => resolveResponse(res),
      extraOptions: {
        errorHandlerSkipOptions: {
          skipByStatusCodes: [404, 503],
        },
      },
    }),
    getCommonBannersAssets: builder.query<GetBannersAssetsResponse, GetCommonBannersAssetsRequest>({
      query: (body) => ({
        url: '/get_common_banners_assets',
        method: 'POST',
        body,
      }),
      transformResponse: (res) => resolveResponse(res),
      extraOptions: {
        errorHandlerSkipOptions: {
          skipByStatusCodes: [404, 503],
        },
      },
    }),
    getPreviewBannersAssets: builder.query<
      GetBannersAssetsResponse,
      Definitions.GetBannerContentPreviewRequest
    >({
      query: (body) => ({
        url: '/get_banner_content_preview',
        method: 'POST',
        body,
      }),
      transformResponse: (res) => resolveResponse(res),
      extraOptions: {
        errorHandlerSkipOptions: {
          skipByStatusCodes: [404, 503],
        },
      },
    }),
  }),
});

export const {
  useGetFaqQuery,
  useGetDocumentQuery,
  useGetBannersAssetsQuery,
  useGetStagingBannersAssetsQuery,
  useGetCommonBannersAssetsQuery,
  useGetPreviewBannersAssetsQuery,
} = service;
