export const colors = {
  white: '#FFFFFF',
  primary: {
    blue1: '#5170BD',
    blue2: '#5A88C6',
    blue3: '#25245B',
    blue4: '#364D89',
    blue5: '#B4C0E0',
  },
  secondary: {
    blue: '#375192',
    orange: '#F9A350',
    green: '#537f19',
  },
  orange: {
    default: '#D54414',
    hover: '#DD730C',
    active: '#BE630B',
    pale: '#fffaf0',
  },
  tertiary: {
    paleGrey: '#F8F8F8',
    lightGrey: '#F0F0F1',
    lightMedGrey: '#D9D9DB',
    medGrey: '#D0D0D1',
    textGrey: '#5E656D',
    textBlack: '#1A242F',
  },
  background: {
    lightest: '#F9F9F9',
    gray: '#E9ECF2',
    darkGray: '#EDEDED',
    lightGray: '#F2F5F7',
    middleGray: '#F6F6F6',
    lightblue: '#E2E8F5',
    blue: '#D6F0FF',
    disabled: '#E1E3E6',
    grayDark: '#76787A',
    lightOrange: '#FFF3E7',

    stateful: {
      warning: '#D54414',
      warningLight: '#FFF3E7',
      success: '#85C354',
      successLight: '#F9FFF2',
      error: '#DC002E',
      errorLight: '#FDEFF1',
    },
  },
  border: {
    formControl: '#AAAEB3',
    line: '#D8D8D8',
    decor: '#C4C8CC',
    document: '#00629B',
    orange: '#FF9E1B',
    error: '#DC002E',
    lightGray: '#E0E2E4',
  },
  text: {
    base: {
      white: '#FFFFFF',
      placeholder: '#6A727A',
      weak: '#6A727A',
      dark: '#454647',
      gray: '#666666',
      default: '#19191A',
      label: '#454647',
    },
    link: {
      default: '#5170BD',
      focus: '#375192',
      active: '#008EE0',
      disabled: '#76787A',
      gray: '#B1B1B1',
    },
    stateful: {
      success: '#6A982C',
      warning: '#D54414',
      error: '#C8102E',
    },
    scorecard: {
      green: '#6A982C',
      yellow: '#F38316',
      red: '#C8102E',
    },
  },
  navigation: {
    default: '#041E5B',
  },
  tabs: {
    default: '#666666',
    hover: '#5170BD',
    active: '#5170BD',
  },
  messagesCards: {
    tripRelated: {
      read: '#F2F2F2',
      unread: '#FFFFFF',
    },
    notTripRelated: {
      read: '#FFF3E780',
      unread: '#FFF3E7',
    },
  },
};
