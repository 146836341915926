import { datetimeUtil } from '@/utils/time';

import type { CarsSearchValues } from '@/features/cars';

export const DEFAULT_SEARCH_RADIUS = 0.5;

export const FORM_FIELDS = {
  PICK_UP_LOCATION: 'pickUpLocation',
  DROP_OFF_LOCATION: 'dropOffLocation',
  DROP_OFF_RADIUS: 'dropoffSearchRadius',
  DIFF_DROP_OFF: 'differentDropOff',
  PICK_UP_DATE: 'pickUpDate',
  PICK_UP_TIME: 'pickUpTime',
  DROP_OFF_DATE: 'dropOffDate',
  DROP_OFF_TIME: 'dropOffTime',
  PICK_UP_RADIUS: 'pickupSearchRadius',
  RADIUS_UNIT: 'searchRadiusUnit',
  ID_NUMBERS: 'idNumbers',
} as const;

export const defaultInitialValues: CarsSearchValues = {
  pickUpLocation: null,
  dropOffLocation: null,
  pickupSearchRadius: DEFAULT_SEARCH_RADIUS,
  dropoffSearchRadius: DEFAULT_SEARCH_RADIUS,
  differentDropOff: false,
  pickUpDate: null,
  pickUpTime: datetimeUtil().hour(9).minute(0).toISOString(),
  dropOffDate: null,
  dropOffTime: datetimeUtil().hour(9).minute(0).toISOString(),
  idNumbers: [],
  searchRadiusUnit: 'KM',
};
