import { groupBy, omit, pick } from 'lodash-es';

import { parseFlightOriginDestination } from './parsers';

import type { Definitions } from '@/types/generated';
import type { ParsedFlightOriginDestination} from './parsers';

export type CarrierCode = Definitions.FareSearchSegment['carrier']['code'];

export type CompareFlightOptionKey = string;

export type FlightOptionsGroups = Record<CompareFlightOptionKey, ParsedFlightOriginDestination[]>;

type FareGroupsObject = Record<
  Definitions.FareSearchFareGroup['fareGroupKey'],
  Definitions.FareSearchFareGroup
>;

export type OriginDestination = FlightOptionsGroups;

export interface ParsedFareSearchResponse {
  fareGroups: FareGroupsObject;
  originDestinations: OriginDestination[];
  emissions: Definitions.AirFareSearchResponse['emissions'];
  policyRules: Definitions.AirFareSearchResponse['policyRules'];
}

// Code from the stackoverflow (it works)
function hashCode(value: string) {
  let hash = 0;
  if (value.length === 0) return hash;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < value.length; i++) {
    const chr = value.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

/** This function is used to group flight options by their attributes.
 * Keep in mind - if you change something here - this change should go to sonex and vice versa.
 * */
function getFlightOptionCompareKey(
  flightOption: ParsedFlightOriginDestination,
): CompareFlightOptionKey {
  const segmentCompareFields = [
    'departure.airport.code',
    'departure.datetime',
    'arrival.airport.code',
    'arrival.datetime',
    'carrier.code',
    'carrier.flightNumber',
    'cabin',
  ];

  const { segments } = flightOption;
  if (!segments?.length) {
    throw new Error(`No segments provided in flight option ${flightOption.flightOptionKey}`);
  }

  const segmentsPart = JSON.stringify(
    segments.map((segment) => pick(segment, segmentCompareFields)),
  );
  return String(hashCode(segmentsPart));
}

const groupFlightOptions = (flightOptions: ParsedFlightOriginDestination[]): FlightOptionsGroups =>
  groupBy(flightOptions, getFlightOptionCompareKey);

const createFlightOptionsGroups = (
  fareGroups: Definitions.FareSearchFareGroup[],
  originDestinationIndex: number,
): FlightOptionsGroups => {
  const flightOptions: ParsedFlightOriginDestination[] = fareGroups.flatMap(
    (fareGroup, fareGroupIndex) => {
      const { originDestinations } = fareGroup;
      const currentFlightOptions = originDestinations[originDestinationIndex].flightOptions;

      return currentFlightOptions.map((flightOption) =>
        parseFlightOriginDestination(flightOption, fareGroup, fareGroupIndex),
      );
    },
  );

  return groupFlightOptions(flightOptions);
};

const createOriginDestinations = (
  fareGroups: Definitions.FareSearchFareGroup[],
): OriginDestination[] => {
  const originDestinationCount = fareGroups[0].originDestinations.length;

  return [...Array(originDestinationCount).keys()].map((originDestinationIndex) =>
    createFlightOptionsGroups(fareGroups, originDestinationIndex),
  );
};

const createFareGroupsObject = (fareGroups: Definitions.FareSearchFareGroup[]) => {
  const FIELDS_TO_SKIP = ['originDestinations'];

  return fareGroups.reduce(
    (acc, fareGroup) => ({
      ...acc,
      [fareGroup.fareGroupKey]: omit(fareGroup, FIELDS_TO_SKIP),
    }),
    {},
  );
};

export const parseFareSearchResponse = (
  response: Definitions.AirFareSearchResponse,
): ParsedFareSearchResponse => {
  const { fareGroups } = response;

  return {
    originDestinations: createOriginDestinations(fareGroups),
    fareGroups: createFareGroupsObject(fareGroups),
    emissions: response.emissions,
    policyRules: response.policyRules,
  };
};
