export enum Paths {
  PREVIEW = '/preview',
  LOGIN = '/login',
  LOGIN_AS_USER = '/login_as_user',
  SIGN_UP = '/login/sign-up',
  SIGN_UP_PASSWORD = '/login/sign-up/:registrationToken',
  SIGN_UP_SUCCESS = '/login/sign-up/success',
  SIGN_UP_ERROR = '/login/sign-up/error',
  COMPANIES = '/login/companies',
  SEND_RESET_EMAIL = '/login/send-reset-email',
  SEND_RESET_EMAIL_SUCCESS = '/login/send-reset-email/success',
  RESET = '/login/reset/:resetToken',
  RESET_SUCCESS = '/login/reset/:resetToken/success',
  RESET_ERROR = '/login/reset/:resetToken/error',
  COMPANY_LOGIN = '/login/:companyLogin',
  COMPANY_SIGN_UP = '/login/:companyLogin/sign-up',
  COMPANY_SIGN_UP_PASSWORD = '/login/:companyLogin/sign-up/:registrationToken',
  COMPANY_SIGN_UP_SUCCESS = '/login/:companyLogin/sign-up/success',
  COMPANY_SIGN_UP_ERROR = '/login/:companyLogin/sign-up/error',
  COMPANY_COMPANIES = '/login/:companyLogin/companies',
  COMPANY_SEND_RESET_EMAIL = '/login/:companyLogin/send-reset-email',
  COMPANY_SEND_RESET_EMAIL_SUCCESS = '/login/:companyLogin/send-reset-email/success',
  COMPANY_RESET = '/login/:companyLogin/reset/:resetToken',
  COMPANY_RESET_SUCCESS = '/login/:companyLogin/reset/:resetToken/success',
  COMPANY_RESET_ERROR = '/login/:companyLogin/reset/:resetToken/error',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
  COMPANY_TERMS_AND_CONDITIONS = '/terms-and-conditions/:companyLogin',
  DASHBOARD = '/dashboard',
  DASHBOARD_FILTER = '/dashboard/:filter',
  BOOK_TRIP = '/book-trip',
  UNIFIED_BOOKING = '/unified-booking/*',
  TRAVEL_PROGRAM = '/travel-program',
  MESSAGES = '/messages',
  MY_PROFILE = '/my-profile/:tab',
  MY_PROFILE_GENERAL = '/my-profile/general',
  MY_PROFILE_FOLLOWERS = '/my-profile/followers',
  MY_PROFILE_SMS_NOTIFICATIONS = '/my-profile/sms-notifications',
  PRIVACY_POLICY = '/privacy-policy',
  TERMS_CONDITIONS = '/terms-conditions',
  FAQ = '/faq',
  CARS = '/cars',
  CARS_RESULTS = '/cars/results',
  CARS_CHECKOUT = '/cars/checkout',
  CARS_RESERVATION = '/cars/reservation/:bookingUid',
  AIR = '/air',
  AIR_RESULTS = '/air/results',
  AIR_CHECKOUT = '/air/checkout',
  AIR_VERIFICATION = '/air/verification',
  AIR_RESERVATION = '/air/reservation/:bookingUid',
  HOTELS = '/hotels',
  HOTELS_RESULTS = '/hotels/results',
  HOTELS_RATES = '/hotels/rates/:hotelId',
  HOTELS_CHECKOUT = '/hotels/checkout/:hotelId',
  HOTELS_RESERVATION = '/hotels/reservation/:bookingUid',
  TRIPS = '/trips/:tripId',
  CREDITS = '/credits',
  SSO = '/sso',
  PAGE_NOT_FOUND = '/*',
  RAIL = '/rail',
  RAIL_RESULTS = '/rail/results',
  RAIL_RETURN_RESULTS = '/rail/return-results',
  RAIL_CHECKOUT = '/rail/checkout',
  RAIL_RESERVATION = '/rail/reservation/:bookingUid',
  AGENT_CHAT_INIT = '/agent-chat-init',
}
