import { extendWithArrangerHeaders } from '@/features/arranger';
import { spitfire } from '@/core/spitfire';
import { parseFareSearchResponse, parseSeatMapResponse, parsePricingResponse } from './utils';
import { FETCH_ERROR_CODE } from '@/core/errors/constants';
import { parseAirLookup } from './common/AirDestinationAutocomplete/parseAirLookup';

import type { TypedLocation } from './common/AirDestinationAutocomplete/parseAirLookup';
import type { TArrangerQueryRequest } from '@/features/arranger';
import type { Definitions } from '@/types/generated';
import type {
  ParsedFareSearchResponse,
  ParsedSeatMapResponse,
  ParsedPricingResponse,
} from './utils';
import type { OrNull } from '@/types/common';

const service = spitfire.injectEndpoints({
  endpoints: (builder) => ({
    airDestinationLookup: builder.query<
      TypedLocation[],
      TArrangerQueryRequest<Definitions.AirDestinationLookupRequest>
    >({
      query: ({ currentTraveler, ...body }) =>
        extendWithArrangerHeaders({
          args: {
            url: 'bookings/air/destination_lookup',
            method: 'POST',
            body,
          },
          currentTraveler,
        }),
      transformResponse: parseAirLookup,
    }),
    fareSearch: builder.query<
      ParsedFareSearchResponse,
      TArrangerQueryRequest<Definitions.AirFareSearchRequest>
    >({
      query: ({ currentTraveler, ...body }) =>
        extendWithArrangerHeaders({
          args: {
            url: 'bookings/air/fare_search',
            method: 'POST',
            body,
          },
          currentTraveler,
        }),
      transformResponse: parseFareSearchResponse,
      extraOptions: {
        errorHandlerSkipOptions: {
          skipByStatusCodes: [404],
        },
      },
    }),
    fareRules: builder.query<
      Definitions.AirFareRulesResponse,
      TArrangerQueryRequest<Definitions.AirFareRulesRequest>
    >({
      query: ({ currentTraveler, ...body }) =>
        extendWithArrangerHeaders({
          args: {
            url: 'bookings/air/fare_rules',
            method: 'POST',
            body,
          },
          currentTraveler,
        }),
      extraOptions: {
        errorHandlerSkipOptions: {
          skipByStatuses: ['ASB_UNSUPPORTED_PROVIDER_METHOD'],
        },
      },
    }),
    pricing: builder.query<
      ParsedPricingResponse,
      TArrangerQueryRequest<Definitions.AirPricingRequest>
    >({
      query: ({ currentTraveler, ...body }) =>
        extendWithArrangerHeaders({
          args: {
            url: 'bookings/air/pricing',
            method: 'POST',
            body,
          },
          currentTraveler,
        }),
      transformResponse: parsePricingResponse,
      extraOptions: {
        errorHandlerSkipOptions: {
          skipByStatusCodes: [404],
        },
      },
    }),
    checkoutData: builder.query<
      Definitions.AirGetCheckoutDataResponse,
      TArrangerQueryRequest<Definitions.AirGetCheckoutDataRequest>
    >({
      query: ({ currentTraveler, ...body }) =>
        extendWithArrangerHeaders({
          args: {
            url: 'bookings/air/get_checkout_data',
            method: 'POST',
            body,
          },
          currentTraveler,
        }),
    }),
    seatMap: builder.query<
      ParsedSeatMapResponse,
      TArrangerQueryRequest<Definitions.AirSeatMapRequest>
    >({
      query: ({ currentTraveler, ...body }) =>
        extendWithArrangerHeaders({
          args: {
            url: 'bookings/air/seat_map',
            method: 'POST',
            body,
          },
          currentTraveler,
        }),
      transformResponse: parseSeatMapResponse,
      extraOptions: {
        errorHandlerSkipOptions: {
          skipAll: true,
        },
      },
    }),
    book: builder.mutation<
      Definitions.AirBookResponse,
      TArrangerQueryRequest<Definitions.AirBookRequest>
    >({
      query: ({ currentTraveler, ...body }) =>
        extendWithArrangerHeaders({
          args: {
            url: 'bookings/air/book',
            method: 'POST',
            body,
          },
          currentTraveler,
        }),
      extraOptions: {
        errorHandlerSkipOptions: {
          skipByStatusCodes: [FETCH_ERROR_CODE],
          skipByStatuses: ['ASB_PRICE_CHECK_FAILED'],
        },
      },
    }),
    reservationDetails: builder.query<
      TAirGetReservationDetailsResponse,
      TArrangerQueryRequest<Definitions.AirGetReservationDetailsRequest>
    >({
      query: ({ currentTraveler, ...body }) =>
        extendWithArrangerHeaders({
          args: {
            url: 'bookings/air/get_reservation_details',
            method: 'POST',
            body,
          },
          currentTraveler,
        }),
      providesTags: ['AirReservation'],
      extraOptions: {
        errorHandlerSkipOptions: {
          skipByStatusCodes: [403, 404, FETCH_ERROR_CODE],
        },
      },
    }),
    cancelFlightReservation: builder.mutation<
      Definitions.AirCancelReservationResponse,
      TArrangerQueryRequest<Definitions.AirCancelReservationRequest>
    >({
      query: ({ currentTraveler, ...body }) =>
        extendWithArrangerHeaders({
          args: {
            url: 'bookings/air/cancel_reservation',
            method: 'POST',
            body,
          },
          currentTraveler,
        }),
      invalidatesTags: ['AirReservation'],
    }),
    completeVerification: builder.mutation<
      Definitions.AirCompleteVerificationResponse,
      TArrangerQueryRequest<Definitions.AirCompleteVerificationRequest>
    >({
      query: ({ currentTraveler, ...body }) =>
        extendWithArrangerHeaders({
          args: {
            url: 'bookings/air/complete_verification',
            method: 'POST',
            body,
          },
          currentTraveler,
        }),
    }),
    unusedTickets: builder.query<Definitions.AirUnusedTicketsResponse, TArrangerQueryRequest>({
      query: ({ currentTraveler, ...body }) =>
        extendWithArrangerHeaders({
          args: {
            url: 'get_air_unused_tickets',
            method: 'POST',
            body,
          },
          currentTraveler,
        }),
      extraOptions: {
        errorHandlerSkipOptions: {
          skipAll: true,
        },
      },
    }),
  }),
});

export const {
  useLazyPricingQuery,
  useBookMutation,
  useCompleteVerificationMutation,
  useCancelFlightReservationMutation,
  useAirDestinationLookupQuery,
  useLazyAirDestinationLookupQuery,
  useReservationDetailsQuery,
  useLazyReservationDetailsQuery,
  useUnusedTicketsQuery,
  useFareSearchQuery,
  useFareRulesQuery,
  useCheckoutDataQuery,
  useSeatMapQuery,
} = service;

// booking.confirmation -> nullable (coz it is a possible value in the response. despite the schemas from the backend)
export type TAirGetReservationDetailsResponse = Omit<
  Definitions.AirGetReservationDetailsResponse,
  'booking'
> & {
  booking?: Omit<
    Required<Definitions.AirGetReservationDetailsResponse>['booking'],
    'confirmation'
  > & {
    confirmation: OrNull<
      Required<Definitions.AirGetReservationDetailsResponse>['booking']['confirmation']
    >;
  };
};
