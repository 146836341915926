import { createSlice } from '@reduxjs/toolkit';

import { isCommonCRD } from './utils';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@/core/store';
import type { Definitions } from '@/types/generated';


type State = {
  pnrId: string;
  crd: Definitions.ReportableData[];
};

const initialState: State = {
  pnrId: '',
  crd: [],
};

type SaveTripCRDPayload = {
  pnrId?: string;
  crd?: Definitions.ReportableData[];
};

const slice = createSlice({
  name: 'crd',
  initialState,
  reducers: {
    saveTripCRD(state, { payload }: PayloadAction<SaveTripCRDPayload>) {
      if (payload.pnrId && payload.crd) {
        state.pnrId = payload.pnrId;
        state.crd = payload.crd.filter(isCommonCRD);
      }
    },
  },
});

export const crdReducer = slice.reducer;
export const { saveTripCRD } = slice.actions;

export const selectTripCRD = (pnrId?: string) => (state: RootState) => {
  if (pnrId && pnrId === state.crd.pnrId) {
    return state.crd.crd;
  }

  return null;
};
