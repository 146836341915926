import React from 'react';
import {
  Alert as ChakraAlert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
} from '@chakra-ui/react';

import { ErrorStatusIcon, InfoStatusIcon, SuccessStatusIcon, WarningStatusIcon } from '@/icons';

import type {
  AlertProps as ChakraAlertProps,
  AlertIconProps,
  AlertTitleProps,
} from '@chakra-ui/react';
import type { PropsWithChildren } from 'react';
import type { TAlertVariant } from '@/components/Alert/AlertStyles';
import type { TIcon } from '@/types/common';

export type AlertStatuses = 'error' | 'warning' | 'success' | 'info';

export type AlertProps = ChakraAlertProps & {
  status: AlertStatuses;
  title?: string;
  showIcon?: boolean;
  className?: string;
  dismissible?: boolean;
  iconProps?: AlertIconProps;
  onClose?: () => void;
  variant?: TAlertVariant;
  alertTitleProps?: AlertTitleProps;
  dataTestId?: string;
};

const alertIconsMap: Record<AlertStatuses, TIcon> = {
  error: ErrorStatusIcon,
  warning: WarningStatusIcon,
  success: SuccessStatusIcon,
  info: InfoStatusIcon,
};

export function Alert({
  status,
  title,
  showIcon = true,
  dismissible,
  iconProps,
  children,
  onClose,
  alertTitleProps,
  dataTestId,
  ...restProps
}: PropsWithChildren<AlertProps>) {
  return (
    <ChakraAlert status={status} data-testid={dataTestId} {...restProps}>
      {showIcon && (
        <AlertIcon
          as={alertIconsMap[status]}
          boxSize="15px"
          mt="0.25em"
          aria-hidden
          {...iconProps}
        />
      )}
      <Box w="full">
        <AlertTitle {...alertTitleProps}>
          {title ?? ''}
          {dismissible && (
            <CloseButton
              size="sm"
              color="inherit"
              alignSelf="flex-start"
              position="absolute"
              top="0"
              right="0"
              onClick={onClose}
            />
          )}
        </AlertTitle>
        <AlertDescription>{children}</AlertDescription>
      </Box>
    </ChakraAlert>
  );
}
