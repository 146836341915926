import { uniq } from 'lodash-es';
import { airHelpers } from '@getgoing/hurricane';

import { isFlightInPolicy } from './flightOptionHelpers';
import { isDefined } from '@/utils/typeGuards';

import type { Definitions } from '@/types/generated';
import type { CarrierCode } from '@/features/air/utils/parseFareSearchResponse';

export type FareSearchFlightOption = Definitions.FlightOption;
export type PricingOriginDestination = Definitions.PricingOriginDestination;
export type ReservationDetailsOriginDestination =
  Definitions.AirReservationDetailsOriginDestination;
export type FlightOptionSegment =
  | Definitions.FareSearchSegment
  | Definitions.PricingSegment
  | Definitions.AirReservationDetailsSegment;
export type FareGroup = Definitions.FareSearchFareGroup | Definitions.PricingFareGroup;
export type FlightOptionOrOriginDestination = FareSearchFlightOption | PricingOriginDestination;

interface FareGroupFields
  extends Record<string, unknown>,
    Pick<
      Definitions.FareSearchFareGroup & Definitions.PricingFareGroup,
      | 'fareGroupKey'
      | 'total'
      | 'ticketRefundable'
      | 'ticketAttributes'
      | 'currency'
      | 'isBcdNegotiated'
      | 'isCompanyNegotiated'
    >,
    Partial<Pick<Definitions.FareSearchFareGroup, 'baggageAllowance'>>,
    Partial<Pick<Definitions.PricingFareGroup, 'baggageAllowances'>> {
  fareGroupIndex: number;
}

type ExtendedFareSearchFlightOption = FareSearchFlightOption & FareGroupFields;
export type ExtendedPricingOriginDestination = PricingOriginDestination & FareGroupFields;
export type ExtendedFlightOptionOrOriginDestination =
  | ExtendedFareSearchFlightOption
  | ExtendedPricingOriginDestination;

export function isExtendedPricingOriginDestination(
  originDestination: ExtendedFlightOptionOrOriginDestination,
): originDestination is ExtendedPricingOriginDestination {
  return 'optionalServices' in originDestination;
}

export const injectFareGroupInfo = (
  flightOptionOrOriginDestination: FlightOptionOrOriginDestination,
  fareGroup: FareGroup,
  fareGroupIndex: number,
): ExtendedFlightOptionOrOriginDestination => {
  const commonFields = {
    ...flightOptionOrOriginDestination,
    fareGroupKey: fareGroup.fareGroupKey!,
    total: fareGroup.total,
    ticketRefundable: fareGroup.ticketRefundable,
    ticketAttributes: fareGroup.ticketAttributes,
    currency: fareGroup.currency,
    isBcdNegotiated: fareGroup.isBcdNegotiated,
    isCompanyNegotiated: fareGroup.isCompanyNegotiated,
    fareGroupIndex,
  };

  if ('baggageAllowance' in fareGroup) {
    return {
      ...commonFields,
      baggageAllowance: fareGroup.baggageAllowance,
    };
  }

  if ('baggageAllowances' in fareGroup) {
    return {
      ...commonFields,
      baggageAllowances: fareGroup.baggageAllowances,
    };
  }

  return commonFields;
};

// eslint-disable-next-line no-shadow
export enum FlightOptionStops {
  Direct = 'Nonstop',
  One = '1 Stop',
  Multiple = '2+ Stops',
}

const getFlightOptionStops = (
  flightOption: ExtendedFlightOptionOrOriginDestination,
): FlightOptionStops => {
  const { segments = [] } = flightOption;
  if (segments.length <= 1) {
    return FlightOptionStops.Direct;
  }

  if (segments.length < 3) {
    return FlightOptionStops.One;
  }

  return FlightOptionStops.Multiple;
};
const isBcdPreferredFlightOption = (
  flightOption: ExtendedFlightOptionOrOriginDestination,
): boolean => {
  const { segments = [] } = flightOption;

  return segments.some((segment) => segment.bcdPreferredAirline);
};
const isCompanyPreferredFlightOption = (
  flightOption: ExtendedFlightOptionOrOriginDestination,
): boolean => {
  const { segments = [] } = flightOption;

  return segments.some((segment) => segment.preferredAirline);
};

export enum FlightOptionPreference {
  BCDPreferred = 'BCDPreferred',
  CompanyPreferred = 'CompanyPreferred',
}

const getFlightOptionPreferences = (
  flightOption: ExtendedFlightOptionOrOriginDestination,
): FlightOptionPreference[] => {
  const preferences = [];

  if (isBcdPreferredFlightOption(flightOption)) {
    preferences.push(FlightOptionPreference.BCDPreferred);
  }

  if (isCompanyPreferredFlightOption(flightOption)) {
    preferences.push(FlightOptionPreference.CompanyPreferred);
  }

  return preferences;
};

export enum FlightOptionExtras {
  BaggageIncluded = 'BaggageIncluded',
}

const getFlightOptionExtras = (
  flightOption: ExtendedFlightOptionOrOriginDestination,
): FlightOptionExtras[] => {
  const extras = [];
  if (airHelpers.isBaggageIncluded(flightOption.ticketAttributes.baggage)) {
    extras.push(FlightOptionExtras.BaggageIncluded);
  }

  return extras;
};

export type Cabin = Definitions.FareSearchSegment['cabin'];
export type ParsedFlightOriginDestination = ExtendedFlightOptionOrOriginDestination & {
  bcdPreferred: boolean;
  companyPreferred: boolean;
  cabins: Cabin[];
  airlines: CarrierCode[];
  stops: FlightOptionStops;
  preferences: FlightOptionPreference[];
  extras: FlightOptionExtras[];
  outOfPolicy: boolean;
};

export type PartialParsedFlightOption =
  | Pick<ParsedFlightOriginDestination, 'segments'>
  | Pick<PricingOriginDestination, 'segments'>
  | Pick<ReservationDetailsOriginDestination, 'segments'>;
export type FlightOptionOrSegments = PartialParsedFlightOption | FlightOptionSegment[];

export const injectParsedOriginDestinationInfo = (
  flightOption: ExtendedFlightOptionOrOriginDestination,
): ParsedFlightOriginDestination => {
  const { segments = [] } = flightOption;
  return {
    ...flightOption,
    bcdPreferred: isBcdPreferredFlightOption(flightOption),
    companyPreferred: isCompanyPreferredFlightOption(flightOption),
    cabins: uniq(segments.map((segment) => segment.cabin).filter(isDefined)),
    airlines: uniq(segments.map((segment) => segment.carrier.code)),
    stops: getFlightOptionStops(flightOption),
    preferences: getFlightOptionPreferences(flightOption),
    extras: getFlightOptionExtras(flightOption),
    outOfPolicy: !isFlightInPolicy(flightOption),
  };
};

export const parseFlightOriginDestination = (
  flightOptionOrOriginDestination: FlightOptionOrOriginDestination,
  fareGroup: FareGroup,
  fareGroupIndex: number,
) =>
  injectParsedOriginDestinationInfo(
    injectFareGroupInfo(flightOptionOrOriginDestination, fareGroup, fareGroupIndex),
  );
