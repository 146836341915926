import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { InputStyles } from '../Input/InputStyles';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  selectAnatomy.keys,
);

const baseStyle = definePartsStyle({
  field: {
    textOverflow: 'ellipsis',
    backgroundColor: 'white',
    borderWidth: '0 !important',
    outlineColor: 'border.formControl',
    outlineWidth: '1px',
    outlineOffset: '0',

    ':focus-within': {
      outlineWidth: '2px',
      outlineColor: 'primary.blue1',
    },

    _invalid: {
      outlineColor: 'text.stateful.error',
    },

    placeholder: {
      color: 'text.base.placeholder !important',
    },

    _disabled: {
      color: 'text.base.placeholder !important',
      opacity: 1,
      backgroundColor: 'background.disabled !important',
    },
  },

  icon: {
    color: 'border.formControl',
    mt: '1px',
  },

  outline: {
    field: {
      backgroundColor: 'white !important',
      borderWidth: '0 !important',
      outlineColor: 'border.formControl',
      outlineWidth: '1px',
      outlineOffset: '0',

      _focusVisible: {
        outlineColor: 'primary.blue1',
        outlineWidth: '2px',
      },
    },
  },
});

const sizes = {
  lg: {
    ...InputStyles.sizes?.lg,
    field: {
      ...InputStyles.sizes?.lg.field,
    },
  },
  md: {
    ...InputStyles.sizes?.md,
    field: {
      ...InputStyles.sizes?.md.field,
    },
  },
  sm: {
    ...InputStyles.sizes?.sm,
    field: {
      ...InputStyles.sizes?.sm.field,
    },
  },
  xs: {
    ...InputStyles.sizes?.xs,
    field: {
      ...InputStyles.sizes?.xs.field,
    },
  },
};

export const SelectStyles = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: 'sm',
    variant: 'outline',
  },
});
