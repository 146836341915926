import React from 'react';
import {
  Code,
  Td,
  Th,
  Tr,
  Divider,
  Text,
  UnorderedList,
  OrderedList,
  ListItem,
} from '@chakra-ui/react';
import { INLINES, MARKS, BLOCKS } from '@contentful/rich-text-types';

import { ContentfulImage } from './components/ContentfulImage';
import { ContentfulLink } from './components/ContentfulLink';
import { ContentfulTable } from './components/ContentfulTable';

import type { Options as ContentfulRenderOptions } from '@contentful/rich-text-react-renderer';

export const contentfulRenderOptions: ContentfulRenderOptions = {
  renderMark: {
    [MARKS.CODE]: (text) => <Code>{text}</Code>,
    [MARKS.BOLD]: (text) => <Text as="strong">{text}</Text>,
    [MARKS.ITALIC]: (text) => <Text as="em">{text}</Text>,
    [MARKS.UNDERLINE]: (text) => <Text as="u">{text}</Text>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => (
      <ContentfulLink node={node}>{children}</ContentfulLink>
    ),
    [BLOCKS.HR]: () => <Divider my={2} />,
    [BLOCKS.QUOTE]: (node, children) => (
      <Text pl={8} py={1} my={6} borderLeft="3px solid" borderColor="gray.300">
        {children}
      </Text>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node) => <ContentfulImage node={node} />,
    // Table
    [BLOCKS.TABLE]: (node, children) => <ContentfulTable node={node}>{children}</ContentfulTable>,
    [BLOCKS.TABLE_HEADER_CELL]: (_, children) => (
      <Th
        py={1}
        px={2}
        color="text.base.dark"
        textTransform="none"
        fontSize="inherit"
        borderColor="gray.300"
      >
        {children}
      </Th>
    ),
    [BLOCKS.TABLE_ROW]: (_, children) => <Tr>{children}</Tr>,
    [BLOCKS.TABLE_CELL]: (_, children) => (
      <Td py={1} px={2} fontSize="inherit" borderColor="gray.300">
        {children}
      </Td>
    ),
    [BLOCKS.PARAGRAPH]: (_, children) => <Text my={2}>{children}</Text>,
    [BLOCKS.UL_LIST]: (_, children) => <UnorderedList my={2}>{children}</UnorderedList>,
    [BLOCKS.OL_LIST]: (_, children) => <OrderedList my={2}>{children}</OrderedList>,
    [BLOCKS.LIST_ITEM]: (_, children) => <ListItem>{children}</ListItem>,
    [BLOCKS.HEADING_1]: (_, children) => (
      <Text textStyle="h4" as="h1" my={8}>
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_2]: (_, children) => (
      <Text textStyle="h4" as="h2" my={8}>
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_3]: (_, children) => (
      <Text textStyle="h4" as="h3" my={8}>
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_4]: (_, children) => (
      <Text textStyle="h4" as="h4" my={8}>
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_5]: (_, children) => (
      <Text textStyle="h4" as="h5" my={8}>
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_6]: (_, children) => (
      <Text textStyle="h4" as="h6" my={8}>
        {children}
      </Text>
    ),
  },
};
