import { useParams } from 'react-router-dom';

import { useAppSelector } from '@/core/hooks';
import { Paths } from '@/app/routes/paths';
import { getCompanyLoginPaths } from '@/features/auth/utils';

export const useAuth = () => {
  const { meta, accessToken, selectedCompany } = useAppSelector((state) => state.auth);
  const isLoggedIn = !!accessToken && !!meta && !!selectedCompany;

  return {
    ...meta,
    accessToken,
    isLoggedIn,
  };
};

export const useCompanyLoginPaths = (): Record<keyof typeof Paths, string> => {
  const { companyLogin } = useParams();

  return companyLogin ? getCompanyLoginPaths(companyLogin) : Paths;
};
