// TODO: Use react-aria for datepicker and get rid of date-fns lib.
// Temporary solution. react-datepicker requires date-fns locales only :(
import { registerLocale } from 'react-datepicker';
/* eslint-disable import/no-duplicates */
import enUS from 'date-fns/locale/en-US';
import esES from 'date-fns/locale/es';
import ptBR from 'date-fns/locale/pt';
import deDE from 'date-fns/locale/de';
import frFR from 'date-fns/locale/fr';
import nnNO from 'date-fns/locale/nn';
import svSE from 'date-fns/locale/sv';
import frCA from 'date-fns/locale/fr-CA';
import jaJP from 'date-fns/locale/ja';

import { Datepicker } from './Datepicker';
import { DatepickerRange } from './DatepickerRange';
import { useDatepicker } from './hooks/useDatepicker';
import { useDatepickerRange } from './hooks/useDatepickerRange';

registerLocale('en-US', enUS);
registerLocale('es-ES', esES);
registerLocale('pt-BR', ptBR);
registerLocale('de-DE', deDE);
registerLocale('fr-FR', frFR);
registerLocale('nn-NO', nnNO);
registerLocale('sv-SE', svSE);
registerLocale('ja-JP', jaJP);
registerLocale('fr-CA', frCA);

export { Datepicker, DatepickerRange, useDatepicker, useDatepickerRange };
