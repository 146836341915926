import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  cardAnatomy.keys,
);

const variants = {
  rate: definePartsStyle({
    container: {
      cursor: 'pointer',
      borderColor: 'border.line',
      borderWidth: 1,
      borderBottomWidth: 0,
      borderRadius: 0,
      bg: 'white',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
      transition: 'all 100ms ease',
      _first: {
        borderTopRadius: 12,
        mt: 0,
      },
      _last: { borderBottomRadius: 12, mb: 0, borderBottomWidth: 1 },

      _hover: {
        bgColor: 'background.lightest',
      },
    },
  }),

  elevated: definePartsStyle({
    container: {
      bg: 'white',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
      borderRadius: 12,
    },
    footer: {
      borderColor: 'border.line',
      borderTopWidth: 1,
    },
  }),

  'highlight-success': definePartsStyle({
    container: {
      bg: 'background.stateful.successLight',
      border: '2px solid',
      borderColor: 'text.stateful.success',
      borderRadius: 12,
    },
  }),
};

const sizes = {
  sm: {
    body: {
      p: 2,
    },
    header: {
      p: 2,
    },
    footer: {
      p: 2,
    },
  },

  md: {
    body: {
      p: 4,
    },
    header: {
      p: 4,
      pb: 2,
    },
    footer: {
      p: 4,
    },
  },

  lg: {
    body: {
      p: 6,
    },
    header: {
      p: 6,
      pb: 2,
    },
    footer: {
      p: 6,
    },
  },

  xl: {
    body: {
      p: 12,
    },
    header: {
      p: 12,
      pb: 4,
    },
    footer: {
      p: 12,
    },
  },
};

export const CardStyles = defineMultiStyleConfig({ variants, sizes });
