export const latinSpaceRegex = /^[a-zA-Z\s]*$/;

export const latinNumberRegex = /^[a-zA-Z0-9]+$/;

export const latinNumberOptionalRegex = /^[A-Za-z0-9]*$/;

export const latinNumberSpaceRegex = /^[a-zA-Z0-9\s]+$/;

export const numberOptionalRegex = /^[0-9]*$/;

export const USZIPCodeRegex = /^\d{5}$/;

/* took from https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript */
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
