import { createSlice } from '@reduxjs/toolkit';

import { getSliceActionsList } from '@/core/utils';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@/core/store';
import type { TCurrency } from '@/types/bookings';
import type { OrNull } from '@/types/common';


type TUserSettingsState = {
  currency: OrNull<TCurrency>;
};

const initialState: TUserSettingsState = {
  currency: null,
};

const slice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    setCurrency(state: TUserSettingsState, { payload }: PayloadAction<OrNull<TCurrency>>) {
      state.currency = payload;
    },
    resetUserSettingsState() {
      return initialState;
    },
  },
});

export const userSettingsReducer = slice.reducer;

export const selectUserCurrency = (state: RootState) => state.userSettings.currency;

export const { setCurrency, resetUserSettingsState } = slice.actions;

export const userSettingsActionsSyncList = getSliceActionsList(slice, ['resetUserSettingsState']);
