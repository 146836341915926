import React from 'react';
import { generatePath, Route } from 'react-router-dom';
import omitBy from 'lodash-es/omitBy';
import isNil from 'lodash-es/isNil';

import {
  RouteType,
} from '@/types/router';
import { WithSuspense } from '@/app/routes/WithSuspense';

import type {
  AppRoute,
  BaseRoute,
  ContainerRoute,
  DefaultRoute,
  IndexRoute} from '@/types/router';
import type { RouteProps } from 'react-router-dom';

export const generateAbsolutePath: typeof generatePath = (path, params) => {
  const generated = generatePath(path, params);
  const url = new URL(generated, window.location.href);

  return url.href;
};

export const indexRouteGuard = (route: AppRoute): route is IndexRoute =>
  route.type === RouteType.INDEX;
export const containerRouteGuard = (route: AppRoute): route is ContainerRoute =>
  route.type === RouteType.CONTAINER;
export const baseRouteGuard = (route: AppRoute): route is BaseRoute =>
  route.type === RouteType.BASE;
export const defaultRouteGuard = (route: AppRoute): route is DefaultRoute =>
  route.type === RouteType.DEFAULT;

export function buildRoutes(routes?: AppRoute[], prefix = '') {
  if (!routes) {
    return undefined;
  }

  return routes.map((route, i) => {
    const hasPath = baseRouteGuard(route) || defaultRouteGuard(route);

    const getElement = () => {
      if (!baseRouteGuard(route)) {
        const Element = route.component;
        return (
          <WithSuspense>
            <Element />
          </WithSuspense>
        );
      }

      return null;
    };
    const path = hasPath ? route.pathname.replace(prefix, '') : undefined;
    const newPrefix = path ? `${prefix}${path}/` : prefix;

    const props: RouteProps = omitBy(
      {
        path,
        element: getElement(),
        ...(indexRouteGuard(route)
          ? { index: true, children: undefined }
          : {
              index: false,
              children: buildRoutes(route.children, newPrefix),
            }),
      },
      (value) => isNil(value),
    );

    return <Route key={prefix + (props.path ?? i)} {...props} />;
  });
}
