import { nanoid } from 'nanoid';

import {
  GetCurrentProductError
} from './types';
import { Products } from '@/utils/constants/common';
import { UNIFIED_BOOKING_PRODUCTS } from './UnifiedSearch/constants';
import { UNIFIED_BOOKING_PATH } from './constants';

import type {
  AirProduct,
  CarProduct,
  GetCurrentProductResult,
  HotelProduct,
  Product,
  UnifiedBooking} from './types';
import type { UnifiedForm } from './UnifiedSearch';
import type { Paths } from '@/app/routes/paths';

export const convertFormToProducts = (unifiedForm: UnifiedForm): Product[] =>
  UNIFIED_BOOKING_PRODUCTS.flatMap((product) =>
    unifiedForm[product].map((form, index) => ({
      id: nanoid(),
      form,
      bookingUid: null,
      product,
      index,
    })),
  ).filter((item) => item.form) as Product[];

export const isAirProduct = (product: Product): product is AirProduct =>
  product.product === Products.Air;
export const isCarProduct = (product: Product): product is CarProduct =>
  product.product === Products.Car;
export const isHotelProduct = (product: Product): product is HotelProduct =>
  product.product === Products.Hotel;

export const getAirProduct = (unifiedBooking: UnifiedBooking): AirProduct | undefined =>
  unifiedBooking.products.find(isAirProduct);

export const getUnifiedBookingProducts = (unifiedBooking: UnifiedBooking): Product[] =>
  unifiedBooking.products;

export const getCurrentProduct = (unifiedBooking: UnifiedBooking): GetCurrentProductResult => {
  const products = getUnifiedBookingProducts(unifiedBooking);

  if (products.length === 0) {
    return { error: GetCurrentProductError.NotFound, data: null };
  }

  const product = products.find((item) => !item.bookingUid);

  if (product) {
    return { error: null, data: product };
  }

  return { error: GetCurrentProductError.AllCompleted, data: null };
};

export const isUnifiedBookingInProgress = (unifiedBooking: UnifiedBooking): boolean =>
  getCurrentProduct(unifiedBooking).data !== null;

export function getUnifiedBookingPath(path: Paths | string) {
  return `${UNIFIED_BOOKING_PATH}${path}`;
}

export function isUnifiedBookingPath(path: string) {
  return path.match(UNIFIED_BOOKING_PATH);
}
