import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { carsService } from '@/features/cars/service';

import type { RootState } from '@/core/store';

type TCarsState = {
  searchTimestamp: Date | null;
};

const initialState: TCarsState = {
  searchTimestamp: null,
};

const slice = createSlice({
  name: 'cars',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addMatcher(carsService.endpoints.getAvailability.matchPending, (state) => {
        state.searchTimestamp = null;
      })
      .addMatcher(carsService.endpoints.getAvailability.matchFulfilled, (state) => {
        state.searchTimestamp = dayjs().toDate();
      });
  },
});

export const selectCarsSearchTimestamp = (state: RootState) => state.cars.searchTimestamp;

export const carsReducer = slice.reducer;
