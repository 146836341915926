import { components } from 'react-select';
import React from 'react';

import type { GroupBase, GroupHeadingProps } from 'react-select';

export function GroupHeading<Option, isMulti extends boolean, Group extends GroupBase<Option>>({
  children,
  ...props
}: GroupHeadingProps<Option, isMulti, Group>) {
  return <components.GroupHeading {...props} data-testid="select-group-heading">
    {children}
  </components.GroupHeading>
}
