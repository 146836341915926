import { languageFallback, localeToLanguageNameMapping } from '@/app/language/constants';

import type { TAppLocales } from '@/app/language/types';

export function isSupportedLocale(locale: unknown): locale is TAppLocales {
  return typeof locale === 'string' && locale in localeToLanguageNameMapping;
}

export function getLocale(locale: unknown): TAppLocales {
  return isSupportedLocale(locale) ? locale : languageFallback;
}
