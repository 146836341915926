export const airTestIds = {
  search: {
    from: 'air-search-destination-from',
    fromError: 'air-search-destination-from-error',
    to: 'air-search-destination-to',
    toError: 'air-search-destination-to-error',
    departure: 'air-search-departure',
    departureError: 'air-search-departure-error',
    return: 'air-search-return',
    returnError: 'air-search-return-error',
    departureTime: 'air-search-departure-time',
    departureTimeGroup: 'air-search-departure-time-group',
    returnTime: 'air-search-return-time',
    timeOption: 'air-search-time-option',
    searchButton: 'air-search-button',
    tripTypeSelector: 'air-search-trip-type-selector',
    cabinTypeSelector: 'air-search-cabin-type-selector',
    addFlight: 'air-search-add-flight',
    removeFlight: 'air-search-remove-flight',
    swapDestinationsButton: 'air-search-swap-destinations-button',
  },
  common: {
    fareRulesTitle: 'air-fare-rules-title',
    techStop: 'air-tech-stop',
    techStopTitle: 'air-tech-stop-title',
    airlineIcon: 'air-airline-icon',
  },
  result: {
    unusedTickets: 'air-unused-tickets',
    unusedTicketsTitle: 'air-unused-tickets-title',
    unusedTicketsItem: 'air-unused-tickets-item',
    unusedTicketsGroup: 'air-unused-tickets-group',
    unusedTicketsItemTitle: 'air-unused-tickets-item-title',
    unusedTicketsItemExpire: 'air-unused-tickets-item-expire',
    unusedTicketsItemAccordionArrow: 'air-unused-tickets-accordion-arrow',
    flightOption: 'air-flight-option',
    flightOptionOpened: 'air-flight-option-opened',
    flightOptionClosed: 'air-flight-option-closed',
    flightOptionDetails: 'air-flight-option-details',
    flightOptionAmenities: 'air-flight-option-amenities',
    flightOptionEmissions: 'air-flight-option-emissions',
    flightOptionDetailsEmissions: 'air-flight-option-details-emissions',
    flightOptionPreferences: 'air-flight-option-preferences',
    flightOptionRatePreferences: 'air-flight-option-rate-preferences',
    flightOptionAirlineIcon: 'air-flight-option-airline-icon',
    flightOptionToggleBtn: 'air-flight-option-toggle-btn',
    flightOptionPolicy: 'air-flight-option-policy',
    flightOptionDepartureArrivalTime: 'air-flight-option-departure-arrival-time',
    flightOptionCarriers: 'air-flight-option-carriers',
    flightOptionDuration: 'air-flight-option-duration',
    flightOptionAirports: 'air-flight-option-airports',
    flightOptionStops: 'air-flight-option-stops',
    flightOptionExtras: 'air-flight-option-extras',
    flightOptionDetailsExtras: 'air-flight-option-details-extras',
    flightOptionPrice: 'air-flight-option-price',
    flightOptionDepartureDate: 'air-flight-option-departure-date',
    flightOptionSelect: 'air-flight-option-select',
    flightOptionFareRules: 'air-flight-option-fare-rules',
    flightOptionMeal: 'air-flight-option-meal',
    flightOptionCabin: 'air-flight-option-cabin',
    flightOptionBaggage: 'air-flight-option-baggage',
    flightOptionDepartureTerminal: 'air-flight-option-departure-terminal',
    flightOptionArrivalTerminal: 'air-flight-option-arrival-terminal',
    flightOptionAirplane: 'air-flight-option-airplane',
    flightOptionDetailsOperatedBy: 'air-flight-option-details-operated-by',
    flightOptionDetailsCarrier: 'air-flight-option-details-carrier',
    flightOptionDetailsDuration: 'air-flight-option-details-duration',
    flightOptionDetailsDeparture: 'air-flight-option-details-departure',
    flightOptionDetailsArrival: 'air-flight-option-details-arrival',
    flightOptionDetailsLayover: 'air-flight-option-details-layover',
    flightOptionDetailsChangeWarning: 'air-flight-option-details-change-warning',
    fare: {
      flightPreviewDirection: 'air-fare-flight-preview-direction',
      flightPreview: 'air-fare-flight-preview',
      flightPreviewDate: 'air-fare-flight-preview-date',
      flightPreviewTime: 'air-fare-flight-preview-time',
      flightPreviewDuration: 'air-fare-flight-preview-duration',
      ticketFlexibility: 'air-fare-ticket-flexibility',
      ticketSeat: 'air-fare-ticket-seat',
      ticketBags: 'air-fare-ticket-bags',
      ticketPrice: 'air-fare-ticket-price',
      tooltip: 'air-fare-tooltip',
      outOfPolicy: 'air-fare-out-of-policy',
    },
  },
  checkout: {
    travelerInformation: 'air-checkout-traveler-information',
    travelerInformationName: 'air-checkout-traveler-information-name',
    travelerInformationEmail: 'air-checkout-traveler-information-email',
    purchaseConditionsOpen: 'air-checkout-purchase-conditions-open',
    hazardousMaterialsOpen: 'air-checkout-hazardous-materials-open',
    hazardousMaterialsTitle: 'air-checkout-hazardous-materials-title',
    hazardousMaterialsClose: 'air-checkout-hazardous-materials-close',
    optionalServices: 'air-checkout-optional-service',
    optionalServicesListItem: 'air-checkout-optional-service-list-item',
    serviceModal: {
      cancel: 'air-checkout-service-modal-cancel',
      add: 'air-checkout-service-modal-add',
    },
    travelDocuments: 'air-checkout-travel-documents',
    seatMap: {
      seat: 'air-checkout-seatmap-seat',
      total: 'air-checkout-seatmap-total',
      unavailable: 'air-checkout-seatmap-unavailable',
      add: 'air-checkout-seatmap-add',
      item: 'air-checkout-optional-service-seatmap-item',
      nextFlight: 'air-checkout-seatmap-next-flight',
      prevFlight: 'air-checkout-seatmap-prev-flight',
      save: 'air-checkout-seatmap-save',
      cancel: 'air-checkout-seatmap-cancel',
      segment: {
        item: 'air-checkout-seatmap-segment',
        control: 'air-checkout-seatmap-segment-control',
        destination: 'air-checkout-seatmap-segment-destination',
        class: 'air-checkout-seatmap-segment-class',
        seat: 'air-checkout-seatmap-segment-seat',
        unavailable: 'air-checkout-seatmap-segment-unavailable',
      },
    },
    documents: {
      error: 'air-checkout-documents-error',
      modal: 'air-checkout-documents-modal',
      ktn: 'air-checkout-ktn-section',
      ktnModal: 'air-checkout-ktn-modal',
      ktnKnownTraveler: 'air-checkout-ktn-known-traveler-field',
      ktnRedressNumber: 'air-checkout-ktn-redress-number-field',
      ktnCountry: 'air-checkout-ktn-country-select',
      ktnInvalidTravelerAndRedress: 'air-checkout-ktn-invalid-traveler-and-redress',
      ktnInvalidTraveler: 'air-checkout-ktn-invalid-traveler',
      ktnInvalidRedress: 'air-checkout-ktn-invalid-redress',
      documentContent: 'air-checkout-document-content',
      documentEditBtn: 'air-checkout-document-edit-button',
      documentApplyBtn: 'ait-checkout-document-apply-button',
      documentCancelBtn: 'air-checkout-document-cancel-button',
      documentFooter: 'air-checkout-document-footer',
      addDocumentButton: 'air-checkout-document-add-button',
      documentTitle: 'air-checkout-document-title',
      noDocs: 'air-checkout-document-no-docs',
      passportOrNational: 'air-checkout-document-passport-or-national',
      tabs: {
        list: 'air-checkout-document-tabs',
        passport: 'air-checkout-document-passport-tab',
        nationalId: 'air-checkout-document-national-id-tab',
      },
      useDocForThisTrip: {
        box: 'air-checkout-document-use-doc-for-this-trip-box',
        checkbox: 'air-checkout-document-use-doc-for-this-trip-checkbox',
        error: 'air-checkout-document-use-doc-for-this-trip-error',
        label: 'air-checkout-document-use-doc-for-this-trip-label',
      },
      passport: {
        documentsList: 'air-checkout-document-passport-documents-list',
        documentCard: 'air-checkout-document-passport-document-card',
        place: 'air-checkout-document-passport-place',
        expiredDate: 'air-checkout-document-passport-expired-date',
        issueDate: 'air-checkout-document-passport-issue-date',
        number: 'air-checkout-document-passport-passport-number',
        nameOfPassport: 'air-checkout-document-passport-name-of-passport',
        cityzenship: 'air-checkout-document-passport-cityzenship',
        addDocument: 'air-checkout-document-passport-add-document',
        errorMessage: 'air-checkout-document-passport-error-message',
        addForm: {
          modal: 'air-checkout-document-add-modal',
          form: 'air-checkout-document-add-form',
          firstName: 'air-checkout-document-add-form-first-name',
          middleName: 'air-checkout-document-add-form-middle-name',
          lastName: 'air-checkout-document-add-form-last-name',
          passportNumber: 'air-checkout-document-add-form-passport-number',
          issueDate: 'air-checkout-document-add-form-issue-date',
          expirationDate: 'air-checkout-document-add-form-expiration-date',
          issueCountry: 'air-checkout-document-add-form-issue-country',
          citizenship: 'air-checkout-document-add-form-citizenship',
        },
      },
      nationalID: {
        nationalList: 'air-checkout-document-national-id-list',
        nationalCard: 'air-checkout-document-national-id-card',
        number: 'air-checkout-document-national-id-card-number',
        country: 'air-checkout-document-national-id-country',
        expiredDate: 'air-checkout-document-national-id-expired-date',
        addCard: 'air-checkout-document-national-id-add-national-card',
        errorMessage: 'air-checkout-document-national-id-error-message',
        addForm: {
          modal: 'air-checkout-document-national-id-add-form-modal',
          form: 'air-checkout-document-national-id-form',
          nationalIdCardNumber: 'air-checkout-document-national-id-form-national-id-card-number',
          country: 'air-checkout-document-national-id-form-country',
          expiredDate: 'air-checkout-document-national-id-form-expired-date',
        },
      },
      visa: {
        section: 'air-checkout-visa-section',
        addVisa: 'air-checkout-visa-add-new-button',
        invalidError: 'air-checkout-visa-invalid-error',
        documentCard: {
          section: 'air-checkout-document-visa-document-card',
          number: 'air-checkout-document-visa-document-card-number',
        },
        form: {
          number: 'air-checkout-visa-form-number',
          country: 'air-checkout-visa-form-country',
          placeOfBirth: 'air-checkout-visa-form-placeOfBirth',
          issuePlace: 'air-checkout-visa-form-issuePlace',
          issueDate: 'air-checkout-visa-form-issueDate',
          expirationDate: 'air-checkout-visa-form-expirationDate',
        },
        newVisa: 'air-checkout-visa-new-visa',
      },
    },
    modifyButton: 'air-checkout-modify-button',
    detailsButton: 'air-checkout-details-button',
    fareRulesLink: 'air-checkout-fare-rules-link',
    bookButton: 'air-checkout-book-button',
    summaryTitle: 'air-checkout-summary-title',
    summaryItem: {
      block: 'air-checkout-summary-item',
      title: 'air-checkout-summary-item-title',
      direction: 'air-checkout-summary-item-direction',
      date: 'air-checkout-summary-item-date',
      time: 'air-checkout-summary-item-time',
      layover: 'air-checkout-summary-item-layover',
    },
  },
  reservationDetails: {
    confirmationNumber: 'air-reservation-details-confirmation-number',
    agencyConfirmationNumber: 'air-reservation-details-agency-confirmation-number',
    destination: {
      airports: 'air-reservation-details-destination-airports',
      date: 'air-reservation-details-destination-date',
      time: 'air-reservation-details-destination-time',
      layovers: 'air-reservation-details-destination-layovers',
      plane: 'air-reservation-details-destination-plane',
    },
    price: 'air-reservation-details-price',
  },
  breadcrumbs: {
    newReservation: 'breadcrumbs-new-reservation',
  },
};
