import React from 'react';
import {
  ErrorBoundary,
  globalHandlersIntegration,
  httpContextIntegration,
  replayIntegration,
  breadcrumbsIntegration,
  thirdPartyErrorFilterIntegration,
  init,
} from '@sentry/react';

import { Fallback } from './Fallback';

init({
  dsn: import.meta.env.THUNDERBOLT_SENTRY_DSN,
  environment: import.meta.env.MODE,
  release: THUNDERBOLT_SENTRY_RELEASE,
  replaysOnErrorSampleRate: 1,
  replaysSessionSampleRate: 0,

  integrations: [
    globalHandlersIntegration(),
    httpContextIntegration(),
    replayIntegration(),
    breadcrumbsIntegration(),
    thirdPartyErrorFilterIntegration({
      filterKeys: [import.meta.env.THUNDERBOLT_APPLICATION_KEY],
      behaviour: 'apply-tag-if-contains-third-party-frames',
    }),
  ],
  defaultIntegrations: false,
  ignoreErrors: [
    /**
     * Thrown when firefox prevents an add-on from refrencing a DOM element that has been removed.
     * This can also be filtered by enabling the browser extension inbound filter
     */
    "TypeError: can't access dead object",
    /**
     * React internal error thrown when something outside react modifies the DOM
     * This is usually because of a browser extension or Chrome's built-in translate
     */
    "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
    "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
});

type Props = {
  children: React.ReactNode;
};

export function AppErrorBoundary({ children }: Props) {
  return <ErrorBoundary fallback={<Fallback />}>{children}</ErrorBoundary>;
}
