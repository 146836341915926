import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getCurrentProduct } from './utils';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@/core/store';
import type { Product, UnifiedBooking, ProductForm } from './types';

export type SaveProductsPayload = Product[];
export type SaveBookingIdPayload = { id: string; bookingUid: string; pnrId?: string };
export type SaveBookingIdThunkPayload = { bookingUid: string; pnrId?: string };
export type RemoveProductPayload = { id: string };

const initialState: UnifiedBooking = {
  products: [],
};

const slice = createSlice({
  name: 'unifiedBooking',
  initialState,
  reducers: {
    saveProducts(state, { payload }: PayloadAction<SaveProductsPayload>) {
      state.products = payload;
    },
    saveBookingId(state, { payload }: PayloadAction<SaveBookingIdPayload>) {
      const index = state.products.findIndex((product) => product.id === payload.id);
      state.products[index].bookingUid = payload.bookingUid;
      state.pnrId = payload.pnrId;
      return state;
    },
    updateProductFormById(state, { payload }: PayloadAction<{ id: string; form: ProductForm }>) {
      const index = state.products.findIndex((product) => product.id === payload.id);
      state.products[index].form = payload.form;
      return state;
    },
    removeProduct(state, { payload }: PayloadAction<{ id: string }>) {
      state.products = state.products.filter((product) => product.id !== payload.id);
      return state;
    },
    clearUnifiedBooking() {
      return initialState;
    },
  },
});

export const unifiedBookingReducer = slice.reducer;
export const { clearUnifiedBooking, updateProductFormById } = slice.actions;

export const selectUnifiedBooking = (state: RootState) => state.unifiedBooking;

export const saveProducts = createAsyncThunk<
  UnifiedBooking,
  SaveProductsPayload,
  { state: RootState }
>('unifiedBooking/saveProducts', (payload, thunkAPI) => {
  thunkAPI.dispatch(slice.actions.saveProducts(payload));
  return selectUnifiedBooking(thunkAPI.getState());
});

export const saveBookingId = createAsyncThunk<
  UnifiedBooking | null,
  SaveBookingIdThunkPayload,
  { state: RootState }
>('unifiedBooking/saveBookingId', (payload, thunkAPI) => {
  const { data } = getCurrentProduct(selectUnifiedBooking(thunkAPI.getState()));

  if (!data) {
    return null;
  }

  thunkAPI.dispatch(slice.actions.saveBookingId({ ...payload, id: data.id }));
  return selectUnifiedBooking(thunkAPI.getState());
});

export const removeProduct = createAsyncThunk<
  UnifiedBooking,
  RemoveProductPayload,
  { state: RootState }
>('unifiedBooking/removeProduct', (payload, thunkAPI) => {
  thunkAPI.dispatch(slice.actions.removeProduct(payload));
  return selectUnifiedBooking(thunkAPI.getState());
});

export const updateProductForm = createAsyncThunk<undefined, ProductForm, { state: RootState }>(
  'unifiedBooking/updateProductForm',
  (payload, thunkAPI) => {
    const { data } = getCurrentProduct(selectUnifiedBooking(thunkAPI.getState()));
    if (!data) {
      return undefined;
    }

    thunkAPI.dispatch(slice.actions.updateProductFormById({ form: payload, id: data.id }));
  },
);
