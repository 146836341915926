import { cssVar, defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

const $startColor = cssVar('skeleton-start-color');
const $endColor = cssVar('skeleton-end-color');

const defaultVariant = defineStyle({
  _light: {
    [$startColor.variable]: 'colors.background.lightGray',
    [$endColor.variable]: 'colors.background.disabled',
  },
  _dark: {
    [$startColor.variable]: 'colors.background.lightGray',
    [$endColor.variable]: 'colors.background.disabled',
  },
});

export const SkeletonStyles = defineStyleConfig({
  variants: { default: defaultVariant },
  defaultProps: {
    variant: 'default',
  },
});
