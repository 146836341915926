import { components } from 'react-select';
import React from 'react';
import { Box, Center, Icon } from '@chakra-ui/react';

import type { ControlProps, GroupBase } from 'react-select';

export function Control<Option, isMulti extends boolean, Group extends GroupBase<Option>>({
  children,
  ...props
}: ControlProps<Option, isMulti, Group>) {
  return (
    <Box data-testid={props.selectProps.dataTestId}>
      <components.Control {...props}>
        {props.selectProps.inputLeftIcon && (
          <Center>
            <Icon as={props.selectProps.inputLeftIcon} color="border.formControl" />
          </Center>
        )}
        {children}
      </components.Control>
    </Box>
  );
}
