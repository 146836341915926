export const styles = {
  global: () => ({
    body: {
      color: 'text.base.default',
      minHeight: '100vh',
      backgroundColor: 'background.lightest',
      [`@media screen and (min-width: 916px)`]: {
        overflowX: 'hidden',
      },
      marginRight: 'calc(-1 * (100vw - 100%))',
    },

    '#root': {
      minHeight: '100vh',
    },

    '#root > div': {
      minHeight: '100vh',
    },

    input: {
      fontSize: '14px',
    },

    hr: {
      opacity: '1 !important',
      borderColor: 'border.line !important',
    },

    'li > svg': {
      transform: 'translateY(-2px)',
    },

    pre: {
      fontFamily: 'body',
    },
  }),
};
