import { theme } from '@chakra-ui/react';

import { colors } from '@/theme/colors';
import { fontSizes } from '@/theme/fonts';

export const timeButtonVariants = {
  outline: {
    minHeight: 'auto',
    bgColor: 'white',
    outline: `1px solid ${colors.border.formControl}`,
    borderColor: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      borderColor: 'transparent',
    },
  },
  unstyled: {
    minHeight: 'auto',
    height: 'auto',
    borderColor: 'transparent',
    borderWidth: 0,
    boxShadow: 'none',
    padding: 0,
    '&:hover': {
      borderColor: 'transparent',
    },
    '&:focus': {
      borderColor: 'transparent',
    },
    '&:focus-visible': {
      borderColor: 'transparent',
    },
  },
};

export const timeButtonSizes = {
  sm: {
    fontSize: fontSizes.sm,
    height: theme.sizes['8'],
    paddingRight: theme.sizes['1'],
    paddingLeft: theme.sizes['2'],
  },
  md: {
    fontSize: fontSizes.md,
    height: theme.sizes['12'],
    px: theme.sizes['5'],
  },
  lg: {
    fontSize: fontSizes.lg,
    height: theme.sizes['14'],
    paddingLeft: theme.sizes['8'],
  },
};
