import { Button, Center, Icon, useClipboard, useToast } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineContentCopy } from 'react-icons/md';

import { Alert } from '@/components/Alert';

import type { ButtonProps, UseToastOptions } from '@chakra-ui/react';

type TClipboardTextProps = ButtonProps & {
  text?: string;
  toastOptions?: UseToastOptions;
  testId?: string;
};

export function ClipboardText({
  text,
  toastOptions,
  testId,
  children,
  ...buttonProps
}: TClipboardTextProps) {
  const { t } = useTranslation();
  const { onCopy } = useClipboard(text ?? '');
  const toast = useToast({
    position: 'top',
    render: () => (
      <Alert variant="embedded" status="info" sx={{ '@media print': { display: 'none' } }}>
        {t('Copied successfully!')}
      </Alert>
    ),
    ...toastOptions,
  });

  const handleCopy = () => {
    onCopy();
    toast();
  };

  if (!text) return null;

  return (
    <Button
      variant="link"
      fontWeight="semibold"
      rightIcon={<Icon as={MdOutlineContentCopy} color="currentcolor" />}
      onClick={handleCopy}
      minH={6}
      data-testid={testId}
      {...buttonProps}
    >
      <Center>{children ?? text}</Center>
    </Button>
  );
}
