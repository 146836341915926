import { defineStyle, defineStyleConfig, cssVar, popperCSSVars } from '@chakra-ui/react';

const popperArrowBg = cssVar('popper-arrow-bg');

const variants = {
  light: defineStyle({
    bg: 'white',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.3)',
    color: 'text.base.default',
    borderRadius: 4,
    pointerEvents: 'all',
    [popperArrowBg.variable]: 'white',
    [popperCSSVars.arrowShadowColor.var]: 'rgba(0, 0, 0, 0.15)',
  }),
};

const sizes = {
  md: defineStyle({
    fontSize: 'sm',
    maxW: '378px',
    p: 4,
  }),
};

export const TooltipStyles = defineStyleConfig({
  sizes,
  variants,
  defaultProps: {
    size: 'md',
    variant: 'light',
  },
});
