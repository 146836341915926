import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import camelCaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import * as Sentry from '@sentry/react';

import { API_URL } from './constants';
import {
  LogoutReason,
  logoutThirdPartyServices,
  resetAuthState,
  selectUserAuthState,
  setAccessToken,
  setLogoutDetails,
} from '@/features/auth/slice';
import { getAccessToken } from './selectors';
import { getHeaders } from '@/core/utils';
import { handleGlobalError } from '@/core/errors/utils';
import { refreshSessionId, selectApiHeaders } from '@/core/slices/apiHeaders/slice';
import { resetArrangerState, selectCurrentTraveler } from '@/features/arranger';
import { resetUserSettingsState } from '@/features/userSettings/slice';

import type { TSpitfireErrorStatus, TSpitfireSerializedErrorResponse } from '@/core/errors/types';
import type { AppDispatch, RootState } from './store';
import type { RefreshTokenResult } from '@/features/auth/service';
import type { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import type {
  BaseQueryApi,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';

const baseQuerySpitfire = fetchBaseQuery({
  baseUrl: API_URL,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const state = getState() as RootState;

    const accessToken = getAccessToken(state);
    const { version, context } = selectApiHeaders(state);
    const contextHeader = JSON.stringify(snakecaseKeys(context));

    headers.set('X-Version', version);
    headers.set('X-Context', contextHeader);

    return getHeaders({ headers, accessToken });
  },
});

let refreshTokenPromise: Promise<boolean> | null = null;
const refreshTokenInProgressStorageKey = 'refresh-token-in-progress';

window.addEventListener('beforeunload', () => {
  localStorage.setItem(refreshTokenInProgressStorageKey, 'false');
});

function awaitForRefreshToken() {
  return new Promise<void>((resolve) => {
    const handler = (event: StorageEvent) => {
      if (event.key === refreshTokenInProgressStorageKey && event.newValue === 'false') {
        resolve();
        window.removeEventListener('storage', handler);
      }
    };
    window.addEventListener('storage', handler);
  });
}

async function refreshToken(api: BaseQueryApi, extraOptions: ExtraOptions): Promise<boolean> {
  if (localStorage.getItem(refreshTokenInProgressStorageKey) === 'true') {
    await awaitForRefreshToken();
    return true;
  }

  localStorage.setItem(refreshTokenInProgressStorageKey, 'true');
  const refreshResult = (await baseQuerySpitfire(
    '/auth/refresh',
    api,
    extraOptions,
  )) as QueryReturnValue<RefreshTokenResult, FetchBaseQueryError, FetchBaseQueryMeta>;

  if (refreshResult.data && !refreshResult.error) {
    const { accessToken: newAccessToken } = camelCaseKeys<RefreshTokenResult>(refreshResult.data, {
      deep: true,
    });
    api.dispatch(setAccessToken({ accessToken: newAccessToken }));
    localStorage.setItem(refreshTokenInProgressStorageKey, 'false');
    return true;
  }
  localStorage.setItem(refreshTokenInProgressStorageKey, 'false');
  api.dispatch(authTokenExpired());
  return false;
}

const baseQuerySpitfireWithReauth: BaseQueryFn<
  string | TSpitfireFetchArgs,
  unknown,
  FetchBaseQueryError,
  ExtraOptions
> = async (args, api, extraOptions) => {
  await refreshTokenPromise;
  const argsParameterIsString = typeof args === 'string';
  let result;
  let isSkip401Handling;

  // transforms data keys in body to snake_case for the Python backend
  if (argsParameterIsString) {
    result = await baseQuerySpitfire(args, api, extraOptions);
  } else {
    args.body = args.body ? snakecaseKeys(args.body) : args.body;
    result = await baseQuerySpitfire(args, api, extraOptions);
    isSkip401Handling = Boolean(args.skip401Handling);
  }

  if (!isSkip401Handling && result.error && result.error.status === 401) {
    if (!refreshTokenPromise) {
      refreshTokenPromise = refreshToken(api, extraOptions);
      try {
        const refreshResult = await refreshTokenPromise;

        if (refreshResult) {
          result = await baseQuerySpitfire(args, api, extraOptions);
        }
      } finally {
        refreshTokenPromise = null;
      }
    } else {
      await refreshTokenPromise;
      result = await baseQuerySpitfire(args, api, extraOptions);
    }
  }

  // transforms data keys from snake_case (sent from Python backend) to camelCase by default,
  // or does nothing if extraOptions.camelCase is "false"
  let camelCase = true;

  if (
    extraOptions &&
    Object.hasOwn(extraOptions, 'camelCase') &&
    typeof extraOptions.camelCase === 'boolean'
  ) {
    camelCase = extraOptions.camelCase;
  }

  if (result.data && camelCase) {
    result.data = camelCaseKeys(result.data as Record<string, unknown>, { deep: true });
  }

  /** Global error handler
   * Use extraOptions -> errorHandlerSkipOptions for disable this handler
   *     endpointName: build.mutation<R,A>({
   *        ...
   *       extraOptions: { errorHandlerSkipOptions: {...} }, // <- skip options for global error handler
   *     }),
   */

  if (result.error && (isSkip401Handling || result.error.status !== 401)) {
    const error = result.error as TSpitfireSerializedErrorResponse;
    const isForbiddenTravelerError = error.data?.status === 'ARRANGER_TRAVELER_FORBIDDEN';

    if (isForbiddenTravelerError) {
      api.dispatch(resetAppStore());
    }

    handleGlobalError(error, {
      skipOptions: {
        closeOnRouteChange: !isForbiddenTravelerError,
        ...extraOptions?.errorHandlerSkipOptions,
      },
      toastOptions: { duration: isForbiddenTravelerError ? 10000 : null },
    });
  }

  return result;
};

const tagTypes = [
  'TravelerInfo',
  'Messages',
  'GetMessages',
  'GetGroupedMessages',
  'GetUnreadMessagesCount',
  'UserSettings',
  'Trips',
  'HotelReservation',
  'CarReservation',
  'AirReservation',
  'GetRecentMessages',
  'TravelPolicies',
  'HyattSettings',
  'CompanyLogo',
  'GetSSOConfig',
] as const;

export const spitfire = createApi({
  reducerPath: 'spitfire',
  baseQuery: baseQuerySpitfireWithReauth,
  endpoints: () => ({}),
  tagTypes,
});

export function resetSpitfireApiState() {
  return spitfire.util.resetApiState();
}

export function resetAuthStore() {
  return (dispatch: AppDispatch) => {
    dispatch(resetAuthState());
    dispatch(resetArrangerState());
  };
}

export function appAuthLogout() {
  return (dispatch: AppDispatch) => {
    dispatch(resetAuthStore());
    dispatch(logoutThirdPartyServices());
    dispatch(refreshSessionId());
    Sentry.setUser(null);
  };
}

export function resetAppStore() {
  return (dispatch: AppDispatch) => {
    dispatch(appAuthLogout());
    dispatch(resetSpitfireApiState());
    dispatch(resetUserSettingsState());
  };
}

function authTokenExpired() {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    dispatch(
      setLogoutDetails({
        reason: LogoutReason.TokenExpired,
        authState: selectUserAuthState(state),
        traveler: selectCurrentTraveler(state),
      }),
    );
    dispatch(resetAppStore());
  };
}

export type TErrorHandlerSkipOptions = {
  skipAll?: boolean;
  skipByStatuses?: TSpitfireErrorStatus[];
  skipByStatusCodes?: (number | string)[];
  closeOnRouteChange?: boolean;
};

type ExtraOptions = {
  camelCase?: boolean;
  errorHandlerSkipOptions?: TErrorHandlerSkipOptions;
  arrangerCompatible?: boolean;
};

export type SpitfireAddItemReq<T> = Omit<T, 'itemId'>;
export type SpitfireAddItemRes<T> = {
  items: T[];
  itemId: string;
};

export type SpitfireUpdateItemReq<T> = {
  itemId: string;
  newInfo: Omit<T, 'itemId'>;
};
export type SpitfireUpdateItemRes<T> = {
  newItemId: string;
  items: T[];
};

export type SpitfireRemoveItemReq = string;
export type SpitfireRemoveItemRes<T> = {
  items: T[];
};

export type TSpitfireFetchArgs = FetchArgs & {
  skip401Handling?: boolean;
};
