import React from 'react';
import { Box, Flex, Spacer, Text, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import { airTestIds } from '@/features/air/testids';

export function UnusedTicketsHeader() {
  const { t } = useTranslation();

  return (
    <Flex mb={4} alignItems="center" data-testid={airTestIds.result.unusedTicketsTitle}>
      <Text fontSize="sm" fontWeight="semibold">
        {t('You have available credits with these airlines')}
      </Text>
      <Spacer />
      <Tooltip
        label={t(
          'Credits will be applied automatically to future bookings. Please note that certain restrictions and rules established by the carriers may be in effect.',
        )}
        fontSize="md"
      >
        <Box float="right" color="border.formControl">
          <AiOutlineInfoCircle
            aria-label={t(
              'Credits will be applied automatically to future bookings. Please note that certain restrictions and rules established by the carriers may be in effect.',
            )}
          />
        </Box>
      </Tooltip>
    </Flex>
  );
}
