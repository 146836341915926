import React from 'react';
import { components } from 'react-select';
import { HStack } from '@chakra-ui/react';

import type { GroupBase, IndicatorsContainerProps } from 'react-select';

export function IndicatorsContainer<
  Option,
  isMulti extends boolean,
  Group extends GroupBase<Option>,
>({ children, ...props }: IndicatorsContainerProps<Option, isMulti, Group>) {
  return (
    <components.IndicatorsContainer {...props}>
      <HStack spacing={2} onMouseDown={(e) => e.stopPropagation()}>
        {props.selectProps.extraIndicators}
        {children}
      </HStack>
    </components.IndicatorsContainer>
  );
}
