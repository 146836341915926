import { createSlice } from '@reduxjs/toolkit';

import { getSliceActionsList } from '@/core/utils';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { OrNull } from '@/types/common';
import type { Definitions } from '@/types/generated';
import type { RootState } from '@/core/store';

type TSSOInitialState = {
  region: OrNull<Definitions.CheckSSOResponse['region']>;
};

const SSOInitialState: TSSOInitialState = {
  region: null,
};

const slice = createSlice({
  name: 'SSO',
  initialState: SSOInitialState,
  reducers: {
    setSSORegion(
      state,
      { payload }: PayloadAction<OrNull<Definitions.CheckSSOResponse['region']>>,
    ) {
      state.region = payload;
    },
  },
});

export const SSOReducer = slice.reducer;

export const { setSSORegion } = slice.actions;

export const selectSSORegion = (state: RootState) => state.SSO.region;

export const SSOActionsSyncList = getSliceActionsList(slice, ['setSSORegion']);
