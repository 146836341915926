import { Box, Tag } from '@chakra-ui/react';
import omit from 'lodash-es/omit';
import React from 'react';

import type { GroupBase, MultiValueGenericProps } from 'react-select';

export function MultiValueContainer<
  Option,
  isMulti extends boolean,
  Group extends GroupBase<Option>,
>({ children, innerProps }: MultiValueGenericProps<Option, isMulti, Group>) {
  return (
    <Box {...omit(innerProps, 'css')} m={1} ml={0}>
      <Tag margin="0 !important" size="sm">
        {children}
      </Tag>
    </Box>
  );
}
