import { parseFlightOriginDestination } from './parsers';

import type { Definitions } from '@/types/generated';
import type { ParsedFlightOriginDestination } from './parsers';

export interface ParsedPricingFareGroup
  extends Omit<Definitions.PricingFareGroup, 'originDestinations'> {
  originDestinations: ParsedFlightOriginDestination[];
}

export interface ParsedPricingResponse extends Omit<Definitions.AirPricingResponse, 'fareGroups'> {
  fareGroups: ParsedPricingFareGroup[];
}

export function parsePricingResponse(
  response: Definitions.AirPricingResponse,
): ParsedPricingResponse {
  return {
    ...response,
    fareGroups: response.fareGroups.map((fareGroup, fareGroupIndex) => ({
      ...fareGroup,
      originDestinations: fareGroup.originDestinations.map((originDestination) =>
        parseFlightOriginDestination(originDestination, fareGroup, fareGroupIndex),
      ),
    })),
  };
}
