import get from 'lodash-es/get';
import { createStandaloneToast } from '@chakra-ui/react';

import { GlobalErrorAlert } from '@/components/GlobalErrorAlert/GlobalErrorAlert';
import {
  customErrorAlertProps,
  customErrorToastOptions,
  ERROR_TOAST_COMMON_OPTIONS,
  errorMessages,
  FETCH_ERROR_CODE,
} from '@/core/errors/constants';

import type {
  PreparedErrorObject,
  TRawError,
  TSpitfireErrorStatus,
  TSpitfireSerializedErrorResponse,
} from '@/core/errors/types';
import type { UseToastOptions } from '@chakra-ui/react';
import type { TErrorHandlerSkipOptions } from '@/core/spitfire';
import type { AlertProps } from '@/components/Alert/Alert';

const { toast } = createStandaloneToast();

const getErrorData = (error: TSpitfireSerializedErrorResponse) => {
  const status = error.data?.status as TSpitfireErrorStatus | undefined;
  const details = error.data?.details;
  const correlationId = error.data?.correlation_id;
  const message = get(errorMessages, `${status}`, errorMessages.UNKNOWN_ERROR)(details);

  return {
    statusCode: error.status,
    status,
    details,
    message,
    correlationId,
  };
};

const shouldSkipError = (
  error: TSpitfireSerializedErrorResponse,
  options?: TErrorHandlerSkipOptions,
): boolean =>
  !!(
    options &&
    (options.skipAll ||
      options.skipByStatusCodes?.includes(error.status) ||
      (error.data && options?.skipByStatuses?.includes(error.data.status)))
  );

export const handleGlobalError = (
  error: TSpitfireSerializedErrorResponse,
  options?: {
    skipOptions?: TErrorHandlerSkipOptions;
    toastOptions?: UseToastOptions;
    alertProps?: AlertProps;
    customMessage?: string;
  },
) => {
  const { alertProps, customMessage, skipOptions, toastOptions } = options ?? {};

  if (shouldSkipError(error, skipOptions)) return;

  const { status, message, correlationId } = getErrorData(error);
  const customToastOptions = status && customErrorToastOptions[status];
  const customAlertProps = status && customErrorAlertProps[status];

  return toast({
    ...ERROR_TOAST_COMMON_OPTIONS,
    render: ({ onClose }) =>
      GlobalErrorAlert({
        message: customMessage || message,
        errorCode: correlationId,
        onClose,
        alertProps: { ...customAlertProps, ...alertProps },
        closeOnRouteChange: skipOptions?.closeOnRouteChange,
      }),
    ...customToastOptions,
    ...toastOptions,
  });
};

export const showErrorToast = (message: string) => {
  toast({
    ...ERROR_TOAST_COMMON_OPTIONS,
    render: ({ onClose }) =>
      GlobalErrorAlert({
        message,
        onClose,
      }),
  });
};

export function prepareErrorObject(error: TRawError | undefined): PreparedErrorObject | null {
  if (error) {
    const { message, details, status, statusCode, correlationId } = getErrorData(
      error as TSpitfireSerializedErrorResponse,
    );

    // if error object has 'code' field it's a Serialized Error
    if ('code' in error) {
      return {
        message: error.message ?? '',
      };
    }

    return {
      statusCode,
      status,
      message,
      details,
      correlationId,
    };
  }

  return null;
}

export const isFetchError = (err: any) => prepareErrorObject(err)?.statusCode === FETCH_ERROR_CODE;

export const isErrorStatus = (error: TRawError, status: TSpitfireErrorStatus) => {
  const parsedError = prepareErrorObject(error);

  return parsedError?.status === status;
};
