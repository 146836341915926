import type { FC } from 'react';
import type { Paths } from '@/app/routes/paths';

export enum RouteType {
  DEFAULT,
  INDEX,
  CONTAINER,
  BASE,
}

export interface GenericRoute {
  type: RouteType;
}

export interface IndexRoute extends GenericRoute {
  type: RouteType.INDEX;
  component: FC;
}

export interface DefaultRoute extends GenericRoute {
  type: RouteType.DEFAULT;
  pathname: Paths;
  component: FC;
  children?: AppRoute[];
}

export interface ContainerRoute extends GenericRoute {
  type: RouteType.CONTAINER;
  component: FC;
  children: AppRoute[];
}

export interface BaseRoute extends GenericRoute {
  type: RouteType.BASE;
  pathname: Paths;
  children: AppRoute[];
}

export type AppRoute = IndexRoute | DefaultRoute | ContainerRoute | BaseRoute;
