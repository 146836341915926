import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  dialog: {
    borderRadius: 'xl',
    px: 6,
    py: 4,
  },

  header: {
    fontSize: '24px',
    p: 0,
    pr: 8,
    fontWeight: 'bold',
  },

  closeButton: {
    top: 4,
    right: 6,

    svg: {
      h: '14px',
      w: '14px',
      color: 'border.formControl',
      stroke: 'border.formControl',
    },
  },

  body: {
    px: 0,
    py: 5,
  },

  footer: {
    px: 0,
    pt: 4,
    pb: 0,
    borderTopWidth: 1,
    borderColor: 'border.line',
  },

  defaultProps: {
    size: 'md',
  },
});

const sm = defineStyle({
  maxWidth: '400px',
});

const md = defineStyle({
  maxWidth: '480px',
});
const lg = defineStyle({
  maxWidth: '700px',
});
const sizes = {
  sm: definePartsStyle({ dialog: sm }),
  md: definePartsStyle({ dialog: md }),
  lg: definePartsStyle({ dialog: lg }),
};

export const ModalStyles = defineMultiStyleConfig({
  baseStyle,
  sizes,
});
