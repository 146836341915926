import { datetimeUtil, DEFAULT_DATE_FORMAT } from '@/utils/time';
import { everyItemHasOriginAndDestination } from './everyItemHasOriginAndDestination';
import { originDestinationValidationSchema } from '../schema/searchSchema';
import { isDefined } from '@/utils/typeGuards';

import type { TFunction } from 'i18next';
import type { ISearchCard, OrNull } from '@/types/common';
import type { TTripInfoQueryParams } from '@/types/bookings';
import type { Definitions } from '@/types/generated';
import type { TTrip, TypedLocation } from '../index';

type TCabin = Definitions.RequestOriginDestination['cabin'];

export type TimeOption = OrNull<string>;

export interface FormOriginDestination {
  date: Date | null;
  time: TimeOption;
  origin: TypedLocation | null;
  destination: TypedLocation | null;
}

export interface URLOriginDestination
  extends Omit<FormOriginDestination, 'date' | 'origin' | 'destination'> {
  date: string;
  origin: TypedLocation;
  destination: TypedLocation;
}

export interface AirSearchFormValues {
  originDestinations: FormOriginDestination[];
  trip: TTrip;
  returnDate: Date | null;
  returnTime: TimeOption;
  ffNumbers: ISearchCard[];
  cabin: TCabin;
  filters?: Record<string, any>;
}

export interface AirSearchFormURLValues
  extends TTripInfoQueryParams,
    Omit<AirSearchFormValues, 'originDestinations' | 'returnDate'> {
  originDestinations: URLOriginDestination[];
  returnDate: string;
}

export const getInitialOriginDestination = (
  originDestination?: Partial<FormOriginDestination>,
): FormOriginDestination => ({
  date: null,
  time: null,
  origin: null,
  destination: null,
  ...originDestination,
});

export const FORM_FIELDS = {
  ORIGIN_DESTINATIONS: 'originDestinations',
  RETURN_DATE: 'returnDate',
  RETURN_TIME: 'returnTime',
  TRIP: 'trip',
  FF_NUMBERS: 'ffNumbers',
  CABIN: 'cabin',
} as const;

export const searchFormInitialValues: AirSearchFormValues = {
  originDestinations: [getInitialOriginDestination()],
  returnDate: null,
  returnTime: null,
  trip: 'round',
  ffNumbers: [],
  cabin: 'economy',
};

export const MULTI_CITY_DESTINATIONS_NUMBER = 3;
const getMultiCityOriginDestinations = (): FormOriginDestination[] =>
  Array.from(Array(MULTI_CITY_DESTINATIONS_NUMBER)).map(getInitialOriginDestination);
export const getMultiCityDestinationsWhenTripChanges = (
  destinations: FormOriginDestination[],
): FormOriginDestination[] => {
  const [destination] = destinations;
  const secondPrefilled =
    destination && destination.destination
      ? {
          ...getInitialOriginDestination(),
          origin: destination.destination,
        }
      : null;

  return [destination, secondPrefilled, ...getMultiCityOriginDestinations()]
    .filter(isDefined)
    .slice(0, MULTI_CITY_DESTINATIONS_NUMBER);
};

export const getNewMultiCityDestination = (
  destinations: FormOriginDestination[],
): FormOriginDestination => {
  const destination = destinations[destinations.length - 1];

  return destination && destination.destination
    ? {
        ...getInitialOriginDestination(),
        origin: destination.destination,
      }
    : getInitialOriginDestination();
};

export const isValidOriginDestination = (t: TFunction) => (destination: FormOriginDestination) => {
  try {
    originDestinationValidationSchema(t).validateSync(destination);

    return true;
  } catch (e) {
    return false;
  }
};

function serializeDate(date: Date | null): string {
  const dateObject = datetimeUtil(date);
  return dateObject.isValid() ? dateObject.format(DEFAULT_DATE_FORMAT) : '';
}

function deserializeDate(date: string): Date | null {
  return date ? datetimeUtil(date, DEFAULT_DATE_FORMAT).toDate() : null;
}

export const deserializeSearchForm = (
  searchParams: AirSearchFormURLValues,
): AirSearchFormValues => ({
  ...searchParams,
  originDestinations: searchParams.originDestinations.map((originDestination) => ({
    ...originDestination,
    date: deserializeDate(originDestination.date),
  })),
  returnDate: deserializeDate(searchParams.returnDate),
});

export const serializeSearchForm = (values: AirSearchFormValues): AirSearchFormURLValues => ({
  ...values,
  originDestinations: everyItemHasOriginAndDestination(values.originDestinations)
    ? values.originDestinations.map((originDestination) => ({
        time: originDestination.time,
        destination: originDestination.destination,
        origin: originDestination.origin,
        date: serializeDate(originDestination.date),
      }))
    : [],
  returnDate: serializeDate(values.returnDate),
});

export const serializeTripRelatedParams = (
  pnrId?: string,
  tripId?: string,
  location?: TTripInfoQueryParams['location'],
): TTripInfoQueryParams => ({
  ...(pnrId && { pnrId }),
  ...(tripId && { tripId }),
  ...(location && { location }),
});

export const createSearchFormSearchParams = (
  values: AirSearchFormValues,
  pnrId?: string,
  tripId?: string,
  location?: TTripInfoQueryParams['location'],
): AirSearchFormURLValues => ({
  ...serializeSearchForm(values),
  ...serializeTripRelatedParams(pnrId, tripId, location),
});
