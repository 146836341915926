import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { sliderAnatomy } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  sliderAnatomy.keys,
);

const baseStyle = definePartsStyle({
  thumb: {
    backgroundColor: 'primary.blue1',
  },
  track: {
    backgroundColor: 'border.decor',
  },
  filledTrack: {
    backgroundColor: 'primary.blue1',
  },
});

const visuallyReversed = {
  thumb: {
    backgroundColor: 'primary.blue1',
  },
  track: {
    backgroundColor: 'primary.blue1',
  },
  filledTrack: {
    backgroundColor: 'border.decor',
  },
};

export const SliderStyles = defineMultiStyleConfig({ baseStyle, variants: { visuallyReversed } });
