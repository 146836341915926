import type { UserCompany } from '@/features/auth/service';
import type { OrNull } from '@/types/common';

/**
 * Checks if OneTrust is accepted by the user
 * Performance/Analytics Category Code = C0002
 * Targeting/Advertising Category Code = C0004
 */
export function isOneTrustAccepted() {
  try {
    return Boolean(
      window.OptanonActiveGroups &&
        (window.OptanonActiveGroups.indexOf('C0002') > -1 ||
          window.OptanonActiveGroups.indexOf('C0004') > -1),
    );
  } catch (error) {
    console.error('Error checking OneTrust status', error);
    return false;
  }
}

export const sendAnalyticsData = (selectedCompany: OrNull<UserCompany>) => {
  if (selectedCompany) {
    try {
      window.heap?.addUserProperties({ company_name: selectedCompany?.title });
    } catch (error) {
      console.error('Error checking Heap status', error);
    }
  }
};
