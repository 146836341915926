import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { useLoadScripts, useLoadLoggedInScripts, useSendAnalyticsData } from './hooks';
import { isOneTrustAccepted } from './utils';

export function ExternalScripts() {
  const [cookieConsent, setCookieConsent] = useState(false);

  const updateCookieConsent = useCallback(() => {
    setCookieConsent(isOneTrustAccepted());
  }, [setCookieConsent]);

  useEffect(() => {
    updateCookieConsent();
    window.addEventListener('OneTrustLoaded', updateCookieConsent);

    return () => window.removeEventListener('OneTrustLoaded', updateCookieConsent);
  }, [updateCookieConsent]);

  useLoadScripts(cookieConsent);
  useLoadLoggedInScripts(cookieConsent);
  useSendAnalyticsData();

  return <Helmet>{/* Add external scripts with src here */}</Helmet>;
}
