import { useEffect } from 'react';

import { useAuth } from '@/features/auth/hooks';
import {
  loadBroadstreetAdsIntegration,
  loadHeapAnalyticsIntegration,
  loadSatmetrixScript,
} from './scripts';
import { useAppSelector } from '@/core/hooks';
import { selectAuthSlice } from '@/features/auth/slice';
import { sendAnalyticsData } from './utils';

export function useLoadScripts(cookieConsent: boolean) {
  useEffect(() => {
    try {
      if (cookieConsent) {
        loadHeapAnalyticsIntegration();
        loadBroadstreetAdsIntegration();
      }
    } catch (error) {
      console.error('Error loading external scripts', error);
    }
  }, [cookieConsent]);
}

export function useLoadLoggedInScripts(cookieConsent: boolean) {
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    try {
      if (isLoggedIn && cookieConsent) {
        return loadSatmetrixScript();
      }
    } catch (error) {
      console.error('Error loading external scripts', error);
    }
  }, [isLoggedIn, cookieConsent]);
}

export function useSendAnalyticsData() {
  const { selectedCompany } = useAppSelector(selectAuthSlice);
  useEffect(() => {
    sendAnalyticsData(selectedCompany);
  }, [selectedCompany]);
}
