import React from 'react';

import type { SVGProps } from 'react';

export function Info(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.33301 3.67513H7.66634V5.00846H6.33301V3.67513ZM6.33301 6.3418H7.66634V10.3418H6.33301V6.3418ZM6.99967 0.341797C3.31967 0.341797 0.333008 3.32846 0.333008 7.00846C0.333008 10.6885 3.31967 13.6751 6.99967 13.6751C10.6797 13.6751 13.6663 10.6885 13.6663 7.00846C13.6663 3.32846 10.6797 0.341797 6.99967 0.341797ZM6.99967 12.3418C4.05967 12.3418 1.66634 9.94846 1.66634 7.00846C1.66634 4.06846 4.05967 1.67513 6.99967 1.67513C9.93967 1.67513 12.333 4.06846 12.333 7.00846C12.333 9.94846 9.93967 12.3418 6.99967 12.3418Z"
        fill="currentColor"
      />
    </svg>
  );
}
