import { chakra, Text, HStack, Center, Icon } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { MdAccessTime } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

import { datetimeUtil, DEFAULT_TIME_FORMAT, getTimeRange } from '@/utils/time';
import { timeButtonSizes, timeButtonVariants } from '@/components/TimePicker/TimePickerStyles';
import { Select, selectComponents } from '@/components/Select';
import { colors } from '@/theme/colors';

import type { TValue } from '@/types/common';
import type {
  OptionProps,
  ValueContainerProps,
  GroupBase,
  SingleValue,
  MultiValue,
} from 'react-select';

type ButtonPropsType = Record<string, string>;

type ValueType = {
  label: string;
  value: string;
};

type ChakraTimepickerProps = {
  timeFormat: string;
  value: string | null;
  placeHolder?: string;
  openMenuOnFocus?: boolean;
  autoFocus?: boolean;
  blurInputOnSelect?: boolean;
  offsetMins?: number;
  isInvalid?: boolean;
  buttonProps?: ButtonPropsType;
  onSelect?: (value: TValue) => void;
  variant?: keyof typeof timeButtonVariants;
  size?: keyof typeof timeButtonSizes;
  testId: string;
  inputId?: string;
};

function CustomOption({ children, ...props }: OptionProps<ValueType>) {
  return (
    <selectComponents.Option {...props}>
      <Text fontWeight="semibold">{children}</Text>
    </selectComponents.Option>
  );
}

const createValueContainer = (testId?: string) =>
  function ({ children, ...props }: ValueContainerProps<ValueType, boolean, GroupBase<ValueType>>) {
    return (
      <selectComponents.ValueContainer {...props}>
        <HStack spacing={1} data-testid={testId} cursor="pointer">
          <Icon as={MdAccessTime} color="border.formControl" />
          <Center>{children}</Center>
        </HStack>
      </selectComponents.ValueContainer>
    );
  };

function TimePicker({
  timeFormat,
  value,
  placeHolder,
  openMenuOnFocus,
  autoFocus,
  blurInputOnSelect,
  offsetMins = 30,
  isInvalid,
  onSelect,
  variant = 'outline',
  size = 'sm',
  testId,
  inputId,
}: ChakraTimepickerProps) {
  const { t } = useTranslation();
  const timeRangeOptions = useMemo(
    () =>
      getTimeRange(offsetMins).map((time) => ({
        label: time.format(timeFormat),
        value: time.format(DEFAULT_TIME_FORMAT),
      })),
    // used t in dependency array to rerender options after switching localization
    [timeFormat, offsetMins, t],
  );

  const handleChange = (newValue: MultiValue<ValueType> | SingleValue<ValueType>) => {
    if (newValue && 'value' in newValue)
      return onSelect?.(datetimeUtil(newValue?.value, DEFAULT_TIME_FORMAT).toISOString());
  };

  const currentValue = timeRangeOptions.find(
    (timeRangeOption) => timeRangeOption.value === datetimeUtil(value).format(DEFAULT_TIME_FORMAT),
  );

  const borderColor = isInvalid ? colors.text.stateful.error : 'transparent';

  const CustomValueContainer = useMemo(() => createValueContainer(testId), [testId]);

  return (
    <Select
      inputId={inputId}
      dataTestId={testId}
      optionIconSize="xs"
      isSearchable={false}
      value={currentValue}
      options={timeRangeOptions}
      openMenuOnFocus={openMenuOnFocus}
      autoFocus={autoFocus}
      blurInputOnSelect={blurInputOnSelect}
      onChange={handleChange}
      styles={{
        container: (baseStyles) => ({
          ...baseStyles,
          width: '100%',
        }),
        control: (baseStyles) => ({
          ...baseStyles,
          ...timeButtonSizes[size],
          ...timeButtonVariants[variant],
          borderColor,
          ':hover': {
            borderColor,
          },
          ':focus-within': {
            outline: `2px solid ${colors.primary.blue1}`,
          },
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          minWidth: '140px',
          whiteSpace: 'nowrap',
        }),
        valueContainer: (baseStyles) => ({
          ...baseStyles,
          padding: 0,
        }),
        singleValue: (baseStyles) => ({
          ...baseStyles,
          margin: 0,
        }),
      }}
      placeholder={placeHolder ?? timeFormat}
      components={{
        IndicatorsContainer: () => null,
        ValueContainer: CustomValueContainer,
        Option: CustomOption,
      }}
    />
  );
}

export const TimePickerWithChakra = chakra(TimePicker);
