import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

import { colors } from '@/theme/colors';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys,
);

export const inputStyles = {
  borderRadius: 'md',
  borderColor: 'border.formControl',
  borderWidth: '1px',
  h: 8,
  ':focus-within': {
    borderColor: 'primary.blue2',
    outline: `1px solid ${colors.primary.blue2} !important`,
  },
};

export const inputHighlightedStyles = {
  fontSize: 'sm',
  h: 12,
  bgColor: 'white',
  outline: `2px solid ${colors.primary.blue1} !important`,
  boxShadow: '0px 7px 18px rgba(0, 0, 0, 0.17)',
  borderRadius: 'md',

  _hover: {
    bgColor: 'white',
    outline: `2px solid ${colors.primary.blue2} !important`,
  },
  _active: {
    outline: `2px solid ${colors.primary.blue2} !important`,
  },
  ':focus-within': {
    boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.6)',
  },
};

const baseStyle = definePartsStyle({
  field: {
    backgroundColor: 'white !important',
    borderWidth: '0 !important',
    outlineColor: 'border.formControl',
    outlineWidth: '1px',
    outlineOffset: '0',

    _focusVisible: {
      outlineColor: 'primary.blue1 !important',
      fill: 'primary.blue1',
      outlineWidth: '2px',
    },

    _invalid: {
      outlineColor: 'text.stateful.error',
    },

    _placeholderShown: {
      color: 'text.base.placeholder',
      textOverflow: 'ellipsis',
      opacity: 1,
    },

    _disabled: {
      opacity: 1,
      backgroundColor: 'background.disabled !important',
    },

    _autofill: {
      transition: 'background-color 0s 600000s',
    },
  },

  element: {
    height: '100%',
  },
});

const variants = {
  highlighted: {
    field: {
      ...inputHighlightedStyles,
    },
  },
  unstyled: {
    field: {
      h: 'full',
      outlineWidth: '0 !important',
    },
  },
  outline: {},
};

const size = {
  lg: defineStyle({
    borderRadius: 'md',
  }),
  md: defineStyle({
    borderRadius: 'md',
  }),
  sm: defineStyle({
    borderRadius: 'md',
  }),
  xs: defineStyle({
    borderRadius: 'md',
  }),
};

const sizes = {
  lg: definePartsStyle({
    field: size.lg,
  }),
  md: definePartsStyle({
    field: size.md,
  }),
  sm: definePartsStyle({
    field: size.sm,
  }),
  xs: definePartsStyle({
    field: size.xs,
  }),
};

export const InputStyles = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    size: 'sm',
    variant: 'outline',
  },
});
