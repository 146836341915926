import { Box, Center, Icon } from '@chakra-ui/react';
import omit from 'lodash-es/omit';
import React from 'react';
import { MdClear } from 'react-icons/md';

import type { ClearIndicatorProps, GroupBase } from 'react-select';

export function ClearIndicator<Option, isMulti extends boolean, Group extends GroupBase<Option>>({
  innerProps,
}: ClearIndicatorProps<Option, isMulti, Group>) {
  return (
    <Box {...omit(innerProps, 'css')} cursor="pointer" data-testid="select-clear-button">
      <Center>
        <Icon as={MdClear} color="border.formControl" />
      </Center>
    </Box>
  );
}
