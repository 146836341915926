import React from 'react';
import { createRoot } from 'react-dom/client';

import { App, AppProviders } from './app';
import { IS_MOCKED } from './core/constants';

if (IS_MOCKED) {
  const { worker } = await import('./mocks/browser');
  worker.start();
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <AppProviders>
    <App />
  </AppProviders>,
);
