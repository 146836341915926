import React from 'react';
import { components } from 'react-select';

import type { GroupBase, InputProps } from 'react-select';

export function Input<Option, isMulti extends boolean, Group extends GroupBase<Option>>({
  children,
  ...props
}: InputProps<Option, isMulti, Group>) {
  return <components.Input {...props}>{children}</components.Input>;
}
