export const textStyles = {
  h1: {
    fontSize: 'h1',
    fontWeight: 'bold',
  },

  h2: {
    fontSize: 'h2 !important',
    fontWeight: 'semibold',
  },

  h3: {
    fontSize: 'h3 !important',
    fontWeight: 'semibold',
  },

  h4: {
    fontSize: 'h4 !important',
    fontWeight: 'semibold',
  },
};
