import { createSlice } from '@reduxjs/toolkit';

import { getSliceActionsList } from '@/core/utils';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@/core/store';


type TAgentChatInitialState = {
  authCode: string | null;
};

const initialState: TAgentChatInitialState = {
  authCode: null,
};

const slice = createSlice({
  name: 'agentChat',
  initialState,
  reducers: {
    setAgentChatAuthCode(state, { payload }: PayloadAction<string | null>) {
      state.authCode = payload;
    },
  },
});

export const agentChatReducer = slice.reducer;

export const { setAgentChatAuthCode } = slice.actions;

export const selectAgentChatAuthCode = (state: RootState) => state.agentChat.authCode;

export const agentChatActionsSyncList = getSliceActionsList(slice, ['setAgentChatAuthCode']);
