import React from 'react';

import type { SVGProps } from 'react';

export function Success(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.99967 0.335938C3.31967 0.335938 0.333008 3.3226 0.333008 7.0026C0.333008 10.6826 3.31967 13.6693 6.99967 13.6693C10.6797 13.6693 13.6663 10.6826 13.6663 7.0026C13.6663 3.3226 10.6797 0.335938 6.99967 0.335938ZM5.66634 10.3359L2.33301 7.0026L3.27301 6.0626L5.66634 8.44927L10.7263 3.38927L11.6663 4.33594L5.66634 10.3359Z"
        fill="currentColor"
      />
    </svg>
  );
}
