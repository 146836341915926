import { Box } from '@chakra-ui/react';
import React from 'react';

import { colors } from '@/theme/colors';
import { useParsedAccessToken } from '@/hooks/useParsedAccessToken';

const loginAsStyles = {
  border: `4px solid ${colors.orange.active}`,
  height: '100vh',
  width: '100vw',
  overflow: 'auto',
};

export function LoginAsIndicator({ children }: { children: React.ReactNode }) {
  const parsedToken = useParsedAccessToken();

  return <Box {...(parsedToken?.su_email ? loginAsStyles : {})}>{children}</Box>;
}
