import React from 'react';
import ReactSelect from 'react-select';
import { useTheme } from '@chakra-ui/react';

import { selectComponents } from '@/components/Select/components';
import { selectStyles, selectTheme } from '@/components/Select/constants';

import type { GroupBase, Props } from 'react-select';

export type TSelectProps<Option, isMulti extends boolean, Group extends GroupBase<Option>> = Omit<
  Props<Option, isMulti, Group>,
  'theme'
>;

export type TOption = {
  value: string;
  label: string;
};

export function Select<
  Option = unknown,
  isMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({ components, styles, ...props }: TSelectProps<Option, isMulti, Group>) {
  const { zIndices } = useTheme();
  return (
    <ReactSelect
      menuPortalTarget={document.body}
      hideSelectedOptions={false}
      components={{
        ...selectComponents,
        ...components,
      }}
      styles={{
        ...selectStyles,
        ...styles,
        menuPortal: (provided) => ({ ...provided, zIndex: zIndices.popover }),
      }}
      theme={selectTheme}
      {...props}
    />
  );
}
