import { Grid, GridItem, HStack, Box, Text, AccordionIcon } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import keyBy from 'lodash-es/keyBy';
import mapValues from 'lodash-es/mapValues';

import { preventDefaultEvent } from '@/utils/preventDefaultEvent';
import { ClipboardText } from '@/components/ClipboardText/ClipboardText';
import { airTestIds } from '@/features/air/testids';
import {
  formatTicketDate,
  getTicketAmount,
  getTicketCurrency,
  getValidatingCarrierCode,
} from '../utils';
import { useAcceptedVendorOptions } from '@/hooks/useAcceptedVendorOptions';
import { AirlineIconImg } from '../../common/AirlineIconImg';

import type { Definitions } from '@/types/generated';

type Props = {
  ticket: Definitions.UnusedTicket;
  groupLength: number;
  isSubTicket?: boolean;
};

export function Ticket({ ticket, groupLength, isSubTicket }: Props) {
  const { t } = useTranslation();
  const airlinesCodesArray = useAcceptedVendorOptions('air');
  const airlinesCodeObject = mapValues(keyBy(airlinesCodesArray, 'value'), 'label');
  const airlineCode = getValidatingCarrierCode(ticket);

  const isOnlyOneInGroup = groupLength === 1;

  return (
    <Grid
      data-testid={airTestIds.result.unusedTicketsItem}
      w="100%"
      gridTemplateColumns="24px 1fr 1fr 1fr 1fr 24px"
      gap={5}
      fontSize="sm"
      pt={isSubTicket ? 3 : 0}
    >
      <GridItem>
        {!isSubTicket && (
          <Box w={6} h={6}>
            <AirlineIconImg iconCode={airlineCode} />
          </Box>
        )}
      </GridItem>
      <GridItem>
        {!isSubTicket && (
          <Text
            data-testid={airTestIds.result.unusedTicketsItemTitle}
            textAlign="left"
            fontWeight="semibold"
            fontSize="h4"
            ml={3}
          >
            {airlinesCodeObject[airlineCode]} {`(${groupLength})`}
          </Text>
        )}
      </GridItem>
      <GridItem>
        <HStack justifyContent="flex-end" spacing={2} flexWrap="wrap">
          {!isSubTicket && (
            <Text fontWeight="normal" fontSize="sm" color="text.base.placeholder">
              {t('Ticket #')}
            </Text>
          )}{' '}
          <Box onClick={preventDefaultEvent}>
            <ClipboardText color="text.base.default" text={ticket.ticketNumber} />
          </Box>
        </HStack>
      </GridItem>
      <GridItem>
        <HStack justifyContent="flex-end" spacing={2} flexWrap="wrap">
          {!isSubTicket && (
            <Text
              whiteSpace="nowrap"
              fontWeight="normal"
              fontSize="sm"
              color="text.base.placeholder"
            >
              {t('Est. Value')}
            </Text>
          )}{' '}
          <Text whiteSpace="nowrap" fontWeight="semibold">
            {getTicketCurrency(ticket)} {getTicketAmount(ticket)}
          </Text>
        </HStack>
      </GridItem>
      <GridItem>
        <HStack justifyContent="flex-end" spacing={2} flexWrap="wrap">
          {!isSubTicket && (
            <Text fontWeight="normal" fontSize="sm" color="text.base.placeholder">
              {t('Expires')}
            </Text>
          )}{' '}
          <Text
            whiteSpace="nowrap"
            data-testid={airTestIds.result.unusedTicketsItemExpire}
            fontWeight="semibold"
          >
            {formatTicketDate(ticket.expiryDatetime)}
          </Text>
        </HStack>
      </GridItem>
      {isOnlyOneInGroup || isSubTicket ? (
        <GridItem />
      ) : (
        <AccordionIcon data-testid={airTestIds.result.unusedTicketsItemAccordionArrow} />
      )}
    </Grid>
  );
}
