import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@/core/store';

const initialState = {
  navigation: {
    isExpanded: true,
  },
  loginCompanyName: '',
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleNavigation(state) {
      state.navigation.isExpanded = !state.navigation.isExpanded;
    },
    setLoginCompanyName(state, { payload }: PayloadAction<string>) {
      state.loginCompanyName = payload;
    },
  },
});

export const appReducer = slice.reducer;
export const { toggleNavigation, setLoginCompanyName } = slice.actions;
export const selectNavigationState = (state: RootState) => state.app.navigation;
export const selectLoginCompanyNameState = (state: RootState) => state.app.loginCompanyName;
