import React from 'react';

import { RouteType } from '@/types/router';
import { Paths } from '@/app/routes/paths';

import type { AppRoute } from '@/types/router';

const HotelsResultsPage = React.lazy(() =>
  import('@/pages/hotels/HotelsResultsPage').then((module) => ({
    default: module.HotelsResultsPage,
  })),
);
const HotelsRatesPage = React.lazy(() =>
  import('@/pages/hotels/HotelsRatesPage').then((module) => ({
    default: module.HotelsRatesPage,
  })),
);
const HotelsCheckoutPage = React.lazy(() =>
  import('@/pages/hotels/HotelsCheckoutPage').then((module) => ({
    default: module.HotelsCheckoutPage,
  })),
);
const HotelsReservationPage = React.lazy(() =>
  import('@/pages/hotels/HotelsReservationPage').then((module) => ({
    default: module.HotelsReservationPage,
  })),
);

export const hotelsRoutes: AppRoute[] = [
  {
    type: RouteType.DEFAULT,
    pathname: Paths.HOTELS_RESULTS,
    component: HotelsResultsPage,
  },
  { type: RouteType.DEFAULT, pathname: Paths.HOTELS_RATES, component: HotelsRatesPage },
  {
    type: RouteType.DEFAULT,
    pathname: Paths.HOTELS_CHECKOUT,
    component: HotelsCheckoutPage,
  },
  {
    type: RouteType.DEFAULT,
    pathname: Paths.HOTELS_RESERVATION,
    component: HotelsReservationPage,
  },
];
