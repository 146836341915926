import React from 'react';
import { Field } from 'formik';
import { FormControl, FormLabel, FormErrorMessage, Flex, Box, Input } from '@chakra-ui/react';

import { CRDFieldHelpText } from './CRDFieldHelpText';
import { testIds } from './testIds';
import { Select } from '@/components/Select';

import type { TOption } from '@/types/common';
import type { FieldProps } from 'formik';
import type { CRDFieldConfig } from './types';

type CRDFieldProps = {
  crdFormKey: string;
  crdFieldConfig: CRDFieldConfig;
};

export function CRDField({ crdFormKey, crdFieldConfig }: CRDFieldProps) {
  const { type } = crdFieldConfig;
  const key = `${crdFormKey}.['${crdFieldConfig.code}']`;
  const testId = `${testIds.crdTextField}-${crdFieldConfig.code}`;

  const selectOptions: TOption[] = crdFieldConfig.options.map((option) => ({
    label: option.name,
    value: option.value,
  }));

  const selectDefaultValue = selectOptions.find(
    (option) => option.value === crdFieldConfig.defaultValue,
  );

  return (
    <Field name={key} type={type}>
      {({ field, meta, form }: FieldProps) => {
        const isInvalid = meta.touched && !!meta.error;

        return (
          <FormControl
            isRequired={crdFieldConfig.required}
            isInvalid={isInvalid}
            data-testid={testId}
            display="grid"
            gridTemplateRows="1fr 32px 1fr"
            h="100%"
          >
            <Flex alignItems="flex-end">
              <FormLabel whiteSpace="normal" wordBreak="break-word">
                {crdFieldConfig.label}
              </FormLabel>
            </Flex>
            {type === 'text' && <Input {...field} size="sm" />}
            {type === 'select' && (
              <Select
                dataTestId={testIds.crdSelectField}
                defaultValue={selectDefaultValue}
                placeholder=" "
                onChange={(option) => form.setFieldValue(field.name, option?.value)}
                options={selectOptions}
                isInvalid={isInvalid}
              />
            )}

            <Box>
              {!isInvalid && <CRDFieldHelpText text={crdFieldConfig.helpText} />}
              <FormErrorMessage>{meta.error}</FormErrorMessage>
            </Box>
          </FormControl>
        );
      }}
    </Field>
  );
}
