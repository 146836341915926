import { extendTheme } from '@chakra-ui/react';

import { AlertStyles as Alert } from '@/components/Alert';
import { AvatarStyles as Avatar } from '@/components/Avatar/AvatarStyles';
import { ButtonStyles as Button } from '@/components/Button/ButtonStyles';
import { RadioStyles as Radio } from '@/components/Form/RadioInput/RadioStyles';
import { CheckboxStyles as Checkbox } from '@/components/Form/Checkbox/CheckboxStyles';
import { SelectStyles as Select } from '@/components/Form/Select/SelectStyles';
import { InputStyles as Input } from '@/components/Form/Input/InputStyles';
import { Form, FormError, FormLabel } from '@/components/Form/FormControl/FormControlStyles';
import { TabsStyles as Tabs } from '@/components/Tabs/TabsStyles';
import { AccordionStyles as Accordion } from '@/components/Accordion/AccordionStyles';
import { TagStyles as Tag } from '@/components/Tag/TagStyles';
import { ModalStyles as Modal } from '@/components/Modal/ModalStyles';
import { MenuStyles as Menu } from '@/components/Menu/MenuStyles';
import { BreadcrumbStyles as Breadcrumb } from '@/components/Breadcrumb/BreadcrumbStyles';
import { CardStyles as Card } from '@/components/Card/CardStyles';
import { SliderStyles as Slider } from '@/components/Slider/SliderStyles';
import { TooltipStyles as Tooltip } from '@/components/Tooltip';
import { SwitchStyles as Switch } from '@/components/Switch';
import { SkeletonStyles as Skeleton } from '@/components/Skeleton';
import { colors } from './colors';
import { fonts, fontSizes } from './fonts';
import { styles } from './global';
import { textStyles } from './text';
import { sizes } from '@/theme/sizes';
import { breakpoints } from '@/theme/breakpoints';

import type { Theme } from '@chakra-ui/react';

const overrides = {
  colors,
  fonts,
  fontSizes,
  styles,
  textStyles,
  sizes,
  breakpoints,
  components: {
    Alert,
    Avatar,
    Button,
    Radio,
    Checkbox,
    Input,
    FormLabel,
    FormError,
    Form,
    Select,
    Tabs,
    Accordion,
    Tag,
    Modal,
    Menu,
    Breadcrumb,
    Card,
    Slider,
    Tooltip,
    Switch,
    Skeleton,
  },
};

export const theme = extendTheme(overrides) as Theme;
