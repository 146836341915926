import * as Yup from 'yup';

import { REQUIRED_FIELD_MESSAGE } from '@/utils/yup';

import type { TFunction } from 'i18next';
import type { TTrip } from '@/features/air/types';

export const MAX_ORIGIN_DESTINATIONS = 6;

export const originDestinationValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    origin: Yup.object()
      .nullable()
      .test('required', REQUIRED_FIELD_MESSAGE(t), (value) => value?.label ?? false),
    destination: Yup.object()
      .nullable()
      .test('required', REQUIRED_FIELD_MESSAGE(t), (value) => value?.label ?? false),
    date: Yup.date().required(REQUIRED_FIELD_MESSAGE(t)).nullable(),
  });

export const searchValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    trip: Yup.string().required(REQUIRED_FIELD_MESSAGE(t)),
    returnDate: Yup.date()
      .nullable()
      .when('trip', {
        is: (value: TTrip) => value === 'round',
        then: Yup.date().nullable().required(REQUIRED_FIELD_MESSAGE(t)),
      }),
    originDestinations: Yup.array()
      .of(originDestinationValidationSchema(t))
      .min(1)
      .max(MAX_ORIGIN_DESTINATIONS)
      .required(REQUIRED_FIELD_MESSAGE(t)),
  });
