import React from 'react';

import { RouteType } from '@/types/router';
import { Paths } from '@/app/routes/paths';

import type { AppRoute } from '@/types/router';

const LoginPage = React.lazy(() =>
  import('@/pages/auth/LoginPage').then((module) => ({ default: module.LoginPage })),
);
const LoginCompaniesPage = React.lazy(() =>
  import('@/pages/auth/LoginCompaniesPage').then((module) => ({
    default: module.LoginCompaniesPage,
  })),
);
const ResetPasswordPage = React.lazy(() =>
  import('@/pages/auth/ResetPasswordPage').then((module) => ({
    default: module.ResetPasswordPage,
  })),
);
const ResetPasswordErrorPage = React.lazy(() =>
  import('@/pages/auth/ResetPasswordErrorPage').then((module) => ({
    default: module.ResetPasswordErrorPage,
  })),
);
const ResetPasswordSuccessPage = React.lazy(() =>
  import('@/pages/auth/ResetPasswordSuccessPage').then((module) => ({
    default: module.ResetPasswordSuccessPage,
  })),
);
const SendResetEmailPage = React.lazy(() =>
  import('@/pages/auth/SendResetEmailPage').then((module) => ({
    default: module.SendResetEmailPage,
  })),
);
const SendResetEmailSuccessPage = React.lazy(() =>
  import('@/pages/auth/SendResetEmailSuccessPage').then((module) => ({
    default: module.SendResetEmailSuccessPage,
  })),
);
const SignUpPage = React.lazy(() =>
  import('@/pages/auth/SignUpPage').then((module) => ({ default: module.SignUpPage })),
);
const SignUpPasswordPage = React.lazy(() =>
  import('@/pages/auth/SignUpPasswordPage').then((module) => ({
    default: module.SignUpPasswordPage,
  })),
);
const SignUpErrorPage = React.lazy(() =>
  import('@/pages/auth/SignUpErrorPage').then((module) => ({ default: module.SignUpErrorPage })),
);

export const authRoutes: AppRoute[] = [
  { type: RouteType.INDEX, component: LoginPage },
  {
    type: RouteType.DEFAULT,
    pathname: Paths.SIGN_UP,
    component: SignUpPage,
  },
  { type: RouteType.DEFAULT, pathname: Paths.COMPANIES, component: LoginCompaniesPage },
  { type: RouteType.DEFAULT, pathname: Paths.SIGN_UP_PASSWORD, component: SignUpPasswordPage },
  {
    type: RouteType.DEFAULT,
    pathname: Paths.SIGN_UP_ERROR,
    component: SignUpErrorPage,
  },
  {
    type: RouteType.BASE,
    pathname: Paths.SEND_RESET_EMAIL,
    children: [
      { type: RouteType.INDEX, component: SendResetEmailPage },
      {
        type: RouteType.DEFAULT,
        pathname: Paths.SEND_RESET_EMAIL_SUCCESS,
        component: SendResetEmailSuccessPage,
      },
    ],
  },
  { type: RouteType.DEFAULT, pathname: Paths.RESET, component: ResetPasswordPage },
  {
    type: RouteType.DEFAULT,
    pathname: Paths.RESET_SUCCESS,
    component: ResetPasswordSuccessPage,
  },
  {
    type: RouteType.DEFAULT,
    pathname: Paths.RESET_ERROR,
    component: ResetPasswordErrorPage,
  },
  {
    type: RouteType.BASE,
    pathname: Paths.COMPANY_LOGIN,
    children: [
      { type: RouteType.INDEX, component: LoginPage },
      {
        type: RouteType.DEFAULT,
        pathname: Paths.COMPANY_SIGN_UP,
        component: SignUpPage,
      },
      {
        type: RouteType.DEFAULT,
        pathname: Paths.COMPANY_SIGN_UP_PASSWORD,
        component: SignUpPasswordPage,
      },
      {
        type: RouteType.DEFAULT,
        pathname: Paths.COMPANY_SIGN_UP_ERROR,
        component: SignUpErrorPage,
      },
      {
        type: RouteType.DEFAULT,
        pathname: Paths.COMPANY_COMPANIES,
        component: LoginCompaniesPage,
      },
      {
        type: RouteType.BASE,
        pathname: Paths.COMPANY_SEND_RESET_EMAIL,
        children: [
          { type: RouteType.INDEX, component: SendResetEmailPage },
          {
            type: RouteType.DEFAULT,
            pathname: Paths.COMPANY_SEND_RESET_EMAIL_SUCCESS,
            component: SendResetEmailSuccessPage,
          },
        ],
      },
      {
        type: RouteType.DEFAULT,
        pathname: Paths.COMPANY_RESET,
        component: ResetPasswordPage,
      },
      {
        type: RouteType.DEFAULT,
        pathname: Paths.COMPANY_RESET_SUCCESS,
        component: ResetPasswordSuccessPage,
      },
      {
        type: RouteType.DEFAULT,
        pathname: Paths.COMPANY_RESET_ERROR,
        component: ResetPasswordErrorPage,
      },
    ],
  },
];
