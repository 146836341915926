import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { colors } from '@/theme/colors';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tabsAnatomy.keys,
);

const pills = definePartsStyle({
  tablist: {
    color: 'primary.blue1',
  },
  tab: {
    boxSizing: 'border-box',
    color: 'primary.blue1',
    fontWeight: 'regular',
    borderRadius: 'full',
    padding: '10px 16px',
    mr: 8,

    svg: {
      mr: 2,
      height: 5,
      width: 5,
    },

    _hover: {
      bgColor: 'tertiary.lightGrey',
    },

    _selected: {
      fontWeight: 'semibold',
      color: 'text.base.white',
      backgroundColor: 'primary.blue1',
    },
  },
});

const line = definePartsStyle((props) => {
  const { orientation } = props;
  const isVertical = orientation === 'vertical';
  const marginEndProp = isVertical ? 'marginEnd' : 'marginBottom';
  const marginStartProp = isVertical ? 'marginStart' : 'marginBottom';
  const paddingProp = isVertical ? 'paddingEnd' : 'paddingBottom';
  const borderProp = isVertical ? 'borderLeft' : 'borderBottom';

  return {
    tablist: {
      color: 'tabs.default',
      [borderProp]: '1px solid',
      borderColor: 'border.line !important',
      [marginEndProp]: 6,
    },
    tab: {
      fontWeight: isVertical ? 'normal' : 'semibold',
      borderColor: 'transparent !important',
      [marginStartProp]: '-1px',
      [paddingProp]: '10px',

      _hover: {
        color: 'tabs.hover',
        borderColor: 'tabs.hover',
        bgColor: 'transparent !important',
      },

      _selected: {
        cursor: 'default',
        fontWeight: 'semibold',
        color: 'tabs.active !important',
        [borderProp]: isVertical
          ? `3px solid ${colors.orange.default} !important`
          : `3px solid ${colors.primary.blue1} !important`,
      },
    },

    tabpanel: {
      p: 0,
    },
  };
});

const solidRounded = definePartsStyle({
  tablist: {
    borderColor: 'primary.blue1',
    borderWidth: 1,
    borderRadius: 6,
  },
  tab: {
    color: 'primary.blue1',
    borderRadius: 0,

    _selected: {
      color: 'text.base.white',
      backgroundColor: 'primary.blue1',
    },
  },
});

export const TabsStyles = defineMultiStyleConfig({
  variants: { pills, line, 'solid-rounded': solidRounded },
});
