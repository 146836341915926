import type { TSpitfireFetchArgs } from '@/core/spitfire';
import type { Definitions } from '@/types/generated';

export type TArrangerTraveler = Definitions.GetTravelersResponse | null;

export type TArrangerQueryRequest<T = Record<string, unknown>> = T & {
  currentTraveler?: TArrangerTraveler;
};

type TExtendWithArrangerHeadersParams = {
  args: TSpitfireFetchArgs;
  currentTraveler?: TArrangerTraveler;
};

export function extendWithArrangerHeaders({
  args,
  currentTraveler,
}: TExtendWithArrangerHeadersParams): TSpitfireFetchArgs {
  if (!currentTraveler) return args;

  return {
    ...args,
    headers: {
      'X-Traveler': JSON.stringify({
        traveler_id: currentTraveler.travelerId,
        company_id: currentTraveler.companyId,
      }),
    },
  };
}
