import type { THotelsSearchFormValues } from '../types';

export const DEFAULT_SEARCH_RADIUS = 5;

export const FORM_FIELDS = {
  DESTINATION: 'destination',
  CHECKIN: 'checkin',
  CHECKOUT: 'checkout',
  GUEST_COUNT: 'guestCount',
  SEARCH_RADIUS: 'searchRadius',
  SEARCH_RADIUS_UNIT: 'searchRadiusUnit',
} as const;

export const initialValues: THotelsSearchFormValues = {
  destination: null,
  checkin: null,
  checkout: null,
  guestCount: 1,
  searchRadius: DEFAULT_SEARCH_RADIUS,
  searchRadiusUnit: 'KM',
};
