const EXTERNAL_SUPPORT_LINK = 'https://tripsource.com';
const EXTERNAL_PRIVACY_LINK = 'https://www.bcdtravel.com/privacy-policy/';

const getLangId = (lang: string) => lang?.split('-')[0] || 'en';

export const getPrivacyPolicyLink = (lang: string) => {
  // get from en-US to en
  const langPrivacy = getLangId(lang);

  if (lang === 'nn-NO') {
    return `${EXTERNAL_PRIVACY_LINK}#no`;
  }

  if (lang === 'fr-CA') {
    return `${EXTERNAL_PRIVACY_LINK}#fr`;
  }

  return `${EXTERNAL_PRIVACY_LINK}#${langPrivacy}`;
};

export const getSupportLink = (lang: string) => {
  const langSupport = getLangId(lang);

  if (lang === 'en-US' || langSupport === 'en') {
    return `${EXTERNAL_SUPPORT_LINK}/support`;
  }

  if (lang === 'nn-NO') {
    return `${EXTERNAL_SUPPORT_LINK}/nb/support`;
  }

  if (lang === 'pt-BR') {
    return `${EXTERNAL_SUPPORT_LINK}/pt-br/support/`;
  }

  return `${EXTERNAL_SUPPORT_LINK}/${langSupport}/support`;
};
