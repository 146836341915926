/* eslint-disable */
// @ts-nocheck

export function loadHeapAnalyticsIntegration() {
  (window.heap = window.heap || []),
    (heap.load = function (e, t) {
      (window.heap.appid = e), (window.heap.config = t = t || {});
      var r = document.createElement('script');
      (r.type = 'text/javascript'),
        (r.async = !0),
        (r.src = 'https://cdn.heapanalytics.com/js/heap-' + e + '.js');
      var a = document.getElementsByTagName('script')[0];
      a.parentNode.insertBefore(r, a);
      for (
        var n = function (e) {
            return function () {
              heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
            };
          },
          p = [
            'addEventProperties',
            'addUserProperties',
            'clearEventProperties',
            'identify',
            'resetIdentity',
            'removeEventProperty',
            'setEventProperties',
            'track',
            'unsetEventProperty',
          ],
          o = 0;
        o < p.length;
        o++
      )
        heap[p[o]] = n(p[o]);
    });
  heap.load(import.meta.env.THUNDERBOLT_HEAP_ID);
}

export function loadBroadstreetAdsIntegration() {
  const broadstreetScriptElement = document.createElement('script');
  broadstreetScriptElement.src = 'https://cdn.broadstreetads.com/init-2.min.js';
  broadstreetScriptElement.async = true;
  document.body.appendChild(broadstreetScriptElement);

  window.broadstreet = window.broadstreet || { run: [] };
}

export function loadSatmetrixScript() {
  // Don't load the script if it's already loaded
  if (window.npxUrl) {
    return;
  }

  // Satmetrix Feedback Button rely on global "npxUrl" variable
  window.npxUrl =
    'https://appeuw1.satmetrix.com/npxcdn/enterprise/bcdtravel/app/cx/resources/datacollection/bcdtravel_5897/intercept/bcdtravel_5897_popup_1717750051367-en_us/interceptsurvey_bcdtravel_5897_popup_1717750051367-en_us.js?a=' +
    new Date().getTime();

  var script = document.createElement('script');
  script.src = window.npxUrl;
  document.getElementsByTagName('head')[0].appendChild(script);
}
