import { generatePath } from 'react-router-dom';

import { Paths } from '@/app/routes/paths';

export function getCompanyLoginPaths(companyLogin: string): Record<keyof typeof Paths, string> {
  return {
    ...Paths,
    LOGIN: generatePath(Paths.COMPANY_LOGIN, { companyLogin }),
    COMPANIES: generatePath(Paths.COMPANY_COMPANIES, { companyLogin }),
    SEND_RESET_EMAIL: generatePath(Paths.COMPANY_SEND_RESET_EMAIL, { companyLogin }),
    SEND_RESET_EMAIL_SUCCESS: generatePath(Paths.COMPANY_SEND_RESET_EMAIL_SUCCESS, {
      companyLogin,
    }),
    SIGN_UP: generatePath(Paths.COMPANY_SIGN_UP, {
      companyLogin,
    }),
    SIGN_UP_SUCCESS: generatePath(Paths.COMPANY_SIGN_UP_SUCCESS, {
      companyLogin,
    }),
    SIGN_UP_ERROR: generatePath(Paths.COMPANY_SIGN_UP_ERROR, {
      companyLogin,
    }),
    // generatePath() can't be used if we need to replace not all parameters of the path
    RESET: Paths.COMPANY_RESET.replace(':companyLogin', companyLogin),
    RESET_SUCCESS: Paths.COMPANY_RESET_SUCCESS.replace(':companyLogin', companyLogin),
    RESET_ERROR: Paths.COMPANY_RESET_ERROR.replace(':companyLogin', companyLogin),
    SIGN_UP_PASSWORD: Paths.COMPANY_SIGN_UP_PASSWORD.replace(':companyLogin', companyLogin),
    TERMS_AND_CONDITIONS: generatePath(Paths.COMPANY_TERMS_AND_CONDITIONS, { companyLogin }),
  };
}
