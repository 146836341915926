import { Center, Icon } from '@chakra-ui/react';
import React from 'react';

import { ChevronDown } from '@/icons/chevron-down';

import type { DropdownIndicatorProps, GroupBase } from 'react-select';

export function DropdownIndicator<
  Option,
  isMulti extends boolean,
  Group extends GroupBase<Option>,
>({
  innerProps,
}: DropdownIndicatorProps<Option, isMulti, Group>) {
  return <Center {...innerProps} boxSize="32px" color="border.formControl" cursor="pointer">
    <Icon as={ChevronDown} />
  </Center>
}
