import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction} from '@reduxjs/toolkit';
import type { RootState } from '@/core/store';
import type { CardType } from './types';
import type { Definitions } from '@/types/generated';

type TRailCardsState = {
  railCardsById: Record<string, CardType> | null;
  passengers: Definitions.SearchResponsePassenger[];
};

const initialState: TRailCardsState = {
  railCardsById: null,
  passengers: [],
};

const slice = createSlice({
  name: 'rail',
  initialState,
  reducers: {
    setRailCardsById: (state, { payload }: PayloadAction<Record<string, CardType>>) => {
      state.railCardsById = payload;
    },
    setPassengers: (state, { payload }: PayloadAction<Definitions.SearchResponsePassenger[]>) => {
      state.passengers = payload;
    },
  },
});

export const selectRailCardsById = (state: RootState) => state.rail.railCardsById;
export const selectPassengers = (state: RootState) => state.rail.passengers;

export const { setRailCardsById, setPassengers } = slice.actions;

export const railReducer = slice.reducer;
